import React from 'react';
import { Avatar, Panel } from 'appkit-react';

const Rules = () => {

  const rules = {
    rulesAndInstructions: {
      title: 'Rules & Instructions',
      description: `You choose the bias metric and group you'd like to protect.`,
      rules: [
        `Financial Lenders will be evaluated on profit.`,
        `Regulator will be evaluated on profit, bias and the percent of people successfully approved for a loan (consumer welfare).`,
        `Each player starts with an initial amount of wealth. If all players become bankrupt, the game will end even if all rounds are incomplete.`,
        `Players and overall game ranking will be determined by percent of people successfully approved for a loan (consumer welfare).`
      ],
    },
    definitions: {
      title: 'Important Things to Know',
      profit: {
        title: 'How to Make a Profit',
        content: [
          {
            title: 'Regulator',
            content: 'Revenue from penalty fines on lenders',
            description: 'Cost of actions taken within game',
          },
          {
            title: 'Lender',
            content: 'Revenue from interest on loans approved',
            description: 'Cost of actions taken within game and loans not paid back',
          }
        ]
      },
      consumerWelfare: {
        title: 'Consumer Welfare (CW)',
        description: 'Percentage of people successfully approved for a loan:',
        numerator: '# People Approved for Loans ',
        denominator: '# People Applied for Loans',
      }
    }
  }

  return (
    <div className='rules-zone'>
      <div className='rules-and-instructions-zone'>
        <span>{rules.rulesAndInstructions.title}</span>
        <div><span>{rules.rulesAndInstructions.description}</span></div>
        { rules.rulesAndInstructions.rules.map((item, index) => {
            return (
              <div key={ index } className='rules-value'>
                <Avatar shape='square'>{ index + 1 }</Avatar>
                <div>
                  <span>{ item }</span>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className='definitions-zone'>
        <div>
          <span className='appkiticon icon-rating-fill a-font-22 a-text-orange-light'></span>
          <span>{ rules.definitions.title }</span>
        </div>
        <Panel title={ rules.definitions.profit.title }>
          { rules.definitions.profit.content.map((item, index) => {
              return (
                <div key={index}>
                  <span>{item.title}</span>
                  <div className='definitions-profit-content'>
                    <div>{item.content}</div>
                    <span>MINUS</span>
                    <div>{item.description}</div>
                  </div>
                </div>
              )
            })
          }
        </Panel>
        <Panel title={ rules.definitions.consumerWelfare.title }>
          <span>{ rules.definitions.consumerWelfare.description }</span>
          <div className='definitions-consumer-welfare-content'>
            <p>{rules.definitions.consumerWelfare.numerator}</p>
            <p>{rules.definitions.consumerWelfare.denominator}</p>
          </div>
        </Panel>
      </div>
    </div>
  )
}

export default Rules;