import { connect } from 'react-redux';
import { PlayComponent } from '../components';
import { playActions, playSelectors } from '../duck';
import { CalculateService } from 'app/common/services'
import { push } from 'connected-react-router'

const mapStateToProps = (state, ownProps) => {
  const { gameId, player } = ownProps.match.params

  const { isLoading, isLoaded, isProcessing, isGameCompleted, isAllBankrupt, isBankrupt, hasNotLaunched, hasNotJoined, isPlayerSubmitted, isSubmitRequested, isSubmitAfterTimeout, isReadyToResult, error, alert, popup,
          game, submittedActions, submittedPlayers, currentRoundPlayers, previousRoundsActions, selectedPlayerInActions, currentRoundActions, allOptions, roundLifeTime, dataScientist } = state.play

  const isCurrentRoundFinished = submittedPlayers.length && submittedPlayers.length === currentRoundPlayers.length
  const interactiveActions = playSelectors.interactiveActions(submittedActions, allOptions)

  const alertOptions = {
    biasWithoutGroup: { 
      title: 'Not Allowed', 
      content: 'You can only apply a bias metric if you have chosen a group to protect.' 
    },
  }

  return {
    isLoading,
    isLoaded,
    isProcessing,
    isGameCompleted,
    isAllBankrupt,
    isBankrupt,
    hasNotLaunched,
    hasNotJoined,
    isPlayerSubmitted,
    isSubmitRequested,
    isSubmitAfterTimeout,
    error,
    alert,
    popup,
    alertOptions,
    game,
    submittedPlayers,
    currentRoundPlayers,
    previousRoundsActions,
    selectedPlayerInActions,
    currentRoundActions,
    allOptions: {
      ...allOptions,
      thresholds: (currentRoundActions.model ? allOptions.thresholds[currentRoundActions.model] : {}),
    },
    roundClock: playSelectors.roundClock(roundLifeTime),
    isRoundTimeout: (roundLifeTime === 0),
    role: CalculateService.getRole(player),
    isRegulator: CalculateService.isRegulator(player),
    isGameStarted: CalculateService.isGameStarted(game),
    dataScientist,
    isCurrentRoundFinished,
    interactiveActions,
    shouldShowInteractiveActions: isReadyToResult && interactiveActions.length > 0,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { gameId, player } = ownProps.match.params

  return {
    push: (path) => {
      dispatch(push(path))
    },
    getGamePlayInfoRequest: () => {
      dispatch(playActions.getGamePlayInfoRequest({ gameId, player }))
    },
    updateRoundActions: (payload) => {
      dispatch(playActions.updateRoundActions(payload))
    },
    updateActionsStatus: (value) => {
      dispatch(playActions.updateActionsStatus(value))
    },
    addLenders: (value) => {
      dispatch(playActions.addLenders(value))
    },
    showAlert: (alert) => {
      dispatch(playActions.toggleAlert({ showing: true, ...alert }))
    },
    hideAlert: () => {
      dispatch(playActions.toggleAlert({}))
    },
    showPopup: (key) => {
      dispatch(playActions.togglePopup({[key]: { showing: true }}))
    },
    hidePopup: (key) => {
      dispatch(playActions.togglePopup({[key]: {}}))
    },
    roundTimePassed: () => {
      dispatch(playActions.roundTimePassed())
    },
    updateDataScientist: (payload) => {
      dispatch(playActions.updateDataScientist(payload))
    },
    submitRoundRequest: (game, currentRoundPlayers, currentRoundActions, submitAfterTimeout) => {
      dispatch(playActions.submitRoundRequest({ game, player, currentRoundPlayers, currentRoundActions, submitAfterTimeout }))
    },
    playerHasSubmitted: (data) => {
      dispatch(playActions.playerHasSubmitted(data))
    },
    readyToResult: () => {
      dispatch(playActions.readyToResult())
    },
  }
};

const PlayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayComponent);

export default PlayContainer;
