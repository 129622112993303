import { connect } from 'react-redux';
import { NewGameComponent } from '../components';
import { homeActions } from '../duck';
import { push } from 'connected-react-router'

const mapStateToProps = (state, ownProps) => {
  const { settings, isLoading, playerDuplicatedValue, isPlayerDuplicatedMsg, responseError, isGameCreated, changeSettings, addLenders, deleteLenders, createGameRequest } = state.home
  
  return {
    props: ownProps,
    isLoading,
    settings,
    playerDuplicatedValue,
    isPlayerDuplicatedMsg,
    responseError,
    isGameCreated,
    changeSettings,
    addLenders,
    deleteLenders,
    createGameRequest,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    push: (path) => {
      dispatch(push(path))
    },
    changeSettings: (keyval) => {
      dispatch(homeActions.changeSettings(keyval))
    },
    addLenders: (keyval) => {
      dispatch(homeActions.addLenders(keyval))
    },
    deleteLenders: (keyval) => {
      dispatch(homeActions.deleteLenders(keyval))
    },
    checkPlayerNameUniqueness: (val) => {
      dispatch(homeActions.checkPlayerNameUniqueness(val))
    },
    createGameRequest: (val) => {
      dispatch(homeActions.createGameRequest(val))
    },
  }
};

const NewGameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewGameComponent);

export default NewGameContainer;
