import React from 'react';
import { number2Percent } from 'app/common/utils'
import { Images } from 'app/assets'

const LeaderBoard = ({ 
	game, 
	player, 
	currentResults,
}) => {

  return (
  	<div className='leader-board'>
  		<span className='title'>Leaderboard</span>
  		<div className='board-zone'>
	  		{currentResults.leaderboard.map((i, index) => (
	  			<div key={index}>
	  				<div>
	  					{index === 0 && (
	  						<img className='trophy' src={Images.trophy_white} />
	  					)}
	  					<span>{i.player === player ? 'You!' : game[i.player]}</span>
	  				</div>
	  				<span>{number2Percent(i.consumer_welfare)} loans approved</span>
	  			</div>
	  		))}
  		</div>
  	</div>
  )
};

export default LeaderBoard;
