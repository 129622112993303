import React from 'react';
import { Button, Checkbox } from 'appkit-react';
import { Link } from "react-router-dom";
import * as RouteName from 'app/routes/route-name'
import { LabelledNumberInput }  from 'app/common/components'
import { range, lodash2space, formatCurrency } from 'app/common/utils'
import moment from 'moment'
import _ from 'lodash'
import { CURRENCY, UNITS } from 'app/common/constants'
import { CalculateService } from 'app/common/services'

const Settings = ({ game, player, editMode, allOptions, isRegulator, isGameStarted, changeSettings, toggleEditMode }) => {
	const title = 'Before you get gaming...'
  const disabled = !isRegulator || isGameStarted

  const updateRoundSettings = (key, val, round, checked) => {
    const round_settings = {
      ...game.round_settings,
      [round]: {
        ...game.round_settings[round],
        [key]: (checked ? _.uniq(game.round_settings[round][key].concat(val)) : game.round_settings[round][key].filter(i => i != val))
      }
    }
    changeSettings({
      key: 'round_settings',
      val: round_settings,
    })
  }

  const settings = {
    basic: {
      active: [{
        name: 'starting_budget',
        title: 'Starting Budget',
        unit: CURRENCY.dollar,
        min: 10000,
        max: 10000000,
        step: 1000,
        integerOnly: true,
        value: game.starting_budget,
      }, {
        name: 'rounds',
        title: 'Game Rounds',
        min: 1,
        max: 10,
        step: 1,
        integerOnly: true,
        value: game.rounds,
      }, {
        name: 'round_time',
        title: 'Time Per Round',
        unit: UNITS.minute,
        min: 1,
        max: 15,
        step: 1,
        integerOnly: true,
        value: game.round_time,
      }],
      defaults: [{
        name: 'penalty',
        title: 'Penalty',
        unit: CURRENCY.dollar,
        min: 0,
        max: 500000,
        step: 1000,
        integerOnly: true,
        value: game.penalty,
      }, {
        name: 'default_baseline_modal_thresholds',
        title: 'Baseline Model Thresholds',
        min: 0,
        max: 1,
        step: .01,
        integerOnly: false,
        value: game.default_baseline_modal_thresholds,
      }, {
        name: 'interest_rate_index',
        title: 'Interest Rate Index',
        min: 0,
        max: 1,
        step: .01,
        integerOnly: false,
        value: game.interest_rate_index,
      }]
    }
  }

  const roundSettingsTitle = 'Round Development Settings'

  return (
    <div className='settings-zone'>
      <span>{title}</span>

      <div className='basic-settings'>
        <div>
          {settings.basic.active.map((i, index) => (
            <LabelledNumberInput key={index} delay={true} obj={i} disabled={disabled} changeSettings={changeSettings} />
          ))}
        </div>

        <div>
          <div>
            <span>Defaults</span>
            {!disabled && (
              <Button kind='transparent' onClick={toggleEditMode}>{editMode ? 'SAVE': 'EDIT'}</Button>
            )}
          </div>
          {editMode ? (
            <>
              {settings.basic.defaults.map((i, index) => (
                <LabelledNumberInput key={index} delay={true} obj={i} disabled={disabled} changeSettings={changeSettings} />
              ))}
            </>
          ) : (
            <>
              {settings.basic.defaults.map((i, index) => (
                <div key={index} className='defaults-row'>
                  <span>{i.title}:</span>
                  <span>{i.unit === CURRENCY.dollar ? formatCurrency(i.value, i.unit) : i.value}</span>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <div className='round-dev-settings'>
        <span>{roundSettingsTitle}</span>
        <div>
          <div className='round-row'>
            <span></span>
            {range(1, game.rounds).map((i, index) => (
              <span key={index}>Round {i}</span>
            ))}
          </div>
          <div className='group-settings'>
            <span>Protected Groups</span>
            {allOptions.protectedGroups.map((i, indexI) => (
              <div key={indexI} className='group-row'>
                <span>{i.name}</span>
                {range(1, game.rounds).map((j, indexJ) => (
                  <Checkbox key={indexJ} 
                            disabled={disabled}
                            onChange={(e, val) => updateRoundSettings('protected_groups', i.id, j, e.target.checked)}
                            checked={game.round_settings[j].protected_groups.indexOf(i.id) > -1}
                            checkedIcon={<span className="appkiticon icon-close-fill"></span>} />
                ))}
              </div>
            ))}
          </div>
          <div className='metric-settings'>
            <span>Metrics</span>
            {allOptions.biasMetrics.map((i, indexI) => (
              <div key={indexI} className='metric-row'>
                <span>{i.name}</span>
                {range(1, game.rounds).map((j, indexJ) => (
                  <Checkbox key={indexJ}  
                            disabled={disabled}
                            onChange={(e, val) => updateRoundSettings('bias_metrics', i.id, j, e.target.checked)}
                            checked={game.round_settings[j].bias_metrics.indexOf(i.id) > -1}
                            checkedIcon={<span className="appkiticon icon-close-fill"></span>} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Settings;
