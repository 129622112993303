import { ERROR } from 'app/common/constants'

const handleError = (e) => {
	let error
	if (e.isCMSError) {
		error = e
	} else if (e.isLASError) {
		error = e
	} else if (e.isAxiosError) {
		if (e.code) {
			error = ERROR.axios[e.code]
		} else if (e.message) {
			switch (e.message) {
				case 'Network Error':
					error = ERROR.general.network
					break
				default:
					error = e
			}
		}
	}

	error = error || ERROR.general.unknown
	error.title = error.title || error.name

	return error
}

export {
	handleError
}
