import React, { useEffect } from 'react'
import { Devider, Info } from 'app/common/components'
import { Colors, Typography } from 'app/styles'
import { Icons } from 'app/assets'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { numberToPercent, numberToThousand, descriptionByPlayer } from 'app/common/utils'
import _ from 'lodash'

const QuarterMarketReport = ({ popup, marketShare, hidePopup }) => {
  const colors = [Colors.RED_LIGHT, Colors.YELLOW_LIGHT, Colors.BLUE_LIGHTE]
  const chartId = 'chart-quarter-market-report'

  const handleCancel = () => {
    hidePopup('quarterMarketReport')
  }

	const options = {
    chart: {
      type: 'line',
      renderTo: chartId,
      ...theStyle.pieChart
    },
    title: {
      text: popup.title,
    },
    legend: {
      align: 'center',
      layout: 'vertical',
      verticalAlign: 'bottom',
      itemMarginBottom: 5,
    },
    plotOptions: {
    	pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true
      }
    },
    tooltip: {
    	pointFormatter: function() {
    		return `<span style="color:${this.color}">\u25CF</span>  ${this.series.name}: <b>${_.round(this.percentage, 1)}%</b>`
    	}
    },
    credits: {
      enabled: false
    },
    series: [
      {
      	type: 'pie',
        name: 'Marketshare',
        data: _.map(marketShare, (i, index) => {
        	return {
        		name: i.name,
        		y: i.nbConsumersGotLoan,
        		color: colors[index],
        	}
        })
      }
    ]
  }

  const contentDom = (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps = {{ id: chartId, className: chartId }}
    />
  )

	return (
    <Info 
      visible={popup.visible}
      title={popup.title}
      content={contentDom}
      className='quarter-market-report-modal'
      handleCancel={handleCancel} />
  )
};

const theStyle = {
	pieChart: {
    borderWidth: 1,
    borderColor: Colors.GREY_LIGHT,
    borderRadius: 2,
	},
	axisLabel: {
		fontSize: Typography.FONT_SIZE_10,
	},
	dataLabel: {
		fontSize: Typography.FONT_SIZE_12,
	}
}

export default QuarterMarketReport;
