import React from 'react';
import { Tabs, Tab } from 'appkit-react';
import { number2Percent } from 'app/common/utils'
import BarChart from './bar-chart-group'
import _ from 'lodash'

const BiasSummary = ({allOptions, biasSummary, biasSummarySettings, switchBiasSummary }) => {
  const bm = biasSummarySettings.biasMetric
  const pg = biasSummarySettings.protectedGroup
  const biasMetrics = _.chain(allOptions.biasMetrics).toPairs().map(([key, val]) => (val)).sortBy('id').value()
  const protectedGroups = _.chain(allOptions.protectedGroups).toPairs().map(([key, val]) => (val)).sortBy('id').value()
  const groupCategories = _.chain(allOptions.groupCategories[pg]).toPairs().map(([key, val]) => (val)).sortBy('id').value()

  const title = 'Bias Summary';
  const subTitle = `Aggregate Bias for ${allOptions.biasMetrics[bm].name}`;

  const onChangeBM = (e, val) => {
    switchBiasSummary({
      biasMetric: parseInt(val),
    })
  }

  const onChangePG = (val) => {
    switchBiasSummary({
      protectedGroup: parseInt(val)
    })
  }

  const chartData = {
    average: biasSummary[pg].bmr,
    data: _.chain(biasSummary[pg].bmr_grouped).toPairs().map(([cat, value]) => ({ id: cat, value })).sortBy('id').value()
  }

  return (
    <div className="bias-summary">
      <div>
        <span className="title">{title}</span>
      </div>

      <Tabs value={bm.toString()} onChange={onChangeBM} className='bias-summary-tabs'>
        {biasMetrics.map((bmItem, bmIndex) => (
          <Tab key={bmIndex} value={bmItem.id.toString()} label={bmItem.name.toUpperCase()}>
            <div className='bias-summary-tab-content'>
              <span className='bias-summary-tab-content-title'>{subTitle}</span>
              <div className='bias-summary-tab-all-content-container'>
                {protectedGroups.map((pgItem, pgIndex) => (
                  <div key={pgIndex} onClick={() => onChangePG(pgItem.id)} className='bias-summary-tab-each-content-container'>
                    <div className={'bias-summary-tab-content-box ' + (pgItem.id === pg ? 'shadow-box' : 'orange-box')}>
                      <span>{pgItem.name}</span> 
                      <span>{number2Percent(biasSummary[pgItem.id].aggregate_bias)}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Tab>
        ))}
      </Tabs>

      <BarChart
        categories={ groupCategories.map(i => i.name) }
        chartData={ chartData }
      />
    </div>
  )
}

export default BiasSummary;