// types
export default {
	GET_ROUND_RESULTS_REQUEST: 'GET_ROUND_RESULTS_REQUEST',
	GET_ROUND_RESULTS_SUCCESS: 'GET_ROUND_RESULTS_SUCCESS',
	GET_ROUND_RESULTS_FAILURE: 'GET_ROUND_RESULTS_FAILURE',

	START_NEXT_ROUND_REQUEST: 'START_NEXT_ROUND_REQUEST',
	START_NEXT_ROUND_SUCCESS: 'START_NEXT_ROUND_SUCCESS',
	START_NEXT_ROUND_FAILURE: 'START_NEXT_ROUND_FAILURE',

	PLAYER_HAS_JOINED_NEXT_ROUND: 'PLAYER_HAS_JOINED_NEXT_ROUND',

	RESULT_TOGGLE_POPUP: 'RESULT_TOGGLE_POPUP',

	UPDATE_AUDIT_REPORT: 'UPDATE_AUDIT_REPORT',
}