import React from 'react';
import { Button } from 'appkit-react';
import { number2Percent } from 'app/common/utils'
import { EMPTY, SPACE } from 'app/common/constants'

const CW = ({
  game,
  player,
  currentResults,
  topPerformer,
}) => {
  const title = 'Loans Successfully Approved'
  const messages = [
    `The percentage of people that applied for loans and were successfully approved by a lenders.`,
    `Also known as Consumer Welfare (CW).`
  ]
  const isCurrentBetter = currentResults.consumer_welfare >= topPerformer.consumer_welfare

  const performers = [
    {
      name: 'You',
      value: currentResults.consumer_welfare,
      isBest: isCurrentBetter,
    },
    {
      name: 'All Time Best Performer',
      value: topPerformer.consumer_welfare,
      isBest: !isCurrentBetter,
    }
  ]

  return (
    <div className='result-cw'>
      <div>
        <span className="title">{title}</span>
        <div className='messages'>
          {messages.map((i, index) => (
            <span key={index}>{index > 0 ? SPACE : EMPTY}{i}</span>
          ))}
        </div>
      </div>
    	<div className='performers'>
        {performers.map((i, index) => (
          <div key={index} className='performer-card'>
            <div>
              {i.isBest && (
                <span className="appkiticon icon-rating-fill"></span>
              )}
              <span>{i.name}</span>
            </div>
            <span>{number2Percent(i.value)}</span>
          </div>
        ))}
    	</div>
    </div>
  )
};

export default CW;
