import _ from 'lodash'
import * as CONSTANTS from 'app/common/constants'

function isRegulator(player) {
	return player === CONSTANTS.PLAYER_ROLE.regulator
}

function getRole(player) {
	return isRegulator(player) ? CONSTANTS.PLAYER_ROLE_MAP.regulator : CONSTANTS.PLAYER_ROLE_MAP.lender
}

function isGameStarted(game) {
	return CONSTANTS.PLAYERS.filter(player => game[`is_${player}_joined`]).length > 0
}

function getPlayersOfGame(game) {
	return CONSTANTS.PLAYERS.filter(i => game[i])
}

function getLendersOfGame(game) {
	return CONSTANTS.LENDERS.filter(i => game[i])
}

function getPlayersCountOfGame(game) {
	return CONSTANTS.PLAYERS.filter(i => game[i]).length
}

function getJoinedPlayersCountOfGame(game) {
	return CONSTANTS.PLAYERS.filter(i => game[`is_${i}_joined`]).length
}

function getGroupKey(oPG) {
	return oPG ? oPG.name.toLowerCase().replace(/\s/g, '_') : null
}

function gotLoan(consumer, model, oPG, thresholds) {
	const groupKey = getGroupKey(oPG)
	const modelKey = `score_m${model}`
	const threshold = _.isObject(thresholds) ? thresholds[consumer[groupKey]] : thresholds

	return consumer[modelKey] > threshold
}

function getAccuracy(data) {
	return _.round(_.filter(data, i => i.paid_back && i.got_loan || !i.paid_back && !i.got_loan).length / data.length, 2)
}

function getLoanProfit(data, game) {
	return _.chain(data).filter(i => i.got_loan)
											.reduce((sum, i) => sum + i.credit_amount * (i.paid_back ? game.interest_rate_index : -1), 0)
											.round()
											.value()
}

function getLoansApproved(data) {
	return data.filter(i => i.got_loan).length
}

function getLoansDenied(data) {
	return data.filter(i => !i.got_loan).length
}

function getLoansApprovedByCategory(data, oPG) {
	if (!oPG) return { total: getLoansApproved(data) }

	const groupKey = getGroupKey(oPG)

	return _.chain(data).filter(i => i.got_loan)
                      .groupBy(groupKey)
                      .mapValues((i, cat) => i.length)
                      .value()
                      // .mapValues((i, cat) => {
                      //   return {
                      //     cat, 
                      //     val: i.length
                      //   }})
                      // .sortBy('cat')
                      // .map(i => i.val)
                      // .value()
}

function getLoansDeniedByCategory(data, oPG) {
	if (!oPG) return { total: getLoansDenied(data) }

	const groupKey = getGroupKey(oPG)
	
	return _.chain(data).filter(i => !i.got_loan)
                      .groupBy(groupKey)
                      .mapValues((i, cat) => i.length)
                      .value()
                      // .mapValues((i, cat) => {
                      //   return {
                      //     cat, 
                      //     val: i.length
                      //   }})
                      // .sortBy('cat')
                      // .map(i => i.val)
                      // .value()
}

function getGroupFairness(data) {
	return _.round(_.filter(data, i => i.got_loan).length / data.length, 2)
}

function getPredictiveParity(data) {
	return _.round(_.filter(data, i => i.got_loan && i.paid_back).length / _.filter(data, i => i.paid_back).length, 2)
}

function getFalsePositiveRate(data) {
	return _.round(_.filter(data, i => i.got_loan && !i.paid_back).length / _.filter(data, i => !i.paid_back).length, 2)
}

function getFalseNegativeRate(data) {
	return _.round(_.filter(data, i => !i.got_loan && i.paid_back).length / _.filter(data, i => i.paid_back).length, 2)
}

function getBM(consumerData, oPG, oBM) {
	if (!oPG || !oBM) return null

	const grpKey = getGroupKey(oPG)
	let bmr = 0
	let bmr_grouped = {}
	switch (oBM.name) {
		case 'Group Fairness':
			bmr = getGroupFairness(consumerData)
			bmr_grouped = _.chain(consumerData)
											.groupBy(grpKey)
											.mapValues((o) => getGroupFairness(o))
											.value()
			break
		case 'Predictive Parity':
			bmr = getPredictiveParity(consumerData)
			bmr_grouped = _.chain(consumerData)
											.groupBy(grpKey)
											.mapValues((o) => getPredictiveParity(o))
											.value()
			break
		case 'False Positive Rate':
			bmr = getFalsePositiveRate(consumerData)
			bmr_grouped = _.chain(consumerData)
											.groupBy(grpKey)
											.mapValues((o) => getFalsePositiveRate(o))
											.value()
			break
		case 'False Negative Rate':
			bmr = getFalseNegativeRate(consumerData)
			bmr_grouped = _.chain(consumerData)
											.groupBy(grpKey)
											.mapValues((o) => getFalseNegativeRate(o))
											.value()
			break
		default:
			break
	}

	const aggregate_bias = _.round(_.values(bmr_grouped).reduce((sum, val) => sum + Math.abs(bmr - val), 0), 2)

	return {
		bmr,
		bmr_grouped,
		aggregate_bias,
	}
}

function getCW(data) {
	return _.round(_.filter(data, i => i.got_loan).length / data.length, 2)
}

export {
	isRegulator,
	getRole,
	isGameStarted,
	getPlayersOfGame,
	getLendersOfGame,
	getPlayersCountOfGame,
	getJoinedPlayersCountOfGame,
	getGroupKey,
	gotLoan,
	getAccuracy,
	getLoanProfit,
	getLoansApprovedByCategory,
	getLoansDeniedByCategory,
	getGroupFairness,
	getPredictiveParity,
	getFalsePositiveRate,
	getFalseNegativeRate,
	getBM,
	getCW,
}