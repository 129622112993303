import React from 'react';
import { Modal, ModalBody, Button } from 'appkit-react'
import numWords from 'num-words'
import { range, capitalize } from 'app/common/utils'
import { COLON } from 'app/common/constants'
import { Images } from 'app/assets'

const RoundSubmitted = ({ isSubmitAfterTimeout, visible, currentRoundActions, isCurrentRoundFinished, currentRoundPlayers, submittedPlayers, roundClock, hidePopup }) => {
  const playersCount = currentRoundPlayers.length
  const submittedCount = submittedPlayers.length
  const title = isSubmitAfterTimeout ? 'You Ran Out of Time!' 
                                            : `You've Submitted Round ${capitalize(numWords(currentRoundActions.round))}!`
  const messages = isSubmitAfterTimeout ? [
    `Don't worry, we've got you.`, 
    `For any selections that were required,`,
    `default selections were made.`
  ] : []
  
  messages.push(...[
    isCurrentRoundFinished ? `All players have submitted!` : `Not all players have submitted yet!`, 
    isCurrentRoundFinished ? `Loans decisions will process soon.` : `Loan decisions will process when all have submitted.`
  ])

  const handleCancel = () => {
    // hidePopup('roundSubmitted')
  }

  return visible ? (
		<Modal
      className="round-submitted-modal"
      visible={visible}
      backdropClosable={false}
      onCancel={handleCancel}
    >
      <ModalBody>
        <div className="main-area">
          <span className='title'>{title}</span>

          <div>
            {submittedCount > 0 && range(1, submittedCount).map(i => (
              <img key={i} className='player-icon player-icon-active' src={Images.player_30_active} />
            ))}
            {(playersCount - submittedCount) > 0 
                && range(1, playersCount - submittedCount).map(i => (
              <img key={i} className='player-icon' src={Images.player_30} />
            ))}
          </div>

          <div className='messages'>
            {messages.map((i, index) => (
              <span key={index}>{i}</span>
            ))}
          </div>
          
          {!isSubmitAfterTimeout && (
            <div className='round-clock'>
              <span className='minute'>{roundClock.min}</span>
              <span className='separator'>{COLON}</span>
              <span className={roundClock.val > roundClock.warningVal ? 'second' : 'second second-warning'}>{roundClock.sec}</span>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  ) : (null)
};

export default RoundSubmitted
