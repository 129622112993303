import React from 'react';
import { Button } from 'appkit-react';
import { range, capitalize } from 'app/common/utils'
import { CalculateService } from 'app/common/services'
import { PLAYER_ROLE_MAP, MOMENT_FORMAT, COLON } from 'app/common/constants'
import { Images } from 'app/assets'
import numWords from 'num-words'

const Headmast = ({ game, role, submittedPlayers, currentRoundPlayers, currentRoundActions, isPlayerSubmitted, roundClock }) => {
	const playersCount = currentRoundPlayers.length
	const submittedCount = submittedPlayers.length

  return (
    <div className='headmast-wrapper'>
    	<div className='headmast'>
    		<div>
		      <span>Round {capitalize(numWords(currentRoundActions.round))}</span>
		      <span>{game.name} - Round {currentRoundActions.round}/{game.rounds}</span>
		      <div>
			      <span>Your Role: </span>
			      <span>{role.title}</span>
		      </div>
		    </div>

		    <div>
		    	<div className='round-clock'>
		    		<span className='minute'>{roundClock.min}</span>
		    		<span className='separator'>{COLON}</span>
		    		<span className={roundClock.val > roundClock.warningVal ? 'second' : 'second second-warning'}>{roundClock.sec}</span>
		    	</div>
		    	{playersCount - submittedCount === 1 && !isPlayerSubmitted ? (
		    		<span>Waiting on you to submit!</span>
		    	) : (
		    		<div>
			    		<span>{submittedCount}/{playersCount} players </span>
			    		<span>have submitted</span>
			    	</div>
		    	)}

		    	<div>
		    		{submittedCount > 0 && range(1, submittedCount).map(i => (
	            <img key={i} className='player-icon' src={Images.player_active} />
	    			))}
	    			{(playersCount - submittedCount) > 0 
	    					&& range(1, playersCount - submittedCount).map(i => (
	    				<img key={i} className='player-icon' src={Images.player} />
	    			))}
		    	</div>
		    </div>
    	</div>
    </div>
  )
};

export default Headmast;
