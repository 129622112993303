// actions
import types from './types'

export default {
	getGamePlayInfoRequest: (value) => ({
		type: types.GET_GAME_PLAY_INFO_REQUEST,
		value,
	}),

	getGamePlayInfoSuccess: (value) => ({
		type: types.GET_GAME_PLAY_INFO_SUCCESS,
		value,
	}),

	getGamePlayInfoFailure: (value) => ({
		type: types.GET_GAME_PLAY_INFO_FAILURE,
		value,
	}),

	updateRoundActions: (value) => ({
		type: types.UPDATE_ROUND_ACTIONS,
		value,
	}),

	updateActionsStatus: (value) => ({
		type: types.UPDATE_ACTIONS_STATUS,
		value,
	}),

	addLenders: (value) => ({
		type: types.ADD_LENDERS,
		value,
	}),
	
	toggleAlert: (value) => ({
		type: types.PLAY_TOGGLE_ALERT,
		value,
	}),

	togglePopup: (value) => ({
		type: types.PLAY_TOGGLE_POPUP,
		value,
	}),

	roundTimePassed: (value) => ({
		type: types.ROUND_TIME_PASSED,
		value,
	}),

	updateDataScientist: (value) => ({
		type: types.UPDATE_DATA_SCIENTIST,
		value,
	}),

	submitRoundRequest: (value) => ({
		type: types.SUBMIT_ROUND_REQUEST,
		value,
	}),

	submitRoundSuccess: (value) => ({
		type: types.SUBMIT_ROUND_SUCCESS,
		value,
	}),

	submitRoundFailure: (value) => ({
		type: types.SUBMIT_ROUND_FAILURE,
		value,
	}),

	playerHasSubmitted: (value) => ({
		type: types.PLAYER_HAS_SUBMITTED,
		value,
	}),

	readyToResult: (value) => ({
		type: types.READY_TO_RESULT,
		value,
	})
}