import * as CONSTANTS from 'app/common/constants'
import axios from 'axios'

const lasAxios = axios.create({
	baseURL: CONSTANTS.LAS_API_BASE,
	timeout: 90000,
})

const cmsAxios = axios.create({
	baseURL: CONSTANTS.CMS_API_BASE,
  timeout: 90000,
})

export {
	lasAxios,
	cmsAxios,
}
