import React, {useEffect} from 'react';
import { Button, Table } from 'appkit-react';
import {Link} from 'react-router-dom';
import { Loading, LabelledInput, LabelledInputInlineWitherror} from 'app/common/components';
import * as RouteName from 'app/routes/route-name';

const NewGameComponent = ({
	isLoading,
	settings,
	playerDuplicatedValue,
	isPlayerDuplicatedMsg,
	responseError,
	isGameCreated,
	changeSettings,
	addLenders,
	deleteLenders,
	createGameRequest,
	push,
}) => {

	useEffect(() => {
    if (isGameCreated) {
			push('/');
		}
	}, [isGameCreated])

	const handleNewGame = () => {
		let playerNameValues = [...settings.financialLenders.value]
		playerNameValues.unshift(settings.regulator.value)
		var payload
		switch (settings.financialLenders.value.length) {
			case 1:
			payload = {
				name: settings.game_name.value,
				regulator: playerNameValues[0],
				financial_lender_1: playerNameValues[1],
			};
			case 2:
			payload = {
				name: settings.game_name.value,
				regulator: playerNameValues[0],
				financial_lender_1: playerNameValues[1],
				financial_lender_2: playerNameValues[2],
			};
			case 3:
			payload = {
				name: settings.game_name.value,
				regulator: playerNameValues[0],
				financial_lender_1: playerNameValues[1],
				financial_lender_2: playerNameValues[2],
				financial_lender_3: playerNameValues[3],
			};
		}
		createGameRequest(payload);
	}

	const lendersDom = settings.financialLenders.value.map((item, i) => {
    return (
			<div key={i} className='financial-lenders'>
				<LabelledInputInlineWitherror 
					obj={{indx: i, 
								name: settings.financialLenders.name, 
								title: settings.financialLenders.titlePrefix.concat(i+1), 
								value: item}}
					hasError={ playerDuplicatedValue.indexOf(item) != -1 }
					errMsg={ isPlayerDuplicatedMsg }
					changeSettings={ changeSettings }/>
				{i != 0 && (
					<Button className='delete-button' onClick={deleteLenders}>
						<span className='appkiticon icon-delete-fill a-text-orange'></span>
					</Button>
				)}
			</div>
		)
  })

	return (
		<>
		{isLoading ? <Loading /> : null}
		<div className='new-game-page'>
			<Link to={RouteName.home}>
        <span className='back-icon appkiticon icon-left-chevron-outline a-text-orange'></span>
        <span>BACK TO HOME PAGE</span>
      </Link>
			<div>
    	  <span>New Game</span>
					<Button 
						onClick={handleNewGame}
						disabled={ playerDuplicatedValue.length > 0 || responseError != '' || 
							settings.game_name.value === '' || settings.regulator.value === '' || 
							settings.financialLenders.value.indexOf('') != -1 }>
						CREATE GAME
					</Button>
      </div>
			<div>
				<LabelledInputInlineWitherror 
					obj={settings.game_name} 
					hasError={ responseError != '' }
					errMsg={ responseError }
					changeSettings={changeSettings}/>
			</div>
			<div>
				<span>Create Your Team</span>
			</div>
			<div>
				<div>
					<div>
						<span className='appkiticon icon-capital-fill a-text-orange'></span>
						<span>Add One Regulator</span>
					</div>
					<p>Seeks to increase the percentage of loans accepted, reduce bias and improving profits.</p>
					<LabelledInputInlineWitherror 
						obj={settings.regulator}
						hasError={ playerDuplicatedValue.indexOf(settings.regulator.value) != -1 }
						errMsg={ isPlayerDuplicatedMsg }
						changeSettings={changeSettings}/>
				</div>
				<div>
					<div>
						<span className='appkiticon icon-wealth-fill a-text-orange'></span>
						<span>Add Multiple Lenders</span>
					</div>
					<p>Seeks to tune their approval algorithm to make a profit while dodging penalties from the Regulator.</p>
					{ lendersDom }
					<div className='button-div'>
						{settings.financialLenders.value.length < 3 && (
							<Button className='add-lenders-button' kind='secondary' onClick={addLenders}>ADD FINANCIAL LENDERS</Button>
						)}
					</div>
				</div>
			</div>
		</div>
		</>
	)
};

export default NewGameComponent;