import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'appkit-react/style/appkit-react.default.css';
import { Input } from 'appkit-react';

const LabelledInput = ({obj, disabled, hasError, errMsg, changeSettings}) => {
	function onChange(val) {
		const keyval = {
			key: obj.name,
			val: val,
			index: obj.indx
		}
		changeSettings(keyval)
	}

	return (
    <div style={theStyle.container} className="input-container">
		  <Input
			  readonly={disabled}
			  hasError={hasError}
		  	label={obj.title}
				value={obj.value}
				errMsg={errMsg}
		  	onChange={onChange}
		  />
		</div>
  )
};

export default LabelledInput;

const theStyle = {
	label: {
		lineHeight: '1rem',
  	fontSize: '0.875rem',
  	marginRight: '1.25rem',
  	flex: '0 0 auto',
  	width: '160px',
  	textAlign: 'right',
	},
	container: {
		display: 'inline-flex',
		flexDirection: 'column',
		width: '300px',
  	marginBottom: '1rem',
	}
}
