import React from 'react';
import { Button, Table } from 'appkit-react';
import { Link } from "react-router-dom";
import * as RouteName from 'app/routes/route-name'
import { range, lodash2space } from 'app/common/utils'
import moment from 'moment'
import { MOMENT_FORMAT, PLAYER_ICON, PLAYER_ROLE, LODASH } from 'app/common/constants'
import { CalculateService } from 'app/common/services'
import { Images } from 'app/assets'

const Overview = ({ game, player }) => {
  const overview = {
    game: {
      question: 'Who should get a loan?',
      description: 'A simple question with a complicated answer.',
      answer: 'Weigh the tradeoffs between profit and algorithmic fairness within financial loans.',
      definition: `The Regulation Game is a role-playing simulation that puts players in two very different positions: a government regulator vs. private financial lenders.`
    },
    roles: [
      {
        title: 'The Regulator',
        icon: PLAYER_ICON.regulator.grey,
        definition: `The AI Regulator seeks to increase percentage of loans accepted while reducing bias and improving their profits.`
      },
      {
        title: 'The Financial Lenders',
        icon: PLAYER_ICON.lender.grey,
        definition: `Financial Lenders need to figure out how to tune their approval algorithm to make a profit while dodging scrutiny and/or penalties from the Regulator.`
      }
    ],
    players: CalculateService.getPlayersOfGame(game).map(i => ({
      title: (CalculateService.isRegulator(i) ? lodash2space(i) : lodash2space(i.replace(/financial_/gi, ''))),
      name: (i === player ? 'You!' : game[i]),
      icon: PLAYER_ICON[CalculateService.isRegulator(i) ? PLAYER_ROLE.regulator : PLAYER_ROLE.lender].white
    }))
  }
	
  return (
    <div className='overview-zone'>
    	<div>
        <div className='game-zone'>
          <div>
            <div><span>{overview.game.question}</span></div>
            <div><span>{overview.game.description}</span></div>
          </div>
          <div>
            <span>{overview.game.answer}</span>
            <span>{overview.game.definition}</span>
          </div>
        </div>

        <div className='roles-zone'>
          <span>Which role are you?</span>
          {overview.roles.map((i, index) => (
            <div key={index}>
              <div>
                <img src={i.icon} />
                <span>{i.title}</span>
              </div>
              <span>{i.definition}</span>
            </div>
          ))}
        </div>
      </div>

      <div className='team-zone'>
        <span>Your Team</span>
        {overview.players.map((i, index) => (
          <div key={index}>
            <div>
              <img src={i.icon} />
            </div>
            <div>
              <span>{i.title}</span>
              <span>{i.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
};

export default Overview;
