// reducers
import { LOCATION_CHANGE } from 'connected-react-router'
import types from './types'
import _ from 'lodash'

const INITIAL_STATE = {
	isLoading: true,
  isLoaded: false,
  isProcessing: false,
  hasNotLaunched: false,
  isCurrentRoundNotFinished: false,
  popup: {
    auditReport: {},
    quarterMarketReport: {},
  },
  game: {},
  currentResults: {},
  topPerformer: {},
  allOptions: {
  	thresholds: {},
    groupCategories: [],
    biasMetrics: [],
    actions: [],
  },
  joinedPlayers: [],
  auditReport: {},
}

const resultReducer = (state=INITIAL_STATE, {type, value}) => {
  switch(type) {
    case LOCATION_CHANGE:
      return INITIAL_STATE
  	case types.GET_ROUND_RESULTS_REQUEST:
  		return {
  			...state,
  			isLoading: true,
  		}
  	case types.GET_ROUND_RESULTS_SUCCESS:
  		return {
  			...state,
  			isLoading: false,
  			isLoaded: true,
  			...value,
  		}
  	case types.GET_ROUND_RESULTS_FAILURE:
  		return {
  			...state,
  			isLoading: false,
        ...value,
  		}
    case types.START_NEXT_ROUND_REQUEST:
      return {
        ...state,
        isProcessing: true,
      }
    case types.START_NEXT_ROUND_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        ...value,
      }
    case types.START_NEXT_ROUND_FAILURE:
      return {
        ...state,
        isProcessing: false,
      }
    case types.PLAYER_HAS_JOINED_NEXT_ROUND:
      return {
        ...state,
        joinedPlayers: _.uniq(state.joinedPlayers.concat(value)),
      }
    case types.RESULT_TOGGLE_POPUP:
      return {
        ...state,
        popup: {
          ...state.popup,
          [value.key]: {
            ...state.popup[value.key],
            ...value.payload,
          }
        },
      }
    case types.UPDATE_AUDIT_REPORT:
      return {
        ...state,
        auditReport: {
          ...state.auditReport,
          ...value,
        }
      }
    default: return state;
  }
}

export default resultReducer;
