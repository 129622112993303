import React from 'react';
import { Button } from 'appkit-react';
import { COMMA, SPACE, POINT, PLAYER_ROLE_MAP } from 'app/common/constants'
import { formatCurrency, number2Percent } from 'app/common/utils'
import { Images } from 'app/assets'
import _ from 'lodash'

const Summary = ({ 
	player, role, game, winner, results
}) => {
  const ranking = _.chain(results).sortBy(['rank']).map(i => i.player).value()
  const bankrupts = results.filter(i => !i.isRegulator && i.wealth < 1).map(i => i.player)
  const isCurrentPlayer = playerItem => playerItem === player
  const sequenceWord = num => {
    switch (num) {
      case 1:
        return '1st'
      case 2:
        return '2nd'
      case 3:
        return '3rd'
      default:
        return `${num}th`
    }
  }

  const isCurrentPlayerWinner = winner && winner.indexOf(player) > -1

  return (
    <div className='finish-summary'>
    	<div>
        {winner ? (
          <>
            <img className='trophy' src={Images.trophy_blue} />
            <span>{isCurrentPlayerWinner ? 'You Take' : `${game[ranking[0]]} Takes`} First!</span>
          </>
        ) : (
          <span>Nobody wins.</span>
        )}
    	</div>
      {winner ? (
        <div>
          {(isCurrentPlayerWinner ? ranking.filter(i => i != player) : ranking.slice(1)).map((i, index) => (
            <React.Fragment key={index}>
              {bankrupts.indexOf(i) > -1 ? (
                <span>{isCurrentPlayer(i) ? `${index === 0 ? 'A' : 'a'}s the ${role.title}, you` : `${game[i]}`} went bankrupt{index+2 < ranking.length ? COMMA+SPACE : POINT}</span>
              ) : (
                <>
                  {winner.indexOf(i) > -1 ? (
                    <span>{isCurrentPlayer(i) ? `${index === 0 ? 'A' : 'a'}s the ${role.title}, you also take ${sequenceWord(1)}` : `${game[i]} also takes ${sequenceWord(1)}`} place{index+2 < ranking.length ? COMMA+SPACE : POINT}</span>
                  ) : (
                    <span>{isCurrentPlayer(i) ? `${index === 0 ? 'A' : 'a'}s the ${role.title}, you take` : `${game[i]} takes`} {sequenceWord(index+2)} place{index+2 < ranking.length ? COMMA+SPACE : POINT}</span>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      ) : (
        <span>{results.length > 2 ? `All ${PLAYER_ROLE_MAP.lender.title}s` : `The ${PLAYER_ROLE_MAP.lender.title}`} went bankrupt.</span>
      )}
    	
    </div>
  )
};

export default Summary;
