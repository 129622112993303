import React from 'react';
import { Button } from 'appkit-react';
import { range, formatCurrency } from 'app/common/utils'
import { PLAYER_ICON } from 'app/common/constants'
import { Images } from 'app/assets'
import _ from 'lodash'

const UserCard = ({ game, player, role, currentRoundActions, allOptions, submitRound }) => {
	const pgs = currentRoundActions.protected_groups
	const bm = currentRoundActions.bias_metric
	const actions = []

	if (currentRoundActions.actions) {
		currentRoundActions.actions.forEach(action => {
			const indexForactions = _.findIndex(actions, n => n.id === action.id);
			if(indexForactions != -1){
				actions[indexForactions].count++;
			} else {
				actions.push({
					...action,
					count: 1
				})
			}
		})
	}

  const disabledToSubmit = (pgs.length > 0 && !bm) || (pgs.length < 1 && bm)

  return (
  	<div className='user-card'>
			<div className='user-info-container'>
				<div className='user-info'>
					<div className='title'>
						<img src={PLAYER_ICON[role.name].grey} />
						<span>{game[player]}</span>
					</div>
					<div className='prop-row'>
						<span>{role.title}</span>
					</div>
					<div className='prop-row'>
						<span>Wealth: </span>
						<span>{formatCurrency(currentRoundActions.wealth)}</span>
					</div>
					<div className='prop-row'>
						<span>CW: </span>
						<span>{currentRoundActions.consumer_welfare}</span>
					</div>
				</div>

				{(pgs[0] || bm || actions[0]) && (
					<div className='user-selection'>
						<div className='title'>
							<span>My Selections</span>
						</div>

						{pgs.map((i, index) => (
							<span key={index} className='selection-item'>{allOptions.groupCategories.find(group => i === group.id).name}</span>
						))}

						{bm && (
							<span className='selection-item'>{allOptions.biasMetrics.find(bias => bm === bias.id).name}</span>
						)}

						{	actions.map((i, index) => {
								const action = allOptions.actions.find(action => i.id === action.id)
								return(
									<div key={index}>
										<span className='selection-item'>{action.name}</span>
										<span className='selection-item-next'>{'Cost ' + formatCurrency(action.cost * i.count)}</span>
									</div>
								)
							})
						}
					</div>
				)}
			</div>

	    <Button className='submit-button' disabled={disabledToSubmit} gray={disabledToSubmit} onClick={() => {submitRound()}}>SUBMIT ROUND</Button>
	    {disabledToSubmit && (
	    	<span className='tip-message'>Please Select a Bias Metric.</span>
	    )}
  	</div>
  )
};

export default UserCard;
