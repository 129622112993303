import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'appkit-react/style/appkit-react.default.css';

const Loading = ({}) => {
	return (
		<div style={theStyle.container}>
	    <div className='a-loading a-default a-m-10'>
			</div>
		</div>
  )
};

export default Loading

const theStyle = {
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 9999,
		backgroundColor: '#F2F2F2',
		opacity: 0.6,
	},
}
