// reducers
import { LOCATION_CHANGE } from 'connected-react-router'
import types from './types'
import _ from 'lodash'

const INITIAL_STATE = {
	isLoading: true,
  isLoaded: false,
  isProcessing: false,
  isGameCompleted: false,
  isAllBankrupt: false,
  isBankrupt: false,
  hasNotLaunched: false,
  hasNotJoined: false,
  isPlayerSubmitted: false,
  isSubmitRequested: false,
  isSubmitAfterTimeout: false,
  isReadyToResult: false,
	error: null,
  alert: {},
  popup: {
    adjustThresholds: {},
  },
	game: {},
  submittedActions: [],
  submittedPlayers: [],
  currentRoundPlayers: [],
  previousRoundsActions: [],
  selectedPlayerInActions: [],
  currentRoundActions: {},
  originalWealth: 0,
  originalModel: 0,
  allOptions: {
    thresholds: {},
    groupCategories: [],
    biasMetrics: [],
    actions: [],
  },
  roundLifeTime: -1,
  dataScientist: {},
}

const playReducer = (state=INITIAL_STATE, {type, value}) => {
  var actions = state.allOptions.actions;
  var selectedPlayerInActions = state.selectedPlayerInActions;
  var newActions = [];
  var cost = 0;
  var modelReduce = 0;
  switch(type) {
    case LOCATION_CHANGE:
      return INITIAL_STATE
  	case types.GET_GAME_PLAY_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_GAME_PLAY_INFO_SUCCESS:
      console.log(value)
      value.allOptions.actions.forEach(item => {
        item.isSelected = false;
      })
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isSubmitRequested: value.isPlayerSubmitted,
        originalWealth: value.currentRoundActions.wealth,
        originalModel: value.currentRoundActions.model,
        ...value,
        roundLifeTime: value.game.round_time * 60,  
      }
    case types.GET_GAME_PLAY_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...value,
      }
    case types.UPDATE_ROUND_ACTIONS:
      const currentRoundActions = {
        ...state.currentRoundActions,
        ...value,
      }

      const dataScientist = value.use_data_scientist 
                            ? {
                                protected_groups: [...currentRoundActions.protected_groups],
                                bias_metric: currentRoundActions.bias_metric,
                                adjusted_threholds: {...currentRoundActions.adjusted_threholds},
                              }
                            : state.dataScientist
      // const dataScientist = _.mapValues(state.dataScientist, (val, key) => (value.hasOwnProperty(key) ? value[key] : val))

      return {
        ...state,
        currentRoundActions,
        dataScientist,
      }
    case types.UPDATE_ACTIONS_STATUS:
      actions.forEach(item => {
        if(item.id === value.id) {
          item.isSelected = !item.isSelected;
        }
      })
      return {
        ...state,
        allOptions: {
          ...state.allOptions,
          actions,
        }
      }
    case types.ADD_LENDERS:
      if(value.id != null) {
        var isUpdated = false;
        var willDelete = false;
        selectedPlayerInActions.forEach((item, index) => {
          if (item.id === value.id) {
            if(item.player === value.player) {
              willDelete = true;
              isUpdated = true;
            }
            //   item.player = value.player;
            //   isUpdated = true;
          }
        })

        if(!isUpdated) {
          selectedPlayerInActions.push(value);
        }

        if(willDelete) {
          _.remove(selectedPlayerInActions, n => {
            return n.id === value.id && n.player === value.player;
          })
        }
      }

      actions.forEach((item, index) => {
        if(item.isSelected) {
          selectedPlayerInActions.forEach((element, index) => {
            if(element.id === item.id) {
              cost += item.cost;
              newActions.push(element);
              if(element.id === 6) {
                modelReduce ++;
              }
            }
          })
        }
      })
      return {
        ...state,
        selectedPlayerInActions: newActions,
        currentRoundActions: {
          ...state.currentRoundActions,
          actions: newActions,
          wealth: state.originalWealth - cost,
          model: state.currentRoundActions.model > 1 ? state.originalModel - modelReduce : 1
        }
      }
    case types.PLAY_TOGGLE_ALERT:
      return {
        ...state,
        alert: value,
      }
    case types.PLAY_TOGGLE_POPUP:
      return {
        ...state,
        popup: {
          ...state.popup,
          ...value,
        },
      }
    case types.ROUND_TIME_PASSED:
      return {
        ...state,
        roundLifeTime: state.roundLifeTime - 1,
      }
    case types.UPDATE_DATA_SCIENTIST:
      return {
        ...state,
        dataScientist: {
          ...state.dataScientist,
          ...value,
        }
      }
    case types.SUBMIT_ROUND_REQUEST:
      return {
        ...state,
        isProcessing: true,
        isSubmitRequested: true,
        isSubmitAfterTimeout: value.submitAfterTimeout,
      }
    case types.SUBMIT_ROUND_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isPlayerSubmitted: true,
        submittedActions: [
          ...state.submittedActions,
          { player: value.player, actions: value.actions }
        ],
        submittedPlayers: _.uniq([...state.submittedPlayers, value]),
      }
    case types.SUBMIT_ROUND_FAILURE:
      return {
        ...state,
        isProcessing: false,
      }
    case types.PLAYER_HAS_SUBMITTED:
      return {
        ...state,
        submittedActions: [
          ...state.submittedActions,
          { player: value.player, actions: value.actions }
        ],
        submittedPlayers: _.uniq([...state.submittedPlayers, value.player]),
      }
    case types.READY_TO_RESULT:
      return {
        ...state,
        isReadyToResult: true,
      }
    default: return state;
  }
}

export default playReducer;
