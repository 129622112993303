const Icons = {
  capital_black: require('app/assets/icons/capital_black.svg'),
  capital_grey: require('app/assets/icons/capital_grey.svg'),
  capital_orange: require('app/assets/icons/capital_orange.svg'),
  capital_white: require('app/assets/icons/capital_white.svg'),
  lender: require('app/assets/icons/lender.svg'),
  pwc_logo_dark: require('app/assets/icons/pwc_logo_dark.svg'),
  pwc_logo_white: require('app/assets/icons/pwc_logo_white.svg'),
  regulator: require('app/assets/icons/regulator.svg'),
  share_box_black: require('app/assets/icons/share_box_black.svg'),
  wealth_black: require('app/assets/icons/wealth_black.svg'),
  wealth_grey: require('app/assets/icons/wealth_grey.svg'),
  wealth_orange: require('app/assets/icons/wealth_orange.svg'),
  wealth_white: require('app/assets/icons/wealth_white.svg')
}
export default Icons
