import React from 'react';
import { Button, Tooltip } from 'appkit-react';
import { range, capitalize } from 'app/common/utils'
import { CalculateService } from 'app/common/services'
import { PLAYER_ROLE_MAP, MOMENT_FORMAT, COLON, GAME_STATUS } from 'app/common/constants'
import { Images } from 'app/assets'
import numWords from 'num-words'

const Headmast = ({
	game,
	role,
	currentResults,
	startNextRound,
	finishGame
}) => {
	const nextRound = currentResults.round + 1
	const isAllBankrupts = currentResults.nonBankrupts.filter(i => i != PLAYER_ROLE_MAP.regulator.name).length < 1

  return (
    <div className='headmast-wrapper'>
    	<div className='headmast'>
    		<div>
		      <span>Round {capitalize(numWords(currentResults.round))} Results</span>
		      <span>{game.name} - Round {currentResults.round}/{game.rounds}</span>
		      <div>
			      <span>Your Role: </span>
			      <span>{role.title}</span>
		      </div>
		    </div>

		    <div>
		    	{game.status === GAME_STATUS.COMPLETED ? (
		    		<>
		    			{isAllBankrupts ? (
		    				<Tooltip
									content={'All financial lenders have gone bankrupt. Please proceed to the game summary page.'} 
									placement="left"
									tooltipTheme='light'
									style={{width: 321}}>
				    			<Button onClick={finishGame}>GAME OVER</Button>
								</Tooltip>
		    			) : (
		    			<Button onClick={finishGame}>FINISH GAME</Button>
		    			)}
		    		</>
		    	) : (
			    	<>
			    		{currentResults.wealth > 0 ? (
			    			<Button onClick={startNextRound}>START ROUND {nextRound}</Button>
			    		) : (
				    		<Tooltip
									content={'You went bankrupt, please view summary after the game is completed.'} 
									placement="left"
									tooltipTheme='light'
									style={{width: 321}}>
				    			<Button disabled>YOU'RE BANKRUPT</Button>
								</Tooltip>
			    		)}
			    	</>
		    	)}
		    </div>
    	</div>
    </div>
  )
};

export default Headmast;
