import React from 'react';
import { Button, Panel, RadioGroup, Radio } from 'appkit-react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const BarChartGroup = ({ categories, chartData, hidePopup }) => {
  var colorfulData = []; 
  var whiteData = [];
  var colors = [];
  categories.forEach((item, index) => {
    colorfulData.push(chartData.data.bmr_grouped[item.id]);
    whiteData.push(1 - chartData.data.bmr_grouped[item.id]);
    colors.push('#F1BAC3');
  })

  const maxPercent = Math.max(...colorfulData);
  colorfulData.forEach((item, index) => {
    if(item === maxPercent) {
      colors[index] = '#A43E50';
    }
  })

  const chartOption = {
    chart: {
      type: 'bar',
      height: 55*categories.length,
      // height: 220,
      backgroundColor: '#F2F2F2',
      style: {
        fontFamily: 'Helvetica Neue',
      }
    },
    title: {
      text: '',
    },
    legend: {
      reversed: true,
      enabled: false
    },
    credits: {
      enabled: false
    },
    xAxis: {
      visible: true,
      categories: categories.map(i => i.name),
      lineWidth: 0,
      labels: {
        style: {
          fontSize: '16px'
        }
      }
    },
    yAxis: {
      visible: true,
      reversedStacks: false,
      tickPositions: [0, 100, 120],
      tickmarkPlacement: '',
      gridLineWidth: 0,
      plotLines: [{ 
        color: '#FFA929',
        width: 2,
        value: chartData.data.bmr * 100,
        zIndex: 15,
      }],
      labels: {
        enabled: false
      },
      title: {
        enabled: false
      }
    },
    plotOptions: {
      series: {
        animation: false,
        stacking: 'percent'
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        name: 'percentage',
        pointWidth: 20,
        // colors: ['#F1BAC3', '#A43E50', '#A43E50', '#A43E50'],
        colors: colors,
        colorByPoint: true,
        data: colorfulData
      },
      { 
        name: 'Loans Denied',
        pointWidth: 20,
        grouping: false,
        dataLabels: {
          align: 'right',
          enabled: true,
          crop: false,
          overflow: 'none',
          x: 55,
          formatter: function() {
            return Math.round((1 - this.y) * 100) + '%' 
          },
          style: {
            fontSize: '20px',
            color: 'black',
            textOutline: 'none',
          }
        },
        colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
        colorByPoint: true,
        data: whiteData
      }
    ]
  }
  
  return (
    <div className='view-impact-bar-chart-group-chart'>
      <HighchartsReact highcharts={Highcharts} options={chartOption}/>
    </div>
  )
}

export default BarChartGroup