// operations using redux-saga
import { all, call, put, take, takeLatest, takeLeading } from 'redux-saga/effects';
import { GAME_STATUS, PLAYERS, PLAYER_ROLE_MAP, DEFAULT_ROUND_SETTINGS } from 'app/common/constants'
import { joinByAnd } from 'app/common/utils'
import { CMSService, CalculateService } from 'app/common/services'
import _ from 'lodash'

import types from './types'
import actions from "./actions"

function* getActiveGames(action) {
	try {
		const collection = 'games'
		const filter = [{ field: 'status', operator: 'eq', value: GAME_STATUS.ACTIVE }]
		const sort = '-created_at'
		const { data } = yield call(CMSService.getItemsByQuery, { collection, filter, sort })
		data.forEach(i => {
			i.players_count = CalculateService.getPlayersCountOfGame(i)
			i.joined_count = CalculateService.getJoinedPlayersCountOfGame(i)
		})

		yield put(actions.getActiveGamesSuccess(data))
	} catch (e) {
		yield put(actions.getActiveGamesFailure(e))
	}
}

function* getActiveGamesSaga() {
	yield takeLatest(types.GET_ACTIVE_GAMES_REQUEST, getActiveGames)
}

function* getCompletedGames(action) {
	try {
		// const collection = 'games'
		// const filter = [{ field: 'status', operator: 'eq', value: GAME_STATUS.COMPLETED }]
		// const sort = '-completed_at'
		// const { data } = yield call(CMSService.getItemsByQuery, { collection, filter, sort })
		// data.forEach(i => {
		// 	i.players_count = CalculateService.getPlayersCountOfGame(i)
		// 	i.joined_count = CalculateService.getJoinedPlayersCountOfGame(i)
		// 	i.final_outcome = 'Lorem ipsum'
		// })

		const [ res_completed_games, res_game_actions ] = yield all([
			call(CMSService.getItemsByQuery, {
				collection: 'games',
				filter: [
					{ field: 'status', operator: 'eq', value: GAME_STATUS.COMPLETED }
				],
				sort: '-completed_at'
			}),
			call(CMSService.getItemsByQuery, {
				collection: 'game_actions',
				fields: '*.*',
				filter: [
					{ field: 'is_result_out', operator: 'eq', value: 1 }
				],
				sort: 'game,player,-round'
			})
		])

		const completedGames = res_completed_games.data.map(itemGame => {
			const isAllBankrupt = res_game_actions.data
															.filter(i => i.game.id === itemGame.id && !CalculateService.isRegulator(i.player) && i.round === itemGame.rounds && i.wealth > 0)
															.length < 1

			// const players = CalculateService.getPlayersOfGame(itemGame)
			const players = _.chain(PLAYERS)
											 .filter(i => itemGame[i])
											 .map(player => {
											 		const isRegulator = CalculateService.isRegulator(player)

											 		const playerActions = _.chain(res_game_actions.data)
											 													.filter(i => i.game.id === itemGame.id && i.player === player)
											 													.orderBy(['round'], ['desc'])
											 													.value()
											 		const wealth = playerActions[0].wealth
											 		const averageCW = _.round(playerActions.reduce((sum, o) => sum + o.consumer_welfare, 0) / itemGame.rounds, 2)
											 		let rank = Infinity

											 		if (!isAllBankrupt) {
											 			if (isRegulator) {
												 			const rankData = _.chain(res_game_actions.data)
																.filter(i => i.game.rounds >= itemGame.rounds 
																					&& i.game.current_round >= itemGame.rounds 
																					&& i.round <= itemGame.rounds
																					&& i.player === PLAYER_ROLE_MAP.regulator.name)
																.groupBy('game')
																.mapValues((oGame, keyGame) => _.chain(oGame)
																																.groupBy('player')
																																.mapValues(oPlayer => _.orderBy(oPlayer, ['round'], ['desc']))
																																.mapValues((oPlayer, keyPlayer) => ({
																																	game: keyGame,
																																	player: keyPlayer,
																																	shouldCompare: oPlayer[0].round === itemGame.rounds,
																																	wealth: oPlayer[0].wealth,
																																	averageCW: _.round(oPlayer.reduce((sum, oRound) => sum + oRound.consumer_welfare, 0) / itemGame.rounds, 2)
																																}))
																																.toPairs()
																																.map(o => o[1])
																																.value())
																.toPairs()
																.map(o => o[1])
																.filter(o => o.shouldCompare)
																.value()

															rank = _.chain(_.union(...rankData))
																			.filter(o => o.averageCW > averageCW || o.averageCW === averageCW && o.wealth > wealth)
																			.value()
																			.length + 1
												 		} else if (wealth > 0) {
												 			const rankData = _.chain(res_game_actions.data)
												 				.filter(i => i.round === itemGame.rounds
												 									&& i.player != PLAYER_ROLE_MAP.regulator.name
												 									&& i.wealth > wealth)
												 				.value()
												 			rank = rankData.length + 1
												 		}
											 		}
												 	
											 		return { player, wealth, averageCW, rank }
											 })
											 .sortBy(['rank'])
											 .value()
			const topPerformer = {
				wealth: _.chain(res_game_actions.data)
									.filter(i => i.game.rounds === itemGame.rounds && i.game.status === GAME_STATUS.COMPLETED && i.round === itemGame.rounds)
									.map(i => i.wealth)
									.max()
									.value(),
				consumer_welfare: _.chain(res_game_actions.data)
														.filter(i => i.game.rounds === itemGame.rounds && i.game.status === GAME_STATUS.COMPLETED)
														.groupBy(i=> i.game.id)
														.mapValues((valGame, keyGame) => (_.chain(valGame)
																															.groupBy('player')
																															.mapValues((valPlayer, keyPlayer) => _.round(_.sumBy(valPlayer, 'consumer_welfare') / itemGame.rounds, 2))
																															.toPairs()
																															.map(i => i[1])
																															.max()
																															.value()))
														.toPairs()
														.map(j => j[1])
														.max()
														.value()
			}

			const winner = (isAllBankrupt ? null : _.chain(players).filter(i => i.rank === _.minBy(players, 'rank').rank).map(i => i.player).value())
			const final_outcome = winner ? (winner.length > 1 ? `Tie: ${joinByAnd(winner.map(i => itemGame[i]))}` : `${itemGame[winner]} won`) : 'All players went bankrupt'
			const is_top_performer = players.findIndex(i => i.wealth >= topPerformer.wealth || i.averageCW >= topPerformer.consumer_welfare) > -1

			const players_count = CalculateService.getPlayersCountOfGame(itemGame)
			const joined_count = CalculateService.getJoinedPlayersCountOfGame(itemGame)

			return {...itemGame, final_outcome, is_top_performer, players_count, joined_count}
		})

		yield put(actions.getCompletedGamesSuccess(completedGames))
	} catch (e) {
		yield put(actions.getCompletedGamesFailure(e))
	}
}

function* getCompletedGamesSaga() {
	yield takeLatest(types.GET_COMPLETED_GAMES_REQUEST, getCompletedGames)
}

function* createGame(action) {
	try {
		const payload = {...action.value}
		payload.round_settings = DEFAULT_ROUND_SETTINGS
		const { data } = yield call(CMSService.createItem, 'games', payload)
		yield put(actions.createGameSuccess(data))
	} catch (e) {
		yield put(actions.createGameFailure(e))
	}
}

function* createGameSaga() {
	yield takeLatest(types.CREATE_GAME_REQUEST, createGame)
}

function* deleteGame(action) {
	try {
		const gameId = action.value

		const resGameActions = yield call(CMSService.getItemsByQuery, {
			collection: 'game_actions',
			fields: 'id',
			filter: [{ field: 'game', operator: 'eq', value: gameId }],
		})
		const gameActionIds = resGameActions.data.map(i => i.id)

		yield call(CMSService.deleteItems, 'game_actions', gameActionIds)
		yield call(CMSService.deleteItem, 'games', gameId)

		yield put(actions.deleteGameSuccess(gameId))
	} catch (e) {
		yield put(actions.deleteGameFailure(e))
	}
}

function* deleteGameSaga() {
	yield takeLatest(types.DELETE_GAME_REQUEST, deleteGame)
}

function* getGamePlayers(action) {
	try {
		const gameId = action.value
		const collection = 'games'
		const fields = 'id,name,rounds,regulator,financial_lender_1,financial_lender_2,financial_lender_3'
		const { data } = yield call(CMSService.getItemById, collection, gameId, fields)
		data.lenders_count = CalculateService.getPlayersCountOfGame(data) - 1

		yield put(actions.getGamePlayersSuccess(data))
	} catch (e) {
		yield put(actions.getGamePlayersFailure(e))
	}
}

function* getGamePlayersSaga() {
	yield takeLatest(types.GET_GAME_PLAYERS_REQUEST, getGamePlayers)
}

export default {
	getActiveGamesSaga,
	getCompletedGamesSaga,
	createGameSaga,
	deleteGameSaga,
	getGamePlayersSaga,
}