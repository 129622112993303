// actions
import types from './types'

export default {
	getRoundResultsRequest: (value) => ({
		type: types.GET_ROUND_RESULTS_REQUEST,
		value,
	}),

	getRoundResultsSuccess: (value) => ({
		type: types.GET_ROUND_RESULTS_SUCCESS,
		value,
	}),

	getRoundResultsFailure: (value) => ({
		type: types.GET_ROUND_RESULTS_FAILURE,
		value,
	}),

	startNextRoundRequest: (value) => ({
		type: types.START_NEXT_ROUND_REQUEST,
		value,
	}),

	startNextRoundSuccess: (value) => ({
		type: types.START_NEXT_ROUND_SUCCESS,
		value,
	}),

	startNextRoundFailure: (value) => ({
		type: types.START_NEXT_ROUND_FAILURE,
		value,
	}),

	playerHasJoinedNextRound: (value) => ({
		type: types.PLAYER_HAS_JOINED_NEXT_ROUND,
		value,
	}),

	togglePopup: (value) => ({
		type: types.RESULT_TOGGLE_POPUP,
		value,
	}),

	updateAuditReport: (value) => ({
		type: types.UPDATE_AUDIT_REPORT,
		value,
	}),
}