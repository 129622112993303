// types
export default {
	GET_GAME_PLAY_INFO_REQUEST: 'GET_GAME_PLAY_INFO_REQUEST',
	GET_GAME_PLAY_INFO_SUCCESS: 'GET_GAME_PLAY_INFO_SUCCESS',
	GET_GAME_PLAY_INFO_FAILURE: 'GET_GAME_PLAY_INFO_FAILURE',

	UPDATE_ROUND_ACTIONS: 'UPDATE_ROUND_ACTIONS',
	UPDATE_ACTIONS_STATUS: 'UPDATE_ACTIONS_STATUS',
	ADD_LENDERS: 'ADD_LENDERS',
	PLAY_TOGGLE_ALERT: 'PLAY_TOGGLE_ALERT',
	PLAY_TOGGLE_POPUP: 'PLAY_TOGGLE_POPUP',

	ROUND_TIME_PASSED: 'ROUND_TIME_PASSED',

	SUBMIT_ROUND_REQUEST: 'SUBMIT_ROUND_REQUEST',
	SUBMIT_ROUND_SUCCESS: 'SUBMIT_ROUND_SUCCESS',
	SUBMIT_ROUND_FAILURE: 'SUBMIT_ROUND_FAILURE',

	UPDATE_DATA_SCIENTIST: 'UPDATE_DATA_SCIENTIST',

	PLAYER_HAS_SUBMITTED: 'PLAYER_HAS_SUBMITTED',

	READY_TO_RESULT: 'READY_TO_RESULT',
}