import storage from 'redux-persist/lib/storage'
import * as CONSTANTS from 'app/common/constants'
import { cmsAxios } from './axios'
import { getCMSToken, refreshCMSToken } from './las'
import _ from 'lodash'

cmsAxios.interceptors.request.use(
	async (config) => {
		// Do something before request is sent
		const cmsToken = await storage.getItem('cmsToken')
		const expiresIn = await storage.getItem('cmsTokenExpiresIn')

		let newToken = cmsToken
		let res

		if (noToken(cmsToken, expiresIn) || isTokenExpired(expiresIn)) {
			res = await getCMSToken()
		} else if (needRefreshToken(expiresIn)) {
			res = await refreshCMSToken(cmsToken)
		}

		if (res) {
			newToken = res
			await storage.setItem('cmsToken', newToken)
			await storage.setItem('cmsTokenExpiresIn', setTokenExpiresIn())
		}

		config.headers.Authorization = `Bearer ${newToken}`

		return config
	}, (e) => {
		// Do something with request error
	  return Promise.reject(e)
	})

cmsAxios.interceptors.response.use(
	(response) => {
		// Any status code that lie within the range of 2xx cause this function to trigger
	  // Do something with response data
	  return response.data
	}, (e) => {
	  // Any status codes that falls outside the range of 2xx cause this function to trigger
	  // Do something with response error
	  if (e.isLASError) {
	  	return Promise.reject(e)
	  } else if (e.response) {
	  	return Promise.reject({ ...e.response.data.error, isCMSError: true })
	  } else if (e.toJSON) {
	  	return Promise.reject({ ...e.toJSON() })
	  } else {
	  	return Promise.reject(e)
	  }
	})

// Get all records of specific collection with specific fields
function getItems(collection, fields='*.*') {
	const url = `items/${collection}?limit=-1&fields=${fields}`
	return cmsAxios.get(url)
}

// Get all records of specific collection with specific fields by filtering with multple filters
function getItemsByFilters(collection, filters, fields='*.*', sort) {
	const sortPart = sort ? `&sort=${sort}` : ''
	const filterPart = filters.map(i => `&filter[${i.field}][${i.operator}]=${i.value}`).join('')
	const url = `items/${collection}?limit=-1&fields=${fields}${filterPart}${sortPart}`
	return cmsAxios.get(url)
}

// Get all records of specific collection with specific fields by filtering with specific field and value
function getItemsBy(collection, field, value, fields='*.*', sort) {
	const sortPart = sort ? `&sort=${sort}` : ''
	const url = `items/${collection}?limit=-1&fields=${fields}&filter[${field}][eq]=${value}${sortPart}`
	return cmsAxios.get(url)
}

// Get one record of specific collection with specific fields by unique ID
function getItemById(collection, id, fields='*.*') {
	const url = `items/${collection}/${id}?fields=${fields}`
	return cmsAxios.get(url)
}

// Get one record of specific collection with specific fields by filtering with multple filters
function getItemByFilters(collection, filters, fields='*.*', sort) {
	const sortPart = sort ? `&sort=${sort}` : ''
	const filterPart = filters.map(i => `&filter[${i.field}][${i.operator}]=${i.value}`).join('')
	const url = `items/${collection}?fields=${fields}&single=1${filterPart}${sortPart}`
	return cmsAxios.get(url)
}

// Get one record of specific collection with specific fields by filtering with specific field and value
function getItemBy(collection, field, value, fields='*.*', sort) {
	const sortPart = sort ? `&sort=${sort}` : ''
	const url = `items/${collection}?fields=${fields}&single=1&filter[${field}][eq]=${value}${sortPart}`
	return cmsAxios.get(url)
}

function getItemsByQuery(query) {
	const { collection, fields, filter, limit, sort } = query
	const fieldsPart = fields ? `fields=${fields}` : ''
	const filterPart = filter ? filter.map(i => `&filter[${i.field}][${i.operator}]=${i.value}`).join('') : ''
	const limitPart = limit ? `&limit=${limit}` : `&limit=-1`
	const sortPart = sort ? `&sort=${sort}` : ''
	const url = `items/${collection}?${fieldsPart}${filterPart}${limitPart}${sortPart}`
	return cmsAxios.get(url)
}

function getItemByQuery(query) {
	const { collection, fields, filter, sort } = query
	const fieldsPart = fields ? `fields=${fields}` : ''
	const filterPart = filter ? filter.map(i => `&filter[${i.field}][${i.operator}]=${i.value}`).join('') : ''
	const sortPart = sort ? `&sort=${sort}` : ''
	const url = `items/${collection}?${fieldsPart}${filterPart}${sortPart}&single=1`
	return cmsAxios.get(url)
}

function createItems(collection, payload) {
	if (_.isArray(payload) && payload.length) {
		const url = `items/${collection}`
		return cmsAxios.post(url, payload)
	}
}

function createItem(collection, payload) {
	if (_.isObject(payload) && _.keys(payload).length) {
		const url = `items/${collection}`
		return cmsAxios.post(url, payload)
	}
}

function updateItems(collection, payload) {
	if (_.isArray(payload) && payload.length) {
		const url = `items/${collection}`
		return cmsAxios.patch(url, payload)
	}
}

function updateItemById(collection, id, payload) {
	if (id && _.isObject(payload) && _.keys(payload).length) {
		const url = `items/${collection}/${id}`
		return cmsAxios.patch(url, payload)
	}
}

// Delete multiple items whose id is in ids
function deleteItems(collection, ids) {
	if (_.isArray(ids) && ids.length) {
		const url = `items/${collection}/${ids.join(',')}`
		return cmsAxios.delete(url)
	}
}

function deleteItem(collection, id) {
	if (id) {
		const url = `items/${collection}/${id}`
		return cmsAxios.delete(url)
	}
}

function setTokenExpiresIn() {
	return (parseInt(Date.now() / 1000) + CONSTANTS.CMS_TOKEN_LIFE_SECONDS).toString()
}

function noToken(cmsToken, expiresIn) {
	return !cmsToken || (cmsToken === 'undefined') || !expiresIn
}

function isTokenExpired(expiresIn) {
	return parseInt(expiresIn) - parseInt(Date.now() / 1000) < 15
}

function needRefreshToken(expiresIn) {
	const now = parseInt(Date.now() / 1000)
	return parseInt(expiresIn) > now && parseInt(expiresIn) - now < 60
}

export {
	getItems,
	getItemsByFilters,
	getItemsBy,
	getItemById,
	getItemByFilters,
	getItemBy,
	getItemsByQuery,
	getItemByQuery,
	createItems,
	createItem,
	updateItems,
	updateItemById,
	deleteItems,
	deleteItem,
}
