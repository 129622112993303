import io from 'socket.io-client'
import { SOCKET_URL, SOCKET_MESSAGE } from 'app/common/constants'

import { sendMessage } from './las'

const socket = io(SOCKET_URL)

const send = (message, data) => {
	return sendMessage(message, data)
}

export {
	socket,
	send,
}
