// operations using redux-saga
import { all, call, put, take, takeLatest, takeLeading } from 'redux-saga/effects';
import { SocketService, CMSService, CalculateService } from 'app/common/services'
import { SOCKET_MESSAGE } from 'app/common/constants'

import types from './types'
import actions from "./actions"

function* getGameAbout(action) {
	try {
		const { gameId, player } = action.value
		const collection = 'games'
		const fields = '*'
		const res_game = yield call(CMSService.getItemById, collection, gameId, fields)
		const game = res_game.data

		let currentRoundPlayers
		if (game.current_round > 1) {
			const res_players = yield call(CMSService.getItemsByQuery, {
				collection: 'game_actions',
				fields: 'player',
				filter: [
					{ field: 'game', operator: 'eq', value: gameId },
					{ field: 'round', operator: 'eq', value: game.current_round - 1 },
					{ field: 'wealth', operator: 'gt', value: 0 }
				]
			})

			currentRoundPlayers = res_players.data.map(i => i.player)
		} else {
			currentRoundPlayers = CalculateService.getPlayersOfGame(game)
		}

		const [res_protected_groups, res_bias_metrics ] = yield all([
			call(CMSService.getItems, 'protected_groups', '*'),
			call(CMSService.getItems, 'bias_metrics', '*')
		])
		const allOptions = {
			protectedGroups: res_protected_groups.data,
			biasMetrics: res_bias_metrics.data,
		}

		yield put(actions.getGameAboutSuccess({ game, currentRoundPlayers, allOptions }))
	} catch (e) {
		yield put(actions.getGameAboutFailure(e))
	}
}

function* getGameAboutSaga() {
	yield takeLatest(types.GET_GAME_ABOUT_REQUEST, getGameAbout)
}

function* updateGameSettings(action) {
	try {
		const { gameId, payload } = action.value
		const collection = 'games'
		const { data } = yield call(CMSService.updateItemById, collection, gameId, payload)

		yield put(actions.updateGameSettingsSuccess(data))
	} catch (e) {
		yield put(actions.updateGameSettingsFailure(e))
	}
}

function* updateGameSettingsSaga() {
	yield takeLatest(types.UPDATE_GAME_SETTINGS_REQUEST, updateGameSettings)
}

function* launchGame(action) {
	try {
		const { game, player } = action.value
		const round = game.current_round

		const res_joined = yield call(CMSService.getItemsByQuery, {
			collection: 'game_actions',
			fields: 'player,is_submitted',
			filter: [
				{ field: 'game', operator: 'eq', value: game.id },
				{ field: 'round', operator: 'eq', value: round }
			],
		})
		const joinedPlayers = res_joined.data.map(i => i.player)
		const nonSubmittedCount = res_joined.data.filter(i => !i.is_submitted).length
		if (joinedPlayers.length && nonSubmittedCount === 0) {
			yield put(actions.launchGameSuccess({ isCurrentRoundFinished: true }))
			return
		}

		if (!joinedPlayers.includes(player)) {
			const payload = {
				game: game.id,
				player,
				round,
			}
			yield call(CMSService.createItem, 'game_actions', payload)
			joinedPlayers.push(player)

			// Set is_${player}_joined to true to mark it as joined
			yield call(CMSService.updateItemById, 'games', game.id, { [`is_${player}_joined`]: 1 })
		}

		yield call(SocketService.send, SOCKET_MESSAGE.PLAYER_HAS_JOINED, { game: game.id, player, round: round })

		yield put(actions.launchGameSuccess({ joinedPlayers }))
	} catch (e) {
		yield put(actions.launchGameFailure(e))
	}
}

function* launchGameSaga() {
	yield takeLatest(types.LAUNCH_GAME_REQUEST, launchGame)
}

export default {
	getGameAboutSaga,
	updateGameSettingsSaga,
	launchGameSaga,
}