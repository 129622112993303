import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import homeReducer from 'app/screens/home/duck'
import aboutReducer from 'app/screens/about/duck'
import playReducer from 'app/screens/play/duck'
import resultReducer from 'app/screens/result/duck'
import finishReducer from 'app/screens/finish/duck'
// import landingReducer from 'app/screens/landing/duck'
// import gameReducer from 'app/screens/game/duck'
// import regulationReducer from 'app/screens/regulation/duck'
// import submittedReducer from 'app/screens/submitted/duck'
// import resultsReducer from 'app/screens/results/duck'
// import summaryReducer from 'app/screens/summary/duck'
// import createGameReducer from 'app/screens/create_game/duck'
// import lobbyReducer from 'app/screens/lobby/duck'
// import playerSelectReducer from 'app/screens/player_select/duck'

// const appReducer = combineReducers({
// 	home: homeReducer,
// 	about: aboutReducer,
// 	play: playReducer,
// 	result: resultReducer,
// 	finish: finishReducer,
// 	// landing: landingReducer,
// 	// game: gameReducer,
// 	// regulation: regulationReducer,
// 	// submitted: submittedReducer,
// 	// results: resultsReducer,
// 	// summary: summaryReducer,
// 	// createGame: createGameReducer,
// 	// lobby: lobbyReducer,
// 	// playerSelect: playerSelectReducer,
// })

// const rootReducer = (state, action) => {
// 	console.log(action.type)
// 	if (action.type === LOCATION_CHANGE) {
//     state = undefined
//   }
// 	return appReducer(state, action)
// }

// export default rootReducer

const createAppReducer = (history) => combineReducers({
  router: connectRouter(history),
  home: homeReducer,
	about: aboutReducer,
	play: playReducer,
	result: resultReducer,
	finish: finishReducer,
});

const createRootReducer = (history) => (state, action) => {
  return createAppReducer(history)(state, action);
};

export default createRootReducer
