import _ from 'lodash'

function capitalize(str) {
	if (!str) return str
	return str.length > 1 ? str.slice(0,1).toUpperCase() + str.slice(1).toLowerCase() : str.toUpperCase();
}

function lodash2space(str) {
	return str.split('_').map(i => capitalize(i)).join(' ')
}

function boolToInt(bool) {
	return bool ? 1 : 0
}

function str2nb(str) {
	return str.match(/(\d+)/)
}

function number2Percent(num, precision=0) {
	return (num * 100).toFixed(precision) + '%'
}

function formatNumber(num) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function formatCurrency(num, currency='$') {
	return `${num >= 0 ? '' : '-'}` + currency + Math.abs(num).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberWithCommas(nb) {
	return nb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function currencyWithCommas(nb, currency) {
	return currency + (nb ? nb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0');
}

function dollarWithCommas(nb) {
	return currencyWithCommas(nb, '$')
}

function numberToThousand(nb) {
	return currencyWithCommas(Math.round(nb/1000), '$') + 'k'
}

function numberToPercent(nb, precision) {
	return _.round(nb * 100, precision) + '%'
}

function percentToNumber(str) {
	return parseFloat(str.split('%')[0]) / 100
}

function descriptionByPlayer(desc, playerInfo) {
	return desc.content[playerInfo.role].replace(/{PLAYER_NAME}/g, playerInfo.name)
}

function joinByAnd(a) {
	const arr = Array.isArray(a) ? a : []
	return arr.length > 1 ? `${arr.slice(0, -1).join(', ')} and ${arr.slice(-1)[0]}`	: (arr.length > 0 ? `${arr[0]}` : '')
}

function toAcronym(str) {
	return str.split(' ').map(i => i.substring(0, 1).toUpperCase()).join('')
}

function arrayOf(length, val) {
	return (new Array(length)).fill(val)
}

function range(start, stop, step=1) {
	return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step))
}

export {
	capitalize,
	lodash2space,
	boolToInt,
	str2nb,
	number2Percent,
	formatNumber,
	formatCurrency,
	numberWithCommas,
	currencyWithCommas,
	dollarWithCommas,
	numberToThousand,
	numberToPercent,
	percentToNumber,
	descriptionByPlayer,
	joinByAnd,
	toAcronym,
	arrayOf,
	range,
}