// operations using redux-saga
import { all, call, put, take, takeLatest, takeLeading } from 'redux-saga/effects';
import { SocketService, CMSService, CalculateService } from 'app/common/services'
import { SOCKET_MESSAGE, GAME_STATUS, PLAYER_ROLE, PLAYERS, LENDERS, ACTIONS, TABLE_FIELDS, MOMENT_FORMAT, CONSUMER_SHRINK_RATIO, COMMA } from 'app/common/constants'
import types from './types'
import actions from "./actions"
import _ from 'lodash'
import moment from 'moment'

function* getGamePlayInfo(action) {
	try {
		const { gameId, player } = action.value
		const isRegulator = CalculateService.isRegulator(player)
		const roleId = isRegulator ? 1 : 2

		const res_game = yield call(CMSService.getItemById, 'games', gameId, '*')
		const game = res_game.data

		// Check game status
		if (game.status === GAME_STATUS.COMPLETED) {
			yield put(actions.getGamePlayInfoFailure({
				isGameCompleted: true
			}))
			return
		}

		let currentRoundPlayers = []
		const players = CalculateService.getPlayersOfGame(game)
		const lenders = CalculateService.getLendersOfGame(game)

		// Check bankrupt
		if (game.current_round > 1) {
			const res_bankrupts = yield call(CMSService.getItemsByQuery, {
				collection: 'game_actions',
				fields: 'player',
				filter: [
					{ field: 'game', operator: 'eq', value: gameId }, 
					{ field: 'player', operator: 'neq', value: PLAYER_ROLE.regulator }, 
					{ field: 'round', operator: 'lt', value: game.current_round },
					{ field: 'wealth', operator: 'lte', value: 0 }
				]
			})
			const bankrupts = _.chain(res_bankrupts.data).map(i => i.player).uniq().value()
			currentRoundPlayers = _.difference(players, bankrupts)

			// Check current player is bankrupt
			if (bankrupts.includes(player)) {
				yield put(actions.getGamePlayInfoFailure({
					isBankrupt: true
				}))
				return
			}

			// Check if all Financial Lenders are bankrupt
			if (lenders.length === bankrupts.length) {
				yield put(actions.getGamePlayInfoFailure({
					isAllBankrupt: true
				}))
				return
			}
		} else {
			currentRoundPlayers = [...players]
		}

		// Fetch submitted players of current round
		const res_submitted_actions = yield call(CMSService.getItemsByQuery, {
			collection: 'game_actions',
			fields: '*',
			filter: [
				{ field: 'game', operator: 'eq', value: gameId },
				{ field: 'round', operator: 'eq', value: game.current_round },
				{ field: 'is_submitted', operator: 'eq', value: 1 }
			]
		})
		const submittedActions = res_submitted_actions.data.map(i => ({ player: i.player, actions: i.actions }))
		const submittedPlayers = res_submitted_actions.data.map(i => i.player)

		// Fetch player's actions
		const res_player_actions = yield call(CMSService.getItemsByQuery, {
			collection: 'game_actions',
			fields: '*',
			sort: 'round',
			filter: [
				{ field: 'game', operator: 'eq', value: gameId },
				{ field: 'player', operator: 'eq', value: player }
			]
		})
		const playerActions = res_player_actions.data
		const previousRoundActions = playerActions.find(i => i.round + 1 === game.current_round)
		let currentRoundActions = playerActions.find(i => i.round === game.current_round)

		if (!currentRoundActions) {
			if (game.current_round > 1) {
				yield put(actions.getGamePlayInfoFailure({
					hasNotJoined: true
				}))
			} else {
				yield put(actions.getGamePlayInfoFailure({
					hasNotLaunched: true
				}))
			}

			return
		}

		currentRoundActions = {
			...currentRoundActions,
			model: (game.current_round > 1 ? previousRoundActions.model : 3),
			profit: 0,
			wealth: (game.current_round > 1 ? previousRoundActions.wealth : game.starting_budget),
			consumer_welfare: (game.current_round > 1 ? previousRoundActions.consumer_welfare : 0),
			protected_groups: [],
			bias_metric: null,
			legal_threshold: 0,
			adjusted_threholds: {},
			adjusted_threholds_temp: {},
			actions: [],
		}

		const dataScientist = isRegulator ? {} : {
			protected_groups: [],
			bias_metric: null,
			adjusted_threholds: {},
		}

		// Fetch all options 

		const [res_thresholds, res_group_categories, res_bias_metrics, res_actions] = yield all([
			call(CMSService.getItems, 'thresholds', '*'),
			call(CMSService.getItemsByQuery, {
				collection: 'group_categories',
				fields: '*.*',
				filter: [
					{ field: 'group', operator: 'in', value: game.round_settings[game.current_round].protected_groups.join(COMMA) }
				]
			}),
			call(CMSService.getItemsByQuery, {
				collection: 'bias_metrics',
				filter: [
					{ field: 'id', operator: 'in', value: game.round_settings[game.current_round].bias_metrics.join(COMMA) }
				]
			}),
			call(CMSService.getItems, 'actions')
		])

		const allOptions = {
			thresholds: _.chain(res_thresholds.data)
										.groupBy('model')
										.mapValues(i => _.chain(i)
																			.groupBy('bias_metric')
																			.mapValues(j => _.chain(j).keyBy('group_category').mapValues('threshold').value())
																			.value())
										.value(),
			groupCategories: _.chain(res_group_categories.data)
											  .groupBy('group.id')
											  .mapValues(i => ({
											    categories: i,
											    id: i[0].group.id,
											    name: i[0].group.name
											  }))
											  .sortBy('id')
											  .value(),
			biasMetrics: res_bias_metrics.data,
			actions: res_actions.data,
		}

		// Get all consumer data if player is Financial Lender
		if (!isRegulator) {
			const res_consumer_data = yield call(CMSService.getItems, 'consumer_data', TABLE_FIELDS.consumer_data)
			allOptions.consumerData = res_consumer_data.data
			const nb_of_news_alerts = Math.min(playerActions.filter(i => i.round < game.current_round && i.news_alert).length, game.max_nb_of_news_alerts)
			allOptions.realConsumers = allOptions.consumerData
																.slice(0, parseInt(allOptions.consumerData.length * Math.pow(CONSUMER_SHRINK_RATIO, nb_of_news_alerts)))
																.map(i => i.id)
		}

		yield put(actions.getGamePlayInfoSuccess({
			game,
			isPlayerSubmitted: currentRoundActions.is_submitted,
			submittedActions,
			submittedPlayers,
			currentRoundPlayers,
			currentRoundActions,
			dataScientist,
			allOptions,
		}))
	} catch (e) {
		yield put(actions.getGamePlayInfoFailure(e))
	}
}

function* getGamePlayInfoSaga() {
	yield takeLatest(types.GET_GAME_PLAY_INFO_REQUEST, getGamePlayInfo)
}

function* submitRound(action) {
	const { game, player, currentRoundPlayers, currentRoundActions } = action.value
	const submitId = currentRoundActions.id

	try {
		const gameId = game.id
		const round = currentRoundActions.round

		// Submit actions
		const correctedCurrentRoundActions = {...currentRoundActions, adjusted_threholds_temp: undefined, is_submitted: 1}
		if (currentRoundActions.protected_groups.length && !currentRoundActions.bias_metric) {
			correctedCurrentRoundActions.protected_groups = []
		}
		yield call(CMSService.updateItemById, 'game_actions', submitId, correctedCurrentRoundActions)

		// Check if all players have submitted, if not, just return without calculating results
		const res_submitted_actions = yield call(CMSService.getItemsByQuery, {
			collection: 'game_actions',
			filter: [
				{ field: 'game', operator: 'eq', value: gameId },
				{ field: 'round', operator: 'eq', value: round },
				{ field: 'is_submitted', operator: 'eq', value: 1 }
			]
		})
		const isAllSubmitted = res_submitted_actions.data.length === currentRoundPlayers.length
		if (!isAllSubmitted) {
			yield call(SocketService.send, SOCKET_MESSAGE.PLAYER_HAS_SUBMITTED, { game: gameId, player, round: round, actions: currentRoundActions.actions })
			yield put(actions.submitRoundSuccess({ player, actions: currentRoundActions.actions }))
			return
		}

		// Calculate results if all players have submitted actions
		const currentActions = _.keyBy(res_submitted_actions.data, 'player')
		const [res_thresholds, res_protected_groups, res_group_categories, res_bias_metrics, res_actions, res_consumer_data ] = yield all([
			call(CMSService.getItems, 'thresholds', '*'),
			call(CMSService.getItems, 'protected_groups', '*'),
			call(CMSService.getItems, 'group_categories', '*'),
			call(CMSService.getItems, 'bias_metrics', '*'),
			call(CMSService.getItems, 'actions', '*'),
			call(CMSService.getItems, 'consumer_data', TABLE_FIELDS.consumer_data)
		])
		const allOptions = {
			thresholds: _.chain(res_thresholds.data)
										.groupBy('model')
										.mapValues(i => _.chain(i)
																			.groupBy('bias_metric')
																			.mapValues(j => _.chain(j).keyBy('group_category').mapValues('threshold').value())
																			.value())
										.value(),
			protectedGroups: _.keyBy(res_protected_groups.data, 'id'),
			groupCategories: _.keyBy(res_group_categories.data, 'id'),
			biasMetrics: _.keyBy(res_bias_metrics.data, 'id'),
			actions: _.keyBy(res_actions.data, 'id'),
			consumerData: res_consumer_data.data
		}

		const resultsPayload = []  // Results Payload to be written into DB
		let gameUpdatePayload // Game Update Payload to be written into DB

		// Calculate Regulator
		const regulatorActions = currentActions.regulator
		const roPGs = regulatorActions.protected_groups.map(i => (allOptions.protectedGroups[i]))
		const roBM = allOptions.biasMetrics[regulatorActions.bias_metric]

		const regulatorResult = {
			revenue: 0,
			costs: _.reduce(regulatorActions.actions, (sum, i) => sum + allOptions.actions[i.id].cost, 0),
			consumer_welfare: 0,
			starting_wealth: game.starting_budget,
			penalty_against: [],
		}
		if (round > 1) {
			const res_previous_regulator_result = yield call(CMSService.getItemByQuery, {
				collection: 'game_actions',
				fields: 'wealth',
				filter: [
					{ field: 'game', operator: 'eq', value: gameId },
					{ field: 'player', operator: 'eq', value: PLAYER_ROLE.regulator },
					{ field: 'round', operator: 'eq', value: round - 1 }
				],
			})
			regulatorResult.starting_wealth = res_previous_regulator_result.data.wealth
		}

		const consumer_welfares = []
		const penaltyReviewedLenders = _.chain(regulatorActions.actions)
																		.filter(i => allOptions.actions[i.id].name === ACTIONS.reviewPenalty.name)
																		.map(i => i.player)
																		.value()

		// Calculate Financial Lenders
		const lenders = currentRoundPlayers.filter(i => i != PLAYER_ROLE.regulator)
		let nb_of_lobby_actions = 0

		for (const lender of lenders) {
			const lenderActions = currentActions[lender]
			if (lenderActions.actions.find(i => allOptions.actions[i.id].name === ACTIONS.lobby.name)) {
				nb_of_lobby_actions += 1
			}
			let previousActions = []
			if (round > 1) {
				const res_previous_actions = yield call(CMSService.getItemsByQuery, {
					collection: 'game_actions',
					fields: 'round,wealth,news_alert',
					filter: [
						{ field: 'game', operator: 'eq', value: gameId },
						{ field: 'player', operator: 'eq', value: lender },
						{ field: 'round', operator: 'lt', value: round }
					],
					sort: '-round'
				})
				previousActions = res_previous_actions.data
			}

			let revenue = 0,
					costs = _.reduce(lenderActions.actions, 
													(sum, i) => sum + allOptions.actions[i.id].cost, 
													lenderActions.use_data_scientist ? ACTIONS.dataScientist.cost : 0),
					consumer_welfare = 0,
					nb_of_consumers_got_loan = 0,
					defaulted_loan = 0,
					paid_back_loan = 0,
					aggregate_bias = null,
					penalty_against = [],
					news_alert = false
			const starting_wealth = (round > 1 ? previousActions[0].wealth : game.starting_budget)

			const nb_of_news_alerts = Math.min(previousActions.filter(i => i.news_alert).length, game.max_nb_of_news_alerts)
			const realConsumers = allOptions.consumerData
														.slice(0, parseInt(allOptions.consumerData.length * Math.pow(CONSUMER_SHRINK_RATIO, nb_of_news_alerts)))
														.map(i => i.id)
			
			const oPG = allOptions.protectedGroups[lenderActions.protected_groups[0]]
			const oBM = allOptions.biasMetrics[lenderActions.bias_metric]
			const model = lenderActions.model

			const thresholds = oPG && oBM
													? { ...allOptions.thresholds[model][oBM.id], ...lenderActions.adjusted_threholds }
													: game.default_baseline_modal_thresholds

			const consumerData = _.map(allOptions.consumerData, consumer => {
				const got_loan = realConsumers.includes(consumer.id) && CalculateService.gotLoan(consumer, model, oPG, thresholds)
				if (got_loan) {
					nb_of_consumers_got_loan += 1
					if (consumer.paid_back) {
						paid_back_loan += consumer.credit_amount
					} else {
						defaulted_loan += consumer.credit_amount
					}
				}
				return {
					got_loan,
					paid_back: consumer.paid_back,
					sex: consumer.sex,
					age: consumer.age,
					employment: consumer.employment,
					housing: consumer.housing,
					citizenship_status: consumer.citizenship_status,
					marital_status: consumer.marital_status,
				}
			})

			costs += Math.round(defaulted_loan)
			revenue += Math.round(paid_back_loan * game.interest_rate_index)
			consumer_welfare = CalculateService.getCW(consumerData)
			consumer_welfares.push(consumer_welfare)

			if (roPGs.length && roBM) {
				aggregate_bias = 0
				for (const roPG of roPGs) {
					const bm_result = CalculateService.getBM(consumerData, roPG, roBM)
					aggregate_bias += bm_result.aggregate_bias

					_.forIn(bm_result.bmr_grouped, (val, cat) => {
						const bias = Math.abs(bm_result.bmr - val)
						if (penaltyReviewedLenders.includes(lender) && bias > regulatorActions.legal_threshold) {
							penalty_against.push(cat)
						}
						if (bias > game.news_alert_threshold && !news_alert) {
							news_alert = true
						}
					})
				}
				if (penaltyReviewedLenders.includes(lender) && penalty_against.length) {
					costs += game.penalty
					regulatorResult.revenue += game.penalty
					regulatorResult.penalty_against.push({
						player: lender,
						penalty_against,
					})
				}
			}

			// Results to write
			resultsPayload.push({
				id: lenderActions.id,
				player: lenderActions.player,
				nb_of_consumers_got_loan,
				defaulted_loan,
				paid_back_loan,
				wealth: starting_wealth + revenue - costs,
				profit: revenue - costs,
				consumer_welfare,
				aggregate_bias,
				penalty_against,
				news_alert,
				is_result_out: 1,
			})
		}	

		if (consumer_welfares.length) {
			regulatorResult.consumer_welfare = _.round(_.reduce(consumer_welfares, (sum, n) => sum + n, 0) / consumer_welfares.length, 2)
		}

		if (nb_of_lobby_actions) {
			const lobbyCost = _.find(allOptions.actions, (val) => val.name === ACTIONS.lobby.name).cost
			regulatorResult.revenue += nb_of_lobby_actions * lobbyCost
		}

		resultsPayload.push({
			id: regulatorActions.id,
			player: regulatorActions.player,
			wealth: regulatorResult.starting_wealth + regulatorResult.revenue - regulatorResult.costs,
			profit: regulatorResult.revenue - regulatorResult.costs,
			consumer_welfare: regulatorResult.consumer_welfare,
			penalty_against: regulatorResult.penalty_against,
			is_result_out: 1,
		})

		// Payload to update current game
		const isAllBankrupt = resultsPayload.filter(i => i.player != PLAYER_ROLE.regulator && i.wealth > 0).length < 1
		if (isAllBankrupt || round === game.rounds) {
			gameUpdatePayload = {
				status: GAME_STATUS.COMPLETED,
				completed_at: moment().format(MOMENT_FORMAT.DateTime.standard),
			}
		}

		const [ res_results, res_updated_game ] = yield all([
			call(CMSService.updateItems, 'game_actions', resultsPayload),
			call(CMSService.updateItemById, 'games', gameId, gameUpdatePayload),
		])

		yield call(SocketService.send, SOCKET_MESSAGE.PLAYER_HAS_SUBMITTED, { game: gameId, player, round: round, actions: currentRoundActions.actions })
		yield put(actions.submitRoundSuccess({ player, actions: currentRoundActions.actions }))												
	} catch (e) {
		yield call(CMSService.updateItemById, 'game_actions', submitId, { is_submitted: null })
		yield put(actions.submitRoundFailure(e))
	}
}

function* submitRoundSaga() {
	yield takeLeading(types.SUBMIT_ROUND_REQUEST, submitRound)
}

export default {
	getGamePlayInfoSaga,
	submitRoundSaga,
}