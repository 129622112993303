// operations using redux-saga
import { all, call, put, take, takeLatest, takeLeading } from 'redux-saga/effects';
import { CMSService, CalculateService } from 'app/common/services'
import { range } from 'app/common/utils'
import { GAME_STATUS, PLAYER_ROLE, PLAYERS, LENDERS, ACTIONS, TABLE_FIELDS, MOMENT_FORMAT, CONSUMER_SHRINK_RATIO, COMMA } from 'app/common/constants'
import types from './types'
import actions from "./actions"
import _ from 'lodash'
import moment from 'moment'

function* getGameSummary(action) {
	try {
		const { gameId } = action.value

		const [ res_game, res_game_actions ] = yield all([
			call(CMSService.getItemById, 'games', gameId),
			call(CMSService.getItemsByQuery, {
				collection: 'game_actions',
				filter: [
					{ field: 'game', operator: 'eq', value: gameId }
				]
			})
		])

		const game = res_game.data
		const results = _.chain(res_game_actions.data)
											.groupBy('player')
											.mapValues(o => _.orderBy(o, ['round'], ['desc']))
											.toPairs()
											.map(o => ({
												player: o[0],
												isRegulator: CalculateService.isRegulator(o[0]),
												results: o[1],
												breakdowns: { 
													..._.keyBy(o[1], 'round'), 
													final: { 
														consumer_welfare: _.round(o[1].reduce((sum, p) => sum + p.consumer_welfare, 0) / game.rounds, 2),
														wealth: o[1][0].wealth
													} 
												},
												averageCW: _.round(o[1].reduce((sum, p) => sum + p.consumer_welfare, 0) / game.rounds, 2),
												wealth: o[1][0].wealth,
												rank: Infinity,
											}))
											.orderBy(['isRegulator', 'player'], ['desc', 'asc'])
											.value()

		const isAllBankrupt = results.filter(i => !i.isRegulator && i.wealth > 0).length < 1

		const [ res_protected_groups, res_group_categories, res_bias_metrics, res_actions, res_thresholds, res_consumer_data ] = yield all([
			call(CMSService.getItemsByQuery, {
				collection: 'protected_groups',
				filter: [
					{ field: 'id', operator: 'in', value: game.round_settings[game.current_round].protected_groups.join(COMMA) }
				]
			}),
			call(CMSService.getItemsByQuery, {
				collection: 'group_categories',
				filter: [
					{ field: 'group', operator: 'in', value: game.round_settings[game.current_round].protected_groups.join(COMMA) }
				]
			}),
			call(CMSService.getItemsByQuery, {
				collection: 'bias_metrics',
				filter: [
					{ field: 'id', operator: 'in', value: game.round_settings[game.current_round].bias_metrics.join(COMMA) }
				]
			}),
			call(CMSService.getItems, 'actions'),
			call(CMSService.getItems, 'thresholds', '*'),
			call(CMSService.getItems, 'consumer_data', TABLE_FIELDS.consumer_data)
		])

		const allOptions = {
			protectedGroups: _.keyBy(res_protected_groups.data, 'id'),
			groupCategories: _.chain(res_group_categories.data)
												.groupBy('group')
												.mapValues((cats, keyGroup) => (_.keyBy(cats, 'id')))
												.value(),
			biasMetrics: _.keyBy(res_bias_metrics.data, 'id'),
			actions: _.keyBy(res_actions.data, 'id'),
			thresholds: _.chain(res_thresholds.data)
										.groupBy('model')
										.mapValues(o => _.chain(o)
																			.groupBy('bias_metric')
																			.mapValues(p => _.chain(p)
																												.keyBy('group_category')
																												.mapValues(q => q.threshold)
																												.value())
																			.value())
										.value(),
			consumerData: res_consumer_data.data,
		}

		// Calculate Top Performer
		const res_performers = yield call(CMSService.getItemsByQuery, {
			collection: 'game_actions',
			fields: 'game,round,player,consumer_welfare,wealth',
			filter: [
				{ field: 'game.rounds', operator: 'eq', value: game.rounds },
				{ field: 'game.status', operator: 'eq', value: GAME_STATUS.COMPLETED }
			]
		})

		const topPerformer = {
			final: {
				wealth: _.chain(res_performers.data)
									.filter(i => i.round === game.rounds)
									.map(i => i.wealth)
									.max()
									.value(),
				consumer_welfare: _.chain(res_performers.data)
													.groupBy('game')
													.mapValues((valGame, keyGame) => (_.chain(valGame)
																														.groupBy('player')
																														.mapValues((valPlayer, keyPlayer) => _.round(_.sumBy(valPlayer, 'consumer_welfare') / game.rounds, 2))
																														.toPairs()
																														.map(i => i[1])
																														.max()
																														.value()))
													.toPairs()
													.map(j => j[1])
													.max()
													.value()
			}
		}

		for (const round of range(1, game.rounds)) {
			const [ res_top_cw, res_top_wealth ] = yield all(
				['consumer_welfare', 'wealth'].map(key => (
					call(CMSService.getItemByQuery, {
						collection: 'game_actions',
						fields: key,
						filter: [
							{ field: 'round', operator: 'eq', value: round },
							{ field: 'is_result_out', operator: 'eq', value: 1 },
						],
						sort: `-${key}`
					})
				)
			))
			topPerformer[round] = {
				consumer_welfare: res_top_cw.data.consumer_welfare,
				wealth: res_top_wealth.data.wealth,
			}
		}

		// Calculate rank
		for (const item of results) {
			if (item.isRegulator) {
				const res_rank_1 = yield call(CMSService.getItemsByQuery, {
					collection: 'game_actions',
					fields: 'game,player,consumer_welfare,wealth',
					filter: [
						{ field: 'game', operator: 'neq', value: gameId },
						{ field: 'game.rounds', operator: 'gte', value: game.rounds },
						{ field: 'game.current_round', operator: 'gte', value: game.rounds },
						{ field: 'round', operator: 'lte', value: game.rounds },
						{ field: 'player', operator: 'eq', value: PLAYER_ROLE.regulator },
						{ field: 'is_result_out', operator: 'eq', value: 1 }
					]
				})
				const rankData = _.chain(res_rank_1.data)
													.groupBy('game')
													.mapValues((o, keyGame) => _.chain(o)
																											.groupBy('player')
																											.mapValues(o1 => _.orderBy(o1, ['round'], ['desc']))
																											.mapValues((o1, keyPlayer) => ({
																												game: keyGame,
																												player: keyPlayer,
																												shouldCompare: o1[0].round === game.rounds,
																												wealth: o1[0].wealth,
																												averageCW: _.round(o1.reduce((sum, o2) => sum + o2.consumer_welfare, 0) / game.rounds, 2)
																											}))
																											.toPairs()
																											.map(o1 => o1[1])
																											.value())
													.toPairs()
													.map(o => o[1])
													.filter(o => o.shouldCompare)
													.value()

				item.rank = _.chain(_.union(...rankData))
											.filter(o => o.averageCW > item.averageCW || o.averageCW === item.averageCW && o.wealth > item.wealth)
											.value()
											.length + 1
			} else {
				if (item.wealth > 0) {
					const res_rank = yield call(CMSService.getItemsByQuery, {
						collection: 'game_actions',
						fields: 'wealth',
						filter: [
							{ field: 'round', operator: 'eq', value: game.rounds },
							{ field: 'player', operator: 'neq', value: PLAYER_ROLE.regulator },
							{ field: 'wealth', operator: 'gt', value: item.wealth },
							{ field: 'is_result_out', operator: 'eq', value: 1 }
						]
					})
					item.rank = res_rank.data.length + 1
				}
			}
		}

		// Calculate Bias Summary
		const resultsByRound = _.chain(res_game_actions.data)
														.groupBy('round')
														.mapValues(o => _.keyBy(o, 'player'))
														.value()
		const biasSummary = {}
		const lendersCount = CalculateService.getLendersOfGame(game).length

		const biasSummaryOfAllRounds = []
		_.forEach(resultsByRound, (roundResults, round) => {
			const biasSummaryOfRound = []
			const gotLoanData = allOptions.consumerData.map(i => { return {...i} })
			_.forEach(roundResults, (playerResult, player) => {
				if (CalculateService.isRegulator(player)) return
				const bm = playerResult.bias_metric
				const pg = playerResult.protected_groups[0]
				const oPG = allOptions.protectedGroups[pg]
				const model = playerResult.model
				const adjusted_threholds = _.isArray(playerResult.adjusted_threholds) ? playerResult.adjusted_threholds : []
				const thresholds = bm && pg ? {...allOptions.thresholds[model][bm], ...adjusted_threholds} 
																		: game.default_baseline_modal_thresholds

				const nb_of_news_alerts = Math.min(res_game_actions.data.filter(i => i.player === player && i.round < round && i.news_alert).length, game.max_nb_of_news_alerts)
				const realConsumers = allOptions.consumerData
															.slice(0, parseInt(allOptions.consumerData.length * Math.pow(CONSUMER_SHRINK_RATIO, nb_of_news_alerts)))
															.map(i => i.id)

				const consumerData = allOptions.consumerData.map(consumer => ({
					got_loan: (realConsumers.includes(consumer.id) && CalculateService.gotLoan(consumer, model, oPG, thresholds)),
					paid_back: consumer.paid_back,
					sex: consumer.sex,
					age: consumer.age,
					employment: consumer.employment,
					housing: consumer.housing,
					citizenship_status: consumer.citizenship_status,
					marital_status: consumer.marital_status,
				}))
				const biasSummaryOfPlayer = _.mapValues(allOptions.biasMetrics, (oBM, bm) => {
					return _.mapValues(allOptions.protectedGroups, (oPG, pg) => CalculateService.getBM(consumerData, oPG, oBM))
				})
				biasSummaryOfRound.push(biasSummaryOfPlayer)
				biasSummaryOfAllRounds.push(biasSummaryOfPlayer)
			})
			biasSummary[round] = _.mapValues(allOptions.biasMetrics, (oBM, bm) => {
				return _.mapValues(allOptions.protectedGroups, (oPG, pg) => ({
					aggregate_bias: _.round(_.chain(biasSummaryOfRound).map(i => i[bm][pg].aggregate_bias).sum().value() / biasSummaryOfRound.length, 2),
					bmr: _.round(_.chain(biasSummaryOfRound).map(i => i[bm][pg].bmr).sum().value() / biasSummaryOfRound.length, 2),
					bmr_grouped: _.mapValues(allOptions.groupCategories[pg], (oGC, gc) => _.round(_.chain(biasSummaryOfRound).map(i => i[bm][pg].bmr_grouped[gc]).sum().value() / biasSummaryOfRound.length, 2))
				}))
			})
		})

		biasSummary.final = _.mapValues(allOptions.biasMetrics, (oBM, bm) => {
			return _.mapValues(allOptions.protectedGroups, (oPG, pg) => ({
				aggregate_bias: _.round(_.chain(biasSummaryOfAllRounds).map(i => i[bm][pg].aggregate_bias).sum().value() / biasSummaryOfAllRounds.length, 2),
				bmr: _.round(_.chain(biasSummaryOfAllRounds).map(i => i[bm][pg].bmr).sum().value() / biasSummaryOfAllRounds.length, 2),
				bmr_grouped: _.mapValues(allOptions.groupCategories[pg], (oGC, gc) => _.round(_.chain(biasSummaryOfAllRounds).map(i => i[bm][pg].bmr_grouped[gc]).sum().value() / biasSummaryOfAllRounds.length, 2))
			}))
		})

		const summary = {
			game,
			allOptions,
			players: _.filter(PLAYERS, i => game[i]),
			results,
			biasSummary,
			winner: (isAllBankrupt ? null : _.chain(results).filter(i => i.rank === _.minBy(results, 'rank').rank).map(i => i.player).value()),
			topPerformer,
		}
		yield put(actions.getGameSummarySuccess(summary))
	} catch (e) {
		yield put(actions.getGameSummaryFailure(e))
	}
}

function* getGameSummarySaga() {
	yield takeLatest(types.FINISH_GET_GAME_SUMMARY_REQUEST, getGameSummary)
}

export default {
  getGameSummarySaga
}
