// reducers
import { LOCATION_CHANGE } from 'connected-react-router'
import types from './types'

const INITIAL_STATE = {
	isLoading: false,
	error: null,
  confirm: null,
	activeGames: [],
  completedGames: [],
  responseError: '',
  playerDuplicatedValue: [],
  isPlayerDuplicatedMsg: 'Player Names are not unique, please make sure the player names are unique.',
  isGameCreated: false,
  chosenGame: {},
  chosenPlayer: null,
  settings : {
    game_name: {
      name: 'game_name',
      title: 'Game Name',
      value: 'RAI Game',
    },
    game_id: {
      dateTime: '',
      gameKey: '',
    },
    regulator: {
      name: 'regulator',
      title: 'REGULATOR',
      value: 'The Regulator'
    },
    financialLenders: {
      name: 'financialLenders',
      titlePrefix: 'FINANCIAL LENDER ',
      value: ['Financial Lender 1'],
      toggle: '-1',
    },
    team_section: {
      title: 'Enter team names for each player: '
    },
    defaults: {
      title: 'DEFAULTS',
    }
  }
}

const homeReducer = (state=INITIAL_STATE, {type, value}) => {
  let financialLenders = state.settings.financialLenders.value.slice()
  const oldLength = financialLenders.length
  switch(type) {
    case LOCATION_CHANGE:
      return INITIAL_STATE
  	case types.GET_ACTIVE_GAMES_REQUEST:
  		return {
  			...state,
  			isLoading: true,
  		}
  	case types.GET_ACTIVE_GAMES_SUCCESS:
  		return {
  			...state,
  			isLoading: false,
  			activeGames: value,
  		}
  	case types.GET_ACTIVE_GAMES_FAILURE:
  		return {
  			...state,
  			isLoading: false,
  		}
    case types.GET_COMPLETED_GAMES_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_COMPLETED_GAMES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        completedGames: value,
      }
    case types.GET_COMPLETED_GAMES_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.DELETE_GAME_REQUEST:
      return {
        ...state,
        isLoading: true,
        confirm: null,
      }
    case types.DELETE_GAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        completedGames: state.completedGames.filter(i => i.id != value),
      }
    case types.DELETE_GAME_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.CHANGE_SETTINGS:
      let newVal = value.val
      if (value.key === 'financialLenders') {
        let financialLenders = state.settings.financialLenders.value;
        let playerDuplicatedValue = [];
        financialLenders[value.index] = newVal;
        let playerNameValues = [...financialLenders];
        playerNameValues.unshift(state.settings.regulator.value);
        // get the duplicate value
        playerNameValues.concat().sort().sort(function(a,b) {
          if(a==b && playerDuplicatedValue.indexOf(a) === -1) {
            playerDuplicatedValue.push(a);
          }
        });
        return {
          ...state,
          playerDuplicatedValue: playerDuplicatedValue,
          settings: {
            ...state.settings,
            [value.key]: {
              ...(state.settings[value.key]),
              value: financialLenders
            },
          }
        }
      }
      if (value.key === 'regulator') {
        let financialLenders = state.settings.financialLenders.value;
        let playerDuplicatedValue = [];
        let playerNameValues = [...financialLenders];
        playerNameValues.unshift(newVal);
        // get the duplicate value
        playerNameValues.concat().sort().sort(function(a,b) {
          if(a==b && playerDuplicatedValue.indexOf(a) === -1) {
            playerDuplicatedValue.push(a);
          }
        });
        console.log(playerDuplicatedValue)
        return {
          ...state,
          playerDuplicatedValue: playerDuplicatedValue,
          settings: {
            ...state.settings,
            [value.key]: {
              ...(state.settings[value.key]),
              value: newVal
            },
          }
        }
      }
      if (value.key === 'game_name') {
        return {
          ...state,
          responseError: '',
          settings: {
            ...state.settings,
            [value.key]: {
              ...(state.settings[value.key]),
              value: newVal
            },
          }
        }
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          [value.key]: {
            ...(state.settings[value.key]),
            value: newVal
          },
        }
      }

    case types.ADD_LENDERS:
      if (oldLength <= 2) {
        financialLenders = financialLenders.concat([...Array(1).keys()].map(i => 'Financial Lender ' + (oldLength + 1 + i).toString()))
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          financialLenders: {
            ...(state.settings.financialLenders),
            value: financialLenders
          }
        }
      }
    case types.DELETE_LENDERS:
      if (oldLength > 1) {
        financialLenders = financialLenders.slice(0, oldLength - 1)
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          financialLenders: {
            ...(state.settings.financialLenders),
            value: financialLenders
          }
        }
      }
    case types.CREATE_GAME_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.CREATE_GAME_SUCCESS:
      return {
        ...state,
        isGameCreated: true,
        isLoading: false,
      }
    case types.CREATE_GAME_FAILURE:
      console.log(value)
      return {
        ...state,
        isLoading: false,
        isGameCreated: false,
        responseError: value.message,
      }
    case types.GET_GAME_PLAYERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        chosenPlayer: INITIAL_STATE.chosenPlayer,
      }
    case types.GET_GAME_PLAYERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chosenGame: value,
      }
    case types.GET_GAME_PLAYERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.CHOOSE_PLAYER:
      return {
        ...state,
        chosenPlayer: value,
      }
    case types.HOME_TOGGLE_CONFIRM:
      return {
        ...state,
        confirm: value,
      }
    default: return state;
  }
}

export default homeReducer;
