import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge, Button, Panel } from 'appkit-react'
import { Slider } from 'appkit-react-slider'
import { LabelledNumberInput }  from 'app/common/components'
import { number2Percent } from 'app/common/utils'
import { Colors, Typography } from 'app/styles'
import _ from 'lodash'

const AdjustThresholds = ({ visible, oPG, oBM, legalThresholdConf, defaultThresholds, adjustedThresholdsTemp, adjustedThresholds,
                            updateRoundActions, hidePopup, isModal, desc}) => {
  
  const title = 'Adjust the Thresholds for Loan Acceptance (individuals above the threshold will be accepted)'
  const subTitle = 'Default thresholds are selected to equalize the bias for your protected group:'
  const subTitleinPanel = 'See the impacts of acceptance on graphs below.'
  const pgNbm = `${oPG.name} & ${oBM.name}`

  const getThresholdConf = (cat) => {
    return {
      name: cat.id,
      title: cat.name,
      min: 0,
      max: 100,
      step: 1,
      integerOnly: true,
      value: getThresholdOfCategory(cat),
    }
  }

  const getThresholdOfCategory = (cat) => {
    if (isModal) {
      return parseInt((adjustedThresholdsTemp.hasOwnProperty(cat.id) ? adjustedThresholdsTemp[cat.id] : defaultThresholds[cat.id]) * 100)
    }
    return parseInt((adjustedThresholds.hasOwnProperty(cat.id) ? adjustedThresholds[cat.id] : defaultThresholds[cat.id]) * 100)
  }

  const onChangeInput = ({key, val}) => {
    const payload = isModal ? {
      adjusted_threholds_temp: {
        ...adjustedThresholdsTemp,
        [key]: (val / 100)
      }
    } : {
      adjusted_threholds: {
        ...adjustedThresholds,
        [key]: (val / 100)
      }
    }
    updateRoundActions(payload)
  }

  const onChangeSlider = (val, catId) => {
    const payload = isModal ? {
      adjusted_threholds_temp: {
        ...adjustedThresholdsTemp,
        [catId]: (val / 100)
      }
    } : {
      adjusted_threholds: {
        ...adjustedThresholds,
        [catId]: (val / 100)
      }
    }
    updateRoundActions(payload)
  }

  const handleCancel = () => {
    const payload = {
      adjusted_threholds_temp: {
        ...adjustedThresholds,
      }
    }
    updateRoundActions(payload)
    hidePopup('adjustThresholds')
  }

  const handleReset=() => {
    if (isModal) {
      const payload = {
        adjusted_threholds_temp: {},
        adjusted_threholds: {}
      }
      updateRoundActions(payload)
      hidePopup('adjustThresholds')
    } else {
      const payload = {
        adjusted_threholds: {}
      }
      updateRoundActions(payload)
    }
  }

  const handleApply = () => {
    const payload = {
      adjusted_threholds: {
        ...adjustedThresholdsTemp,
      }
    }
    updateRoundActions(payload)
    hidePopup('adjustThresholds')
  }

  if(isModal) {
    return (
      <Modal
        className="adjust-thresholds-modal"
        visible={visible}
        backdropClosable={false}
        onCancel={handleCancel}
      >
        <ModalBody>
          <div className="main-area">
            <div className='head-zone'>
              <span className="title">{title}</span>
              <span className="sub-title">{subTitle}</span>
              <span className='pgNbm'>{pgNbm}</span>
            </div>
  
            <div className='recommendation-zone'>
              <span>Data scientist recommendation:</span>
              <div className='categories-row'>
                {oPG.categories.map((cat, index) => (
                  <span key={index}>{cat.name} : {number2Percent(defaultThresholds[cat.id])}</span>
                ))}
              </div>
            </div>
            
            <div className='adjust-zone'>
              {oPG.categories.map((cat, index) => (
                <div key={index}>
                  <LabelledNumberInput obj={getThresholdConf(cat)} changeSettings={onChangeInput} />
                  <Slider
                    min={legalThresholdConf.min}
                    max={legalThresholdConf.max}
                    value={getThresholdOfCategory(cat)}
                    onChange={(val) => onChangeSlider(val, cat.id)} />
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
  
        <ModalFooter>
          <div className="button-comp">
            <Button kind='secondary' onClick={handleCancel}> CANCEL </Button>
            <Button onClick={handleApply}> SAVE </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  } else {
    return(
      <Panel className='adjust-thresholds-panel'>
        <div className="main-area">
          <div className='head-zone'>
            <div>
              <span>{title}</span>
              <span>{subTitleinPanel}</span>
            </div>
            <Button onClick={handleReset}>RESET</Button>
          </div>

          <div className='recommendation-zone'>
            <span>Data scientist recommendation:</span>
            <div className='categories-row'>
              {oPG.categories.map((cat, index) => (
                <span key={index}>{cat.name} : {number2Percent(defaultThresholds[cat.id])}</span>
              ))}
            </div>
          </div>
          
          <div className='adjust-zone'>
            {oPG.categories.map((cat, index) => (
              <div key={index}>
                <LabelledNumberInput obj={getThresholdConf(cat)} changeSettings={onChangeInput} />
                <Slider
                  min={legalThresholdConf.min}
                  max={legalThresholdConf.max}
                  value={getThresholdOfCategory(cat)}
                  onChange={(val) => onChangeSlider(val, cat.id)} />
              </div>
            ))}
          </div>
        </div>
      </Panel>
    )
  }
};

export default AdjustThresholds
