import React, { useEffect } from 'react';
import { ButtonGroup, ButtonGroupItem } from 'appkit-react';
import { range, capitalize } from 'app/common/utils'
import { CalculateService } from 'app/common/services'
import { MOMENT_FORMAT, COLON } from 'app/common/constants'
import { Images } from 'app/assets'
import numWords from 'num-words'

const Headmast = ({ role, results, game, currentTab, switchTab }) => {	
	const onChange = (e, tab) => {
		switchTab(tab.toString())
	}

  return (
    <div className='headmast-wrapper'>
    	<div className='headmast'>
    		<div>
		      <span>Game Finished</span>
		      <span>{ game.name }</span>
		      <div>
			      <span>Your Role: </span>
			      <span>{ role.title }</span>
		      </div>
		    </div>

		    <div>
		    	<ButtonGroup
		    		className='button-group'
						value={currentTab}
						onChange={onChange}
					>
					  <ButtonGroupItem data-value='final'>FINAL</ButtonGroupItem>
						{range(1, game.rounds).map((round, index) => (
							<ButtonGroupItem key={index} disabled={game.current_round < round} data-value={round.toString()}>R{round}</ButtonGroupItem>
						))}
					</ButtonGroup>
		    </div>
    	</div>
    </div>
  )
};

export default Headmast;
