// reducers
import types from './types'
import _ from 'lodash'

const INITIAL_STATE = {
	isLoading: false,
  isLoaded: false,
  isLanding: false,
  currentTab: 'final',
  biasSummarySettings: {
    biasMetric: 1,
    protectedGroup: 1,
  },
  game: {},
  winner: '',
  allOptions: {
    protectedGroups: {},
    groupCategories: {},
    biasMetrics: {},
    actions: {},
  },
  biasSummaryState: {
    round: 1,
    biasMetric: 1,
    protectedGroup: 1,
  },
  biasSummary: {
    final: {
      1: {
        1: {
          aggregate_bias: 0,
          bmr: 0,
          bmr_grouped: {},
        }
      }
    }
  },
  topPerformer: {
    final: {
      consumer_welfare: 0,
      wealth: 0,
    }
  },
  players: [],
  results: [],
  currentRoundResults: {
    profit: 0, 
    wealth: 0, 
    consumer_welfare: 0,
    round: 1
  },
  currentChartData: {
    id: 1,
    data: [],
    average: 0
  }
}

const finishReducer = (state=INITIAL_STATE, {type, value}) => {
  const tempData = JSON.parse(window.sessionStorage.getItem('temp'));
  const tempChartData = {
    id: 1,
    data: [],
    average: 0
  }
  switch(type) {
    case types.FINISH_GET_GAME_SUMMARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.FINISH_GET_GAME_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        ...value,
        biasSummarySettings: {
          biasMetric: value.game.round_settings[1].bias_metrics[0],
          protectedGroup: value.game.round_settings[1].protected_groups[0],
        },
        biasSummaryState: {
          round: 1,
          biasMetric: value.game.round_settings[1].bias_metrics[0],
          protectedGroup: value.game.round_settings[1].protected_groups[0],
        }
      }
    case types.FINISH_GET_GAME_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      }
    case types.SUMMARY_CHANGE_BIAS_SUMMARY_STATE:
      return {
        ...state,
        biasSummaryState: {
          ...state.biasSummaryState,
          ...value,
        }
      }
    case types.UPDATE_CURRENT_ROUND_RESULTS:
      console.log(value)
      return {
        ...state,
        currentRoundResults: {
          ...state.currentRoundResults,
          ...value
        }
      }
    case types.UPDATE_CURRENT_CHART_DATA:
      tempChartData.average = value.data.bmr
      tempChartData.id = value.id
      for(var item in value.data.bmr_grouped) {
        tempChartData.data.push({
          id: item,
          value: value.data.bmr_grouped[item]
        })
      }
      return {
        ...state,
        currentChartData:{
          ...tempChartData
        }
      }
    case types.FINISH_SWITCH_TAB:
      return {
        ...state,
        currentTab: value,
        biasSummarySettings: value != 'final' ? {
          biasMetric: state.game.round_settings[value ].bias_metrics[0],
          protectedGroup: state.game.round_settings[value].protected_groups[0],
        } : state.biasSummarySettings,
      }
    case types.FINISH_SWITCH_BIAS_SUMMARY:
      return {
        ...state,
        biasSummarySettings: {
          ...state.biasSummarySettings,
          ...value,
        },
      }
    default: return state;
  }
}

export default finishReducer;
