// reducers
import { LOCATION_CHANGE } from 'connected-react-router'
import types from './types'
import _ from 'lodash'

const INITIAL_STATE = {
	isLoading: true,
  isLoaded: false,
  isProcessing: false,
  isCurrentRoundFinished: false,
	error: null,
	game: '',
  editMode: false,
  currentRoundPlayers: [],
  joinedPlayers: [],
  allOptions: null,
}

const aboutReducer = (state=INITIAL_STATE, {type, value}) => {
  switch(type) {
    case LOCATION_CHANGE:
      return INITIAL_STATE
  	case types.GET_GAME_ABOUT_REQUEST:
  		return {
  			...state,
  			isLoading: true,
  		}
  	case types.GET_GAME_ABOUT_SUCCESS:
  		return {
  			...state,
  			isLoading: false,
  			game: value.game,
        currentRoundPlayers: value.currentRoundPlayers,
        allOptions: value.allOptions,
        isLoaded: true,
  		}
  	case types.GET_GAME_ABOUT_FAILURE:
  		return {
  			...state,
  			isLoading: false,
  		}
    case types.UPDATE_GAME_SETTINGS_REQUEST:
      return {
        ...state,
        isProcessing: true,
      }
    case types.UPDATE_GAME_SETTINGS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        game: {
          ...state.game,
          ...value,
        },
      }
    case types.UPDATE_GAME_SETTINGS_FAILURE:
      return {
        ...state,
        isProcessing: false,
      }
    case types.GAME_SETTINGS_TOGGLE_EDIT_MODE:
      return {
        ...state,
        editMode: !state.editMode,
      }
    case types.LAUNCH_GAME_REQUEST:
      return {
        ...state,
        isProcessing: true,
      }
    case types.LAUNCH_GAME_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        ...value,
      }
    case types.LAUNCH_GAME_FAILURE:
      return {
        ...state,
        isProcessing: false,
      }
    case types.PLAYER_HAS_JOINED:
      return {
        ...state,
        joinedPlayers: _.uniq(state.joinedPlayers.concat(value)),
      }
    default: return state;
  }
}

export default aboutReducer;
