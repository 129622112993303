import { Icons } from 'app/assets'
import * as Config from 'app/config'

// Directus API
export const CMS_TOKEN_LIFE_SECONDS = Config.CMS_TOKEN_LIFE_SECONDS
export const CMS_PROJECT = Config.CMS_PROJECT
export const CMS_API_BASE = Config.CMS_API_BASE

// Lab App Service API (including PwC Identity Auth and Directus Auth)
export const LAS_API_BASE = Config.LAS_API_BASE

// Socket
export const SOCKET_URL = Config.SOCKET_URL
export const LAS_API_WEBHOOK = Config.LAS_API_WEBHOOK
export const SOCKET_NAMESPACE = Config.SOCKET_NAMESPACE
export const SOCKET_MESSAGE = {
	PLAYER_HAS_JOINED: 'Player Has Joined',
	PLAYER_HAS_SUBMITTED: 'Player Has Submitted',
}

// Directus Auth API
export const LAS_API_CMS_AUTH = Config.LAS_API_CMS_AUTH
export const LAS_API_CMS_REFRESH = Config.LAS_API_CMS_REFRESH

export const APP_TITLE = 'The Regulation Game'

export const TABLE_FIELDS = {
	consumer_data: 'id,paid_back,credit_amount,score_m1,score_m2,score_m3,sex,age,employment,housing,citizenship_status,marital_status',
}

export const ERROR = {
	axios: {
		ECONNABORTED: { title: 'Network Error', message: 'Please check your network and try again later.' },
		ETIMEDOUT: { title: 'Network Error', message: 'Please check your network and try again later.' },
	},
	cms: {

	},
	general: {
		network: { title: 'Network Error', message: 'Please check your network and try again later.' },
		unknown: { title: 'Unknown Error', message: 'Please try again later.' },
	}
}

export const EMPTY = ''
export const SPACE = ' '
export const SLASH = '/'
export const LODASH = '_'
export const COLON = ':'
export const COMMA = ','
export const POINT = '.'

export const CURRENCY = {
	dollar: '$',
}

export const UNITS = {
	minute: 'min',
}

export const MOMENT_FORMAT = {
	Date: {
		standard: 'YYYY-MM-DD ',
		display: 'MMM D, YYYY',
	},
	DateTime: {
		standard: 'YYYY-MM-DD HH:mm:ss',
		display: 'MM/DD/YYYY h:m:sa',
	},
	Time: {
		round: 'mm:ss',
	}
}

export const FOOTER_TEXT = `© 2020 PwC. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see www.pwc.com/structure for further details.`

export const GAME_STATUS = {
	ACTIVE: 'active',
	COMPLETED: 'completed',
}

export const PLAYER_ROLE = {
	regulator: 'regulator',
	lender: 'lender',
}

export const PLAYER_ROLE_MAP = {
	regulator: {
		name: 'regulator',
		title: 'Regulator',
		isRegulator: true,
		pgsMax: 2,
	},
	lender: {
		name: 'lender',
		title: 'Financial Lender',
		isRegulator: false,
		pgsMax: 1,
	}
}

export const PLAYER_ICON = {
	regulator: {
		orange: Icons.capital_orange,
		black: Icons.capital_black,
		grey: Icons.capital_grey,
		white: Icons.capital_white,
	},
	lender: {
		orange: Icons.wealth_orange,
		black: Icons.wealth_black,
		grey: Icons.wealth_grey,
		white: Icons.wealth_white,
	}
}

export const PLAYERS = [ 'regulator', 'financial_lender_1', 'financial_lender_2', 'financial_lender_3' ]
export const LENDERS = [ 'financial_lender_1', 'financial_lender_2', 'financial_lender_3' ]

export const ACTIONS = {
	dataScientist: {
		name: 'Data Scientist',
		cost: 5000,
	},
	reviewPenalty: {
		name: 'Submit Penalty to Review Board',
	},
	lobby: {
		name: 'Lobby',
	},
	audit: {
		name: 'Audit',
	},
	provideWorkshop: {
		name: 'Provide Educational Workshop',
	},
	requestTransparency: {
		name: 'Request Transparency',
	},
	setupConference: {
		name: 'Setup Industry Conference'
	},
	collectMoreData: {
		name: 'Collect More Data',
	},
}

export const CONSUMER_SHRINK_RATIO = 0.95

export const GAME_TABS = [
	{title: 'BACKGROUND', path: '/game_background'},
	{title: 'THE RULES', path: '/game_rules'},
	{title: 'SETTINGS', path: '/game_settings'},
	{title: 'GAME LAUNCH', path: '/game_launch'}
]
export const REGULATION_TABS = [
	{title: 'PROTECTED GROUPS', path: '/relation_protected_groups'},
	{title: 'BIAS METRICS', path: '/relation_bias_metrics'},
	{title: 'DATA SCIENTIST', path: '/relation_data_scientist'},
	{title: 'ACTIONS', path: '/relation_actions'}
]
export const DEVIDER = 'DEVIDER'

export const DEFAULT_ROUND_SETTINGS = {
  1: {
    protected_groups: [1, 2],
    bias_metrics: [3, 4]
  },
  2: {
    protected_groups: [1, 2],
    bias_metrics: [1, 3, 4]
  },
  3: {
    protected_groups: [1, 2, 3, 4],
    bias_metrics: [1, 3, 4]
  },
  4: {
    protected_groups: [1, 2, 3, 4, 5, 6],
    bias_metrics: [1, 2, 3, 4]
  },
  5: {
    protected_groups: [1, 2, 3, 4, 5, 6],
    bias_metrics: [1, 2, 3, 4]
  }
}

export const GAME_SETTINGS = {
  nbFinancialLenders: {
    name: 'nbFinancialLenders',
    title: '# Financial Lenders',
    min: 0,
    max: 3,
    step: 1,
    integerOnly: true,
  },
  startingBudget: {
    name: 'starting_budget',
    title: 'Starting Budget',
    unit: CURRENCY.dollar,
    min: 10000,
    max: 10000000,
    step: 1000,
    integerOnly: true,
  },
  nbGameRounds: {
    name: 'rounds',
    title: '# Game Rounds',
    min: 1,
    max: 10,
    step: 1,
    integerOnly: true,
  },
  regulator: {
    name: 'regulator',
    title: 'REGULATOR',
  },
  financialLenders: {
    name: 'financialLenders',
    namePrefix: 'financial_lender_',
    titlePrefix: 'FINANCIAL LENDER ',
  },
  playerMode: {
    name: 'player_mode',
    title: 'Player Mode',
    options: [
      {
        title: 'Data Scientist',
        value: 'Data Scientist'
      },
      {
        title: 'Business User',
        value: 'Business User'
      }
    ],
  },
  penalty: {
    name: 'penalty',
    title: 'Penalty',
    unit: CURRENCY.dollar,
    min: 0,
    max: 500000,
    step: 1000,
    integerOnly: true,
  },
  defaultBaselineModalThresholds: {
    name: 'default_baseline_modal_thresholds',
    title: 'Default Baseline Modal Thresholds',
    min: 0,
    max: 1,
    step: .01,
    integerOnly: false,
  },
  interestRateIndex: {
    name: 'interest_rate_index',
    title: 'Interest Rate Index',
    min: 0,
    max: 1,
    step: .01,
    integerOnly: false,
  },
  core: {
    title: 'CORE',
    gameInitiation: {
      title: 'Game Initiation',
    },
    teamNames: {
      title: 'Team Names',
    }
  },
  defaults: {
    title: 'DEFAULTS',
  }
}

export const GAME_DESCRIPTION = {
	game: {
		title: 'THE GAME',
		content: [
			`Who should get a loan? A simple question with a complicated answer.`,
			`The Regulation Game is a role-playing simulation that puts players in two very different positions- government regulator versus private
		financial lenders- to weigh the tradeoffs between profit and algorithmic fairness in the context of financial loans.`,
			`Players explore the impact of different methods for quantifying and evaluating bias in AI algorithms for loan approval as they design
		strategies to win.`
		],
	},
	players: {
		title: 'THE PLAYERS',
		content: [
			{
				title: 'The Regulator',
				content: [
				`The AI Regulator seeks to maximize consumer welfare (% of loans
				accepted) while minimizing bias and improving their profits from penalizing violating companies. First, perhaps, they need to figure out
				what bias means.`
				],
				icon: Icons.capital_black,
			},
			{
				title: 'The Lender',
				content: [
				`The Financial Lenders need to figure out how to tune their approval
				algorithm to make a profit while dodging scrutiny and/or penalties from the Regulator. The game can be played with up to 3 lenders
				fighting for the same consumer pool.`
				],
				icon: Icons.wealth_black,
			}
		],
	},
	rules: {
		title: 'THE RULES',
		content: [
			`1. No sharing information unless it is allowed under a specific move chosen.`,
			`2. Each player will start with an inital amount of wealth and the game will end after a certain number of prespecified rounds (see settings) or when all players are bankrupt.`,
			`3. The Financial Lenders will be evaluated solely on profit while the Regulator will be judged on consumer welfare, bias and profit.`,
			`4. Within game winner will be chosen based on performance relative to the best possible outcomes for a player (e.g. comparing profit
			achieved versus possible profit for the Lender).`
		]
	},
	definitions: {
		title: 'DEFINITIONS',
		content: [
			{
				title: 'Profit',
				content: [
					{
						title: 'Regulator',
						content: `Revenue incoming from penalty fines on lenders minus costs for actions taken`
					},
					{
						title: 'Lenders',
						content: `Revenue from interest on loans approved minus costs for actions taken`
					}
				]
			}, {
				title: 'Consumer Welfare (CW)',
				content: [
					`Percent of people successfully approved for a loan:`,
					`# People Approved for Loans`,
					DEVIDER,
					`# People Applied for Loans`
				]
			}, {
				title: 'Bias',
				content: [
					`You decide!`
				]
			}
		]
	},
	the_regulator: {
		title: 'The Regulator',
		content: [
			{
			title: 'The Regulator',
	  	icon: Icons.capital_black,
			}
		]
	},
	the_lenders: {
		title: 'The Lenders',
	  lender_icon: Icons.wealth_black,
		share_icon: Icons.share_box_black,
	},
	launch: {
		content: 'Do you accept the challenge?'
	}
}

export const REGULATION_DESCRIPTION = {
	protectedGroups: {
		strategicChoices: {
			title: 'STRATEGIC CHOICES',
			protectedGroups: {
				title: 'Protected Groups',
				content: {
					regulator: `{PLAYER_NAME} can select up to 2 protected groups to legally protect against bias in the financial loan process.
					Protected groups are listed below with the associated group categories that would be protected.`,
					lender: `{PLAYER_NAME} can select up to 1 group to protect against bias in their loan approval algorithm. Selecting a particular
					group will allow {PLAYER_NAME} to modify loan approval thresholds for individuals in that group in order to increase approval
					rates. Protected groups are listed below with the associated group categories that would be considered.`
				}
			}
		},
		description: {
			title: 'DESCRIPTION',
			content: {
				regulator: `{PLAYER_NAME} needs to decide whether to legally protect specific groups from discrimination in the financial loan
				approval process. For example, employment law has specified a series of protected characteristics (e.g. disability, religion,
				age, etc.) that cannot be used as a basis for employment decisions. Selecting Protected Groups is an important component of
				defining what bias should be considered illegal.`,
				lender: `{PLAYER_NAME} needs to decide how to minimize potential bias in their financial loan approval process.
				Current regulations require fair treatment but allow for explained differences in group treatment.
				Protecting minority groups from bias could help with the bank's brand image, however, these minority groups tend to be
				less represented in {PLAYER_NAME}'s customer base and tend to display higher rates of default. {PLAYER_NAME} can use the
				Data Science page to understand the implications of selecting different bias metrics and thresholds to evaluate these customers.`
			}
		}
	},
	biasMetrics: {
		strategicChoices: {
			title: 'STRATEGIC CHOICES',
			biasMetrics: {
				title: 'Bias Metrics',
				content: {
					regulator: `{PLAYER_NAME} can select up to 1 metric that are used to regulate bias in the financial loan approval process.
					{PLAYER_NAME} sets a Legal Threshold of allowed variance in metric level for each protected group when compared to the whole group.`,
					lender: `{PLAYER_NAME} can select one metric to evaluate bias in their loan approval algorithm. Explore below to understand
					the definitions of the metrics and use the info buttons to see examples. Click "Adjust Thresholds" to further adjust model
					decision making thresholds for selected protected groups. Before making final determinations, {PLAYER_NAME} could use the
					Data Scientist page to analyze the implications of different choices.`
				}
			}
		},
		description: {
			title: 'DESCRIPTION',
			content: {
				regulator: `{PLAYER_NAME} must decide how bias should be evaluated in the financial loan approval process. Over 50 metrics have
				been designed to quantify bias, however the government's committee on Responsible AI has narrowed it down to these four metrics
				for financial loans. Selecting the right metrics will impact how bias is measured and can significantly change how protected
				groups are treated.`,
				lender: `{PLAYER_NAME} has been given a pretrained loan approval algorithm that was built using historical bank data. The algorithm
				provides a risk score for each individual applying to a loan on a scale from 0-100 (100 being the least risky). {PLAYER_NAME} can
				decide what threshold to accept individuals (the default is accept anybody with a score above 70), and whether to adjust the
				thresholds to equalize one bias metric for the protected groups selected (e.g. selecting Group Fairness will adjust acceptance
				thresholds to equalize loan acceptance rates across selected protected groups against the population).`
			}
		}
	},
	dataScientist:{
		title: 'DATA VISUALIZATION',
		content: {
			title: 'Experimental Playground',
			lender: `{PLAYER_NAME} can use their data scientist to test out the implications of various weights and bias metrics using a training
			data sample on historical loan applications. Changes made here are not official moves, use this area to design your strategy and then
			input your actual moves on the other pages.`
		},
		headings: [
			'Set Thresholds',
			'Adjust Acceptance Thresholds',
			'Bias Metrics by ',
			'Model Accuracy',
			'Profitability',
			'Loan Approval Counts by ',
			'Graph Toggles',
			'Reset To Thresholds',
		],
		overlay: {
			content: {
				lender: 'Use this page as a playground to experiment with adjustments to the overall thresholds for accepting loan applicants. {PLAYER_NAME} can adjust an overall model threshold for accepting applicants, and then adjust thresholds for any protected groups selected. Usage of this page costs $5,000 per round.'
			},
			button: 'Purchase Access',
			cost: 5000,
			icon: Icons.wealth_black,
		}
	},
	actions: {
		strategicChoices: {
			title: 'STRATEGIC CHOICES',
			audit: {
				title: 'Audit',
				iconClass: 'icon-bar-chart-fill',
				cost: '50k',
				content: `Get a deeper insight into bias in the system by auditing 1 Financial Lender of your choice to receive a summary of
				their loan approval rates and bias metric outcomes by protected group.`
			},
			submitPenalty: {
				title: 'Submit Penalty to Review Board',
				iconClass: 'icon-alert-fill',
				cost: '10k',
				content: `Investigate 1 lender for violations against existing regulations for a fine of $100k. The Regulator will not receive
				audit data from the investigation but will be notified if a violation if found.`
			},
			provideWorkshop: {
				title: 'Provide Educational Workshop',
				iconClass: 'icon-audience-fill',
				cost: '5k',
				content: `Meet with the lenders to collaborate on the definition of bias. This is the only action the Regulator can take that
				allows for interaction/communication with the lenders.`
			},
			requestTransparency: {
				title: 'Request Transparency',
				iconClass: 'icon-search-fill',
				cost: '5k',
				content: `Ask financial lenders to share high level information on loan approval rates. Lenders can decide what to share and
				what to keep private.`
			}
		},
		description: {
			title: 'DESCRIPTION',
			content: {
				regulator: `{PLAYER_NAME} can select from above actions each round to achieve the outcomes they are looking for. {PLAYER_NAME} can take
				an unlimited number of actions but each of them come with a financial cost and some come with uncertain outcomes or response from
				the lenders.`,
				lender: `{PLAYER_NAME} can select from above actions each round to achieve the outcomes they are looking for. {PLAYER_NAME} can take
				an unlimited number of actions but each of them come with a financial cost and some come with uncertain	outcomes or response from
				the Regulator.`
			}
		}
	}
}

export const REGULATION_GROUP_CATEGORIES = [
	{
		title: 'Sex',
		options: ['Female', 'Male'],
	},
	{
		title: 'Age',
		options: ['<25', '25-45', '45-65', '65+'],
	},
	{
		title: 'Employment',
		options: ['Highly skilled employed', 'Skilled employed', 'Unskilled employed', 'Unemployed'],
	},
	{
		title: 'Housing',
		options: ['Owns', 'Rents', 'Subsidized'],
	},
	{
		title: 'Citizenship Status',
		options: ['Citizen', 'Non-citizen'],
	},
	{
		title: 'Marital Status',
		options: ['Single', 'Married', 'Divorced/Separated', 'Widowed'],
	}
]

export const POPUP_INFO = {
	biasMetrics: {
		'Group Fairness': {
			title: 'Example: Group Fairness',
			content: `If Sex was selected as a protected attribute, enforcing Group Fairness would require loan approval rates for Females
			and Males to equal the overall approval rate.`
		},
		'Predictive Parity': {
			title: 'Example: Predictive Parity',
			content: `If Sex was protected, regulating Predictive Parity would require that if 75% of the population pays back the loan,
			then 75% of Females and 75% of Males would pay back the loan. This avoids situations where protected groups are expected to have
			higher pay back probabilities for approval.`
		},
		'False Positive Rate': {
			title: 'Example: False Positive Rate (FPR)',
			content: `If Sex was protected, equalizing FPR would create equal probabilities of default across Males, Females, and the population.
			This would prevent banks from accepting higher default rates from non-protected groups, but may reduce approvals for the general
			population.`
		},
		'False Negative Rate': {
			title: 'Example: False Negative Rate (FNR)',
			content: `If Sex was protected, equalizing FNR would give the same probability of rejecting a good applicant amongst Females,
			Males, and the population. This would increase approvals for protected groups but may increase defaults due to noisiness in data on
			protected groups.`
		},
		'Legal Threshold': {
			title: 'Legal Threshold',
			content: `The Legal Threshold is the disparity legally allowed from the group level for a regulated metric. For example, if Group
			Fairness is regulated and the group level loan approval rate was 70%, setting the Legal Threshold to 15% would require that all
			protected groups be given loans at rates between 55-85% to be considered legal.`
		}
	}
}

export const REGULATION_BIAS_METRICS = {
	dataSource: [
		{
			metric: 'Group Fairness',
			definition: `Both protected and unprotected groups have an equal probability of loan approval (all groups are granted equivalent
			oppotunity regardless of status).`,
			regulate: false,
			protect: false,
			legalThreshold: 0,
		},
		{
			metric: 'Predictive Parity',
			definition: `For both protected and unprotected groups, there is an equal accuracy rate of predicting who will not default.`,
			regulate: false,
			protect: false,
			legalThreshold: 0,
		},
		{
			metric: 'False Positive Rate',
			definition: `Both protected and unprotected groups have an equal probability of incorrectly being approved (e.g. both groups have
			the same loan default rate).`,
			regulate: false,
			protect: false,
			legalThreshold: 0,
		},
		{
			metric: 'False Negative Rate',
			definition: `Both protected and unprotected groups have an equal probability of incorrectly begin denied (e.g. both groups have
			the same rate of denying good applicants).`,
			regulate: false,
			protect: false,
			legalThreshold: 0,
		}
	],
	popupInfo: {
		'Group Fairness': {
			title: 'Example: Group Fairness',
			content: `If Sex was selected as a protected attribute, enforcing Group Fairness would require loan approval rates for Females
			and Males to equal the overall approval rate.`
		},
		'Predictive Parity': {
			title: 'Example: Predictive Parity',
			content: `If Sex was protected, regulating Predictive Parity would require that if 75% of the population pays back the loan,
			then 75% of Females and 75% of Males would pay back the loan. This avoids situations where protected groups are expected to have
			higher pay back probabilities for approval.`
		},
		'False Positive Rate': {
			title: 'Example: False Positive Rate (FPR)',
			content: `If Sex was protected, equalizing FPR would create equal probabilities of default across Males, Females, and the population.
			This would prevent banks from accepting higher default rates from non-protected groups, but may reduce approvals for the general
			population.`
		},
		'False Negative Rate': {
			title: 'Example: False Negative Rate (FNR)',
			content: `If Sex was protected, equalizing FNR would give the same probability of rejecting a good applicant amongst Females,
			Males, and the population. This would increase approvals for protected groups but may increase defaults due to noisiness in data on
			protected groups.`
		},
		'Legal Threshold': {
			title: 'Legal Threshold',
			content: `The Legal Threshold is the disparity legally allowed from the group level for a regulated metric. For example, if Group
			Fairness is regulated and the group level loan approval rate was 70%, setting the Legal Threshold to 15% would require that all
			protected groups be given loans at rates between 55-85% to be considered legal.`
		}
	}
}

export const CREATE_GAME_DESCRIPTION = {
	team_section: {
		title: 'Enter team names for each player: '
	},
	errors: {
		player_name_uniqueness: 'Player Names are not unique, please make sure the player names are unique.'
	}
}

export const LOBBY_DESCRIPTION = {
	lobby_desc: {
		title: 'Active Games',
		content: 'Please select from the list of active games below to launch your game. Once you select your game, you will be given the option of which player to play and will be asked to enter your team name.'
	},

	waiting_table: {
		col_1_title: 'Game Name',
		col_2_title: 'Datetime Initiated',
		col_3_title: 'Joined Players'
	},

	completed_table: {
		title: 'Recently Completed Games'
	}
}

export const PLAYER_SELECT_DESCRIPTION = {
	the_regulator: {
		title: 'The Regulator',
		content: [
			{
			title: 'The Regulator',
	  	icon: Icons.capital_black,
			}
		]
	},
	the_lenders: {
		title: 'The Lenders',
	  lender_icon: Icons.wealth_black,
		share_icon: Icons.share_box_black,
		}
}
