import React from 'react';
import { Button } from 'appkit-react';
import { Link } from "react-router-dom";
import * as RouteName from 'app/routes/route-name'

const NewGame = ({  }) => {
	return (
    <div className='new-game-zone'>
    	<span>Who should get a loan?</span>
    	<span>A simple question with a complicated answer.</span>
    	<span>Weigh the tradeoffs between profit and algorithmic fairness within financial loans.</span>
    	<Link to={RouteName.new_game}>
    		<Button>NEW GAME</Button>
    	</Link>
    </div>
  )
};

export default NewGame;
