import React, { useEffect } from 'react';
import { Button } from 'appkit-react';
import { Link, useParams } from "react-router-dom";
import pluralize from 'pluralize'
import { Loading } from 'app/common/components'
import * as RouteName from 'app/routes/route-name'
import { range } from 'app/common/utils'
import moment from 'moment'
import { SLASH, MOMENT_FORMAT, PLAYER_ICON } from 'app/common/constants'

const ChoosePlayerComponent = ({ 
  isLoading, error, chosenGame, chosenPlayer,
  getGamePlayersRequest, choosePlayer,
}) => {
  const { gameId } = useParams()

  useEffect(() => {
    getGamePlayersRequest()
  }, [])
	return (
    <>
      {isLoading ? <Loading /> : (
        <div className='choose-player-page'>
          <div>
            <span className='page-title'>Choose Your Player</span>
            <Link to={(chosenPlayer || '').concat(RouteName.about)}>
              <Button disabled={!chosenPlayer} className='play-button'>PLAY</Button>
            </Link>
          </div>

          <div>
            <span>{chosenGame.name}</span>
            <span>{pluralize('Round', chosenGame.rounds, true)}</span>
          </div>

          <div>
            <div className='player-icon-zone'>
              <img src={PLAYER_ICON.regulator.orange} />
              <span>Regulator</span>
            </div>
            {chosenPlayer === 'regulator' ? (
              <Button 
                  className='player-selector' 
                  onClick={() => choosePlayer()}><span className='appkiticon icon-check-mark-outline a-text-white'></span>{chosenGame.regulator}</Button>
            ) : (
              <Button 
                  className='player-selector' 
                  onClick={() => choosePlayer('regulator')} kind='secondary'>{chosenGame.regulator}</Button>
            )}
          </div>

          <div>
            <div className='player-icon-zone'>
              <img src={PLAYER_ICON.lender.orange} />
              <span>Lenders</span>
            </div>
            <div>
              {range(1, chosenGame.lenders_count).map(i => (
                <div key={i}>
                  <span>FINANCIAL LENDER {i}</span>
                  {chosenPlayer === `financial_lender_${i}` ? (
                    <Button 
                        className='player-selector' 
                        onClick={() => choosePlayer()}><span className='appkiticon icon-check-mark-outline a-text-white'></span>{chosenGame[`financial_lender_${i}`]}</Button>
                  ) : (
                    <Button 
                        className='player-selector' 
                        onClick={() => choosePlayer(`financial_lender_${i}`)} kind='secondary'>{chosenGame[`financial_lender_${i}`]}</Button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
};

export default ChoosePlayerComponent;
