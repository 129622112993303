import React from 'react';
import { Modal, ModalBody, Button } from 'appkit-react'
import numWords from 'num-words'
import { range, capitalize } from 'app/common/utils'
import { COLON } from 'app/common/constants'
import { Images } from 'app/assets'

const RoundWaiting = ({ game, round, player, isAllJoined, currentRoundPlayers, joinedPlayers }) => {
  const visible = joinedPlayers.includes(player)
  const playersCount = currentRoundPlayers.length
  const joinedCount = joinedPlayers.length
  const title = isAllJoined ? `About to Join Round ${capitalize(numWords(round))}` : `Waiting to Join Round ${capitalize(numWords(round))}`
  const messages = isAllJoined ? [
    (round > 1 ? `All players have finished looking at their results` : `All players have launched the game.`),
    `All players are ready, Round ${capitalize(numWords(round))} is beginning.`
  ] : [
    (round > 1 ? `Not all players have finished looking at their results.` : `Not all players have launched the game.`), 
    `Once all players are ready, Round ${capitalize(numWords(round))} will begin.`
  ]

  const handleCancel = () => {
  }

  return visible ? (
		<Modal
      className="round-waiting-modal"
      visible={visible}
      backdropClosable={false}
      onCancel={handleCancel}
    >
      <ModalBody>
        <div className="main-area">
          <span className='title'>{title}</span>

          <div>
            {joinedCount > 0 && range(1, joinedCount).map(i => (
              <img key={i} className='player-icon player-icon-active' src={Images.player_30_active} />
            ))}
            {(playersCount - joinedCount) > 0 
                && range(1, playersCount - joinedCount).map(i => (
              <img key={i} className='player-icon' src={Images.player_30} />
            ))}
          </div>

          <div className='messages'>
            {messages.map((i, index) => (
              <span key={index}>{i}</span>
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  ) : (null)
};

export default RoundWaiting
