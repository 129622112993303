import React from "react";
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";

import { Header, Footer, Loading } from 'app/common/components'
import { HomeContainer, CompletedGamesContainer, NewGameContainer, ChoosePlayerContainer, AboutContainer, PlayContainer, ResultContainer, FinishContainer } from 'app/screens'
import * as RouteName from './route-name'

import 'bootstrap/dist/css/bootstrap.css'
import 'appkit-react/style/appkit-react.default.css'
import 'app/scss/index.scss'

function AppRoutesComponent({}) {
  return (
    <>
      <Route component={Header} />
      <hr />
      <section className='content-wrapper'>
        <Switch>
          <Route exact path={RouteName.home} component={HomeContainer} />
          <Route exact path={RouteName.completed_games} component={CompletedGamesContainer} />
          <Route exact path={RouteName.new_game} component={NewGameContainer} />
          <Route children={<GameID />} path={RouteName.gameId} />
        </Switch>
      </section>
      <hr />
      <Route component={Footer} />
    </>
  );
}

function GameID() {
  let { gameId } = useParams()
  return (
    <Switch>
      <Route component={ChoosePlayerContainer} path={RouteName.gameId.concat(RouteName.choose_player)} />
      <Route component={AboutContainer} path={RouteName.gameId.concat(RouteName.player, RouteName.about)} />
      <Route component={PlayContainer} path={RouteName.gameId.concat(RouteName.player, RouteName.play)} />
      <Route component={ResultContainer} path={RouteName.gameId.concat(RouteName.player, RouteName.result)} />
      <Route component={FinishContainer} path={RouteName.gameId.concat(RouteName.player, RouteName.finish)} />
    </Switch>
  );
}

const mapStateToProps = state => {  
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {
  }
};

const AppRoutes = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoutesComponent);

export default AppRoutes;
