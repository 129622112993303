import React from 'react';
import { Modal, ModalBody, Button } from 'appkit-react'
import numWords from 'num-words'
import { range, capitalize } from 'app/common/utils'
import { COLON, ACTIONS, PLAYER_ROLE_MAP } from 'app/common/constants'
import { Images } from 'app/assets'

const InteractiveActions = ({ visible, game, player, interactiveActions, hidePopup, goToResult }) => {
  const getSubmitterName = (person) => {
    return person === player ? 'You' : game[person]
  }
  const getPlayerName = (person) => {
    return person === player ? 'you' : game[person]
  }

  const actionMessage = (action) => {
    switch (action.name) {
      case ACTIONS.provideWorkshop.name:
        return `-${getSubmitterName(action.submitter)} chose to provide an educational workshop.`
      case ACTIONS.requestTransparency.name:
        return `-${getSubmitterName(action.submitter)} chose to request transparency from ${getPlayerName(action.player)}.`
      case ACTIONS.setupConference.name:
        return `-${getSubmitterName(action.submitter)} chose to setup an industry conference. Financial Lenders may convene.`
      case ACTIONS.lobby.name:
        return `-${getSubmitterName(action.submitter)} chose to lobby ${getPlayerName(PLAYER_ROLE_MAP.regulator.name)}.`
    }
  }

  const title = `${interactiveActions.length > 1 ? `${interactiveActions.length} interactive actions have` : 'An interactive action has'} been chosen!`
  const messages = [
    `The following actions have been chosen`,
    ...interactiveActions.map(i => actionMessage(i)),
    `Please take time to conduct these meetings and press submit when done to see round results.`
  ]

  const handleCancel = () => {
    // hidePopup('interactiveActions')
  }

  return visible ? (
		<Modal
      className="round-submitted-modal"
      visible={visible}
      backdropClosable={false}
      onCancel={handleCancel}
    >
      <ModalBody>
        <div className="main-area">
          <span className='title'>{title}</span>

          <div className='messages'>
            {messages.map((i, index) => (
              <span key={index}>{i}</span>
            ))}
          </div>

          <div className='button-group'>
            <Button onClick={goToResult}>SUBMIT</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  ) : (null)
};

export default InteractiveActions
