import storage from 'redux-persist/lib/storage'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'

import createRootReducer from './root-reducer'
import rootSaga from './root-saga'

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [
	],
	blacklist: [
	]
}

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
	const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

	const rootReducer = createRootReducer(history)
	const persistedReducer = persistReducer(persistConfig, rootReducer)

	const router = routerMiddleware(history)
	const saga = createSagaMiddleware()
	const logger = createLogger({
	  // ...options
	})
	const middleware = composeEnhancer(applyMiddleware(router, saga, logger))

	const store = createStore(
    persistedReducer,
    preloadedState,
    middleware,
  )
  const persistor = persistStore(store)

  saga.run(rootSaga)

  return { store, persistor }
}

// const store = createStore(persistedReducer, middleware)
// let persistor = persistStore(store)

// saga.run(rootSaga)

// export {
// 	history,
// 	store,
// 	persistor,
// }
