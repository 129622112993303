import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Tabs, Tab, Button } from 'appkit-react';
import { Loading, Alert } from 'app/common/components'
import * as RouteName from 'app/routes/route-name'
import Headmast from './headmast'
import UserCard from './user-card'
import SetMetrics from './set-metrics'
import OptionalActions from './optional-actions'
import ViewImpact from './view-impact'
import RoundTimeout from './round-timeout'
import RoundSubmitted from './round-submitted'
import InteractiveActions from './interactive-actions'
import { SOCKET_MESSAGE } from 'app/common/constants'
import { socket } from 'app/common/services/socket'

import '../styles/index.scss'

function PlayComponent({
  isLoading,
  isLoaded,
  isProcessing,
  isGameCompleted,
  isAllBankrupt,
  isBankrupt,
  hasNotLaunched,
  hasNotJoined,
  isPlayerSubmitted,
  isSubmitRequested,
  isSubmitAfterTimeout,
  isCurrentRoundFinished,
  error,
  alert,
  popup,
  alertOptions,
  game,
  submittedPlayers,
  currentRoundPlayers,
  previousRoundsActions,
  selectedPlayerInActions,
  currentRoundActions,
  allOptions,
  roundClock,
  isRoundTimeout,
  isRegulator,
  role,
  isGameStarted,
  dataScientist,
  interactiveActions,
  shouldShowInteractiveActions,
  getGamePlayInfoRequest,
  updateRoundActions,
  updateActionsStatus,
  addLenders,
  showAlert,
  hideAlert,
  showPopup,
  hidePopup,
  roundTimePassed,
  updateDataScientist,
  submitRoundRequest,
  playerHasSubmitted,
  readyToResult,
  push,
}) {
  const { gameId, player } = useParams()

  const submitRound = (submitAfterTimeout) => {
    submitRoundRequest(game, currentRoundPlayers, currentRoundActions, submitAfterTimeout)
  }

  const goToResult = () => {
    push(`/${gameId}/${player}${RouteName.result}`)
  }

  useEffect(() => {
    getGamePlayInfoRequest()
  }, [])

  useEffect(() => {
    if (!isLoaded) return

    socket.on(SOCKET_MESSAGE.PLAYER_HAS_SUBMITTED, data => {
      if (data.game == gameId && data.round == game.current_round 
            && data.player !== player && !submittedPlayers.includes(data.player)) {
        playerHasSubmitted(data)
      }
    });
  }, [isLoaded])

  useEffect(() => {
    if (!isLoaded) return

    // Submit automatically ONLY WHEN round timeout and submit is not requested manually
    if (isRoundTimeout && !isSubmitRequested) {
      submitRound(true)
    } 

    if (!isRoundTimeout) {
      const interval = setInterval(() => {
        roundTimePassed()
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isLoaded, isRoundTimeout, isSubmitRequested])

  useEffect(() => {
    if (isCurrentRoundFinished) {
      setTimeout(function() {
        if (interactiveActions.length) {
          readyToResult()
        } else {
          goToResult()
        }
      }, 1500);
    }
  }, [isCurrentRoundFinished])

  useEffect(() => {
    if (hasNotLaunched) {
      setTimeout(function() {
        push(`/${gameId}/${player}${RouteName.about}`)
      }, 500);
    }
  }, [hasNotLaunched])

  useEffect(() => {
    if (hasNotJoined) {
      setTimeout(function() {
        if (interactiveActions.length) {
          readyToResult()
        } else {
          goToResult()
        }
      }, 1500);
    }
  }, [hasNotJoined])

  useEffect(() => {
    if (isGameCompleted) {
      setTimeout(function() {
        push(`/${gameId}/${player}${RouteName.finish}`)
      }, 500);
    }
  }, [isGameCompleted])

  return (
    <>
      {isLoading ? <Loading /> : (
        <>
          {isLoaded && (
            <div className='play-page'>
              <Headmast game={game} role={role} submittedPlayers={submittedPlayers} currentRoundPlayers={currentRoundPlayers} currentRoundActions={currentRoundActions} isPlayerSubmitted={isPlayerSubmitted} roundClock={roundClock} />
              <UserCard game={game} player={player} role={role} currentRoundActions={currentRoundActions} allOptions={allOptions} submitRound={submitRound} />
              <Tabs defaultValue='1' className='a-tab-item-padding-10 play-tabs'>
                <Tab value='1' label="SET METRICS">
                  <SetMetrics game={game} player={player} role={role} currentRoundActions={currentRoundActions} allOptions={allOptions} alertOptions={alertOptions}
                              updateRoundActions={updateRoundActions} showAlert={showAlert} popup={popup} showPopup={showPopup} hidePopup={hidePopup} />
                </Tab>
                <Tab value='2' label="OPTIONAL ACTIONS">
                  <OptionalActions 
                    game={game} 
                    role={role}
                    allOptions={allOptions} 
                    updateActionsStatus={updateActionsStatus} 
                    addLenders = {addLenders}
                    currentRoundActions = {currentRoundActions}
                    currentRoundPlayers={currentRoundPlayers}
                    />
                </Tab>
                { !role.isRegulator && (
                    <Tab value='3' label="VIEW IMPACT">
                      <ViewImpact
                        game={game} 
                        allOptions={allOptions}
                        currentRoundActions = {currentRoundActions}
                        updateRoundActions={updateRoundActions}
                        dataScientist={dataScientist}
                        updateDataScientist={updateDataScientist}
                        hidePopup={hidePopup}
                        />
                    </Tab>
                  )
                }
              </Tabs>
              {isProcessing ? <Loading /> : null}
              <Alert 
                visible={alert.showing}
                title={alert.title}
                content={alert.content}
                handleCancel={hideAlert} />
              <RoundSubmitted isSubmitAfterTimeout={isSubmitAfterTimeout} visible={isPlayerSubmitted && !shouldShowInteractiveActions} 
                              currentRoundActions={currentRoundActions} isCurrentRoundFinished={isCurrentRoundFinished}
                              currentRoundPlayers={currentRoundPlayers} submittedPlayers={submittedPlayers} 
                              roundClock={roundClock} hidePopup={hidePopup} />
              {shouldShowInteractiveActions && (
                <InteractiveActions visible={shouldShowInteractiveActions}
                                    game={game} player={player} interactiveActions={interactiveActions}
                                    hidePopup={hidePopup} goToResult={goToResult}  />
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default PlayComponent;
