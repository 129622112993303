import React from 'react';
import { Button } from 'appkit-react';
import { Link } from "react-router-dom";
import * as RouteName from 'app/routes/route-name'

const CompletedGames = ({  }) => {
	return (
    <div className='completed-games-zone'>
    	<Link to={RouteName.completed_games}>
    		<Button kind='transparent'>VIEW COMPLETED GAMES</Button>
    	</Link>
    </div>
  )
};

export default CompletedGames;
