import React, { useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'appkit-react/style/appkit-react.default.css';
import { InputNumber } from 'appkit-react';
import { SPACE, UNITS, CURRENCY } from 'app/common/constants'
import { formatCurrency, str2nb } from 'app/common/utils'
import _ from 'lodash'

const LabelledNumberInput = ({obj, delay=false, disabled=false, changeSettings}) => {
	const unit = obj.unit
	const delayMS = delay ? 1000 : 0

	const delayed = useCallback(_.debounce(val => {
		const keyval = { key: obj.name, val, }
		changeSettings(keyval)
	}, delayMS), [])

	const onChange = (val) => {
		delayed(val)
	}

	const formatValue = (val=0) => {
		let formatted = val.toString()
		if (unit) {
			if (Object.values(CURRENCY).includes(unit)) {
				formatted = formatCurrency(val, unit)
			} else if (unit === UNITS.minute) {
				formatted = unit.concat(SPACE, formatted)
			}
		}

		return formatted
	}

	return (
    <div className="labelled-number-input">
    	<div className='label-zone'>
			  <span className='label-title'>{obj.title}</span>
			  <span className='label-unit'>{unit ? `(${unit})` : ''}</span>
		  </div>
		  <InputNumber
		  	kind='separate'
		    disabled={disabled}
		    min={obj.min}
		    max={obj.max}
		    step={obj.step}
		    integerOnly={obj.integerOnly}
		    onChange={onChange}
		    value={obj.value || 0}
		  />
		</div>
  )
};

export default LabelledNumberInput;
