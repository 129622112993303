import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge, Button } from 'appkit-react'
import { Colors, Typography } from 'app/styles'

const Alert = ({ visible, title, content, handleCancel }) => {
	return visible ? (
		<Modal
      className="alert-modal"
      visible={visible}
      backdropClosable={false}
      onCancel={handleCancel}
    >
      <ModalHeader> 
      	<Badge type={"warning"}>WARNING</Badge> 
      	<div className="modal-title">{title}</div>
     	</ModalHeader>

      <ModalBody>
          <span>{content}</span>
      </ModalBody>

      <ModalFooter>
        <Button kind="transparent" onClick={handleCancel} size="lg" gray> OK </Button>
      </ModalFooter>
    </Modal>
  ) : (null)
};

export default Alert
