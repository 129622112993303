import { connect } from 'react-redux';
import { ResultComponent } from '../components';
import { resultActions, resultSelectors } from '../duck';
import { CalculateService } from 'app/common/services'
import { push } from 'connected-react-router'

const mapStateToProps = (state, ownProps) => {
  const { gameId, player } = ownProps.match.params

  const { isLoading, isLoaded, isProcessing, hasNotLaunched, isCurrentRoundNotFinished, popup, game, currentResults, topPerformer, allOptions, joinedPlayers, auditReport } = state.result

  return {
    isLoading,
    isLoaded,
    isProcessing,
    hasNotLaunched,
    isCurrentRoundNotFinished,
    popup,
    role: CalculateService.getRole(player),
    game,
    currentResults,
    topPerformer,
    allOptions,
    joinedPlayers,
    auditReport,
    isAllJoined: joinedPlayers.length > 0 && joinedPlayers.length === currentResults.nonBankrupts.length,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { gameId, player } = ownProps.match.params

  return {
    push: (path) => {
      dispatch(push(path))
    },
    getRoundResultsRequest: () => {
      dispatch(resultActions.getRoundResultsRequest({ gameId, player }))
    },
    startNextRoundRequest: (game, nonBankrupts) => {
      dispatch(resultActions.startNextRoundRequest({ game, player, nonBankrupts }))
    },
    playerHasJoinedNextRound: (value) => {
      dispatch(resultActions.playerHasJoinedNextRound(value))
    },
    showPopup: (key, payload={}) => {
      dispatch(resultActions.togglePopup({key, payload: { visible: true, ...payload }}))
    },
    hidePopup: (key) => {
      dispatch(resultActions.togglePopup({key, payload: { visible: false }}))
    },
    updateAuditReport: (payload) => {
      dispatch(resultActions.updateAuditReport(payload))
    },
  }
};

const ResultContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultComponent);

export default ResultContainer;
