import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Button } from 'appkit-react';
import { Loading, Alert } from 'app/common/components'
import * as RouteName from 'app/routes/route-name'
import { SOCKET_MESSAGE } from 'app/common/constants'
import Headmast from './headmast'
import Summary from './summary'
import CW from './cw'
import Wealth from './wealth'
import BiasSummary from './bias-summary'

import '../styles/index.scss'

function FinishComponent({
  history, isLoading, isLoaded, role, currentTab, biasSummarySettings, winner, game, allOptions, results, chartData, biasSummaryState, consumerData,
  getGameSummaryRequest, changeBiasSummaryState, topPerformer, currentRoundResults, players, updateCurrentRoundResults, biasSummary, updateCurrentChartData,
  currentChartData, switchTab, switchBiasSummary,
}) {
  const { gameId, player } = useParams()

  useEffect(() => {
    getGameSummaryRequest();
  }, [])

  return (
    <>
      {isLoading ? <Loading /> : (
        <>
          {isLoaded && (
            <div className='finish-page'>
              <Headmast 
                role={ role } 
                results={ results } 
                game={ game } 
                currentTab={currentTab}
                switchTab={switchTab}
              />
              <Summary 
                player={ player }
                role={ role }
                winner={ winner }
                game={ game } 
                results={ results } 
              />
              <CW 
                winner={ winner }
                game={ game } 
                player={ player } 
                results={ results } 
                topPerformer={ topPerformer } 
              />
              <Wealth 
                winner={ winner }
                game={ game } 
                player={ player } 
                currentTab={ currentTab }
                results={ results } 
                allOptions={ allOptions } 
                topPerformer={ topPerformer }
              />
              <BiasSummary 
                allOptions={ allOptions } 
                biasSummary={ biasSummary }
                biasSummarySettings={biasSummarySettings}
                switchBiasSummary={switchBiasSummary}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default FinishComponent;
