import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Button } from 'appkit-react';
import { Loading, Alert, RoundWaiting } from 'app/common/components'
import * as RouteName from 'app/routes/route-name'
import { SOCKET_MESSAGE } from 'app/common/constants'
import Headmast from './headmast'
import Summary from './summary'
import Notifications from './notifications'
import CW from './cw'
import Wealth from './wealth'
import LeaderBoard from './leader-board'
import UserCard from './user-card'
import AuditReport from './audit-report'
import QuarterMarketReport from './quarter-market-report'
import { socket } from 'app/common/services/socket'

import '../styles/index.scss'

function ResultComponent({
  isLoading,
  isLoaded,
  isProcessing,
  hasNotLaunched,
  isCurrentRoundNotFinished,
  popup,
  role,
  game,
  currentResults,
  topPerformer,
  allOptions,
  joinedPlayers,
  auditReport,
  isAllJoined,
  getRoundResultsRequest,
  startNextRoundRequest,
  playerHasJoinedNextRound,
  updateAuditReport,
  showPopup,
  hidePopup,
  push,
}) {
  const { gameId, player } = useParams()

  const startNextRound = () => {
    startNextRoundRequest(game, currentResults.nonBankrupts)
  }

  const finishGame = () => {
    push(`/${gameId}/${player}${RouteName.finish}`)
  }

  useEffect(() => {
    getRoundResultsRequest()
  }, [])

  useEffect(() => {
    if (isLoaded) {
      socket.on(SOCKET_MESSAGE.PLAYER_HAS_JOINED, data => {
        if (data.game == gameId && data.round == (game.current_round + 1) && data.player && !joinedPlayers.includes(data.player)) {
          playerHasJoinedNextRound(data.player)
        }
      });
    }
  }, [isLoaded])

  useEffect(() => {
    if (isAllJoined) {
      setTimeout(function() {
        push(`/${gameId}/${player}${RouteName.play}`)
      }, 1500);
    }
  }, [isAllJoined])

  useEffect(() => {
    if (hasNotLaunched) {
      setTimeout(function() {
        push(`/${gameId}/${player}${RouteName.about}`)
      }, 500);
    }
  }, [hasNotLaunched])

  useEffect(() => {
    if (isCurrentRoundNotFinished) {
      setTimeout(function() {
        push(`/${gameId}/${player}${RouteName.play}`)
      }, 1500);
    }
  }, [isCurrentRoundNotFinished])

  return (
    <>
      {isLoading ? <Loading /> : (
        <>
          {isLoaded && (
            <div className='result-page'>
              <Headmast game={game} role={role} currentResults={currentResults} startNextRound={startNextRound} finishGame={finishGame} />
              <Summary game={game} currentResults={currentResults} topPerformer={topPerformer} />
              <div className='result-details'>
                <div>
                  <Notifications game={game} currentResults={currentResults} showPopup={showPopup} />
                  <CW game={game} player={player} currentResults={currentResults} topPerformer={topPerformer} />
                  <Wealth game={game} player={player} currentResults={currentResults} topPerformer={topPerformer} />
                </div>

                <div>
                  <UserCard game={game} player={player} role={role} currentResults={currentResults} allOptions={allOptions} />
                  <LeaderBoard game={game} player={player} currentResults={currentResults} />
                </div>
              </div>

              {isProcessing ? <Loading /> : null}

              <RoundWaiting game={game} round={game.current_round + 1} player={player} isAllJoined={isAllJoined} currentRoundPlayers={currentResults.nonBankrupts} joinedPlayers={joinedPlayers} />
              
              {popup.quarterMarketReport.visible && (
                <QuarterMarketReport popup={popup.quarterMarketReport} marketShare={currentResults.marketShare} hidePopup={hidePopup} />
              )}

              {popup.auditReport.visible && (
                <AuditReport popup={popup.auditReport} game={game} allOptions={allOptions} auditReport={auditReport} updateAuditReport={updateAuditReport} hidePopup={hidePopup} />
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default ResultComponent;
