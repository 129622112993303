import React from 'react';
import { Button } from 'appkit-react'
import { ACTIONS } from 'app/common/constants'
import { formatCurrency } from 'app/common/utils'

const ViewImpactOverlay = ({ currentRoundActions, purchaseAccess }) => {

  const title = 'Experiment with Acceptance Rates'
  const messages = [
    `Use this tab to adjust and experiment with the data that determines the acceptance of loan applicants.`
  ]
  const costText = `Cost Per Round: ${formatCurrency(ACTIONS.dataScientist.cost)}`

  const hasEnoughMoney = currentRoundActions.wealth > ACTIONS.dataScientist.cost

  return (
    <div className='overlay-wrapper'>
      <div className='overlay'>
        <span className="appkiticon icon-bar-chart-fill banner-icon"></span>
        <span className='title'>{title}</span>
        <div className='messages'>
          {messages.map((i, index) => (
            <span key={index}>{i}</span>
          ))}
        </div>
        <span className='title'>{costText}</span>
        <Button disabled={!hasEnoughMoney} onClick={purchaseAccess}>PURCHASE ACCESS</Button>
      </div>
    </div>
  )
};

export default ViewImpactOverlay