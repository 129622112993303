// selectors
import { MOMENT_FORMAT, COLON } from 'app/common/constants'

import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

export default {
	roundClock: (roundLifeTime) => {
		const ms = moment.duration(roundLifeTime, 'seconds').format(MOMENT_FORMAT.Time.round, {trim: false}).split(COLON)
		return {
			min: ms[0],
			sec: ms[1],
			val: roundLifeTime,
			warningVal: 30,
		}
	},

	interactiveActions: (submittedActions, allOptions) => {
		const results = []
		submittedActions.forEach(submission => {
			results.push(...submission.actions.map(j => ({ submitter: submission.player, 
																										 player: j.player, 
																										 ...allOptions.actions.find(k => k.id === j.id) }))
																				.filter(j => j.is_interactive))
		})
		return results
	},
}