import { connect } from 'react-redux';
import { HomeHeader } from '../components';

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

const HomeHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeHeader);

export default HomeHeaderContainer;
