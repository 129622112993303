import React from 'react';
import { Button } from 'appkit-react';
import { formatCurrency } from 'app/common/utils'
import { Images } from 'app/assets'
import { Devider } from 'app/common/components'
import { finishSelectors } from '../duck'
import _ from 'lodash'

const Wealth = ({ winner, game, player, currentTab, results, allOptions, topPerformer }) => {
  const title = 'Wealth';
  const bestVal = _.chain(results).filter(i => i.breakdowns).map(i => i.breakdowns.wealth).max().value()
  const isBest = playerItem => playerItem.breakdowns && playerItem.breakdowns.wealth === bestVal
  const isError = playerItem => playerItem.breakdowns && playerItem.breakdowns.wealth < 0
  const isBankrupt = playerItem => !playerItem.breakdowns
  const isWinner = playerItem => winner && winner.indexOf(playerItem.player) > -1

  return (
    <div className='finish-wealth'>
      <div>
        <span className="title">{title}</span>
      </div>

    	<div className='performers'>
        {results.map((i, index) => (
          <div key={index}>
            <div className={'performer-card' + (isError(i) || isBankrupt(i) ? ' error-performer' : '')}>
              <div>
                {isWinner(i) && (<img className='trophy' src={Images.trophy_blue} />)}
                <span>{`${game[i.player]}${player === i.player ? ' (You)' : ''}`}</span>
              </div>
              <span>{i.breakdowns ? formatCurrency(i.breakdowns.wealth) : 'Bankrupt'}</span>
            </div>
            {currentTab !== 'final' && !isBankrupt(i) && (
              <div className='performer-desc'>
                {finishSelectors.getActionLabels(game, i.breakdowns, allOptions).map((item, subIndex) => (
                  <span key={subIndex}>{item}</span>
                ))}
              </div>
            )}
          </div>
        ))}

        <div className='performer-card'>
      	  <div>
            <span className="appkiticon icon-rating-fill"></span>
            <span>All Time Best Performer</span>
          </div>
          <span>{formatCurrency(topPerformer.wealth)}</span>
        </div>
    	</div>
    </div>
  )
};

export default Wealth;
