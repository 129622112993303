const Images = {
  finished_banner: require('app/assets/images/finished_banner.png'),
  home_banner: require('app/assets/images/home_banner.png'),
  landing: require('app/assets/images/landing.png'),
  pictograms: require('app/assets/images/pictograms.png'),
  play_banner: require('app/assets/images/play_banner.png'),
  player: require('app/assets/images/player.png'),
  player_30: require('app/assets/images/player_30.png'),
  player_30_active: require('app/assets/images/player_30_active.png'),
  player_active: require('app/assets/images/player_active.png'),
  result_banner: require('app/assets/images/result_banner.png'),
  trophy_blue: require('app/assets/images/trophy_blue.png'),
  trophy_white: require('app/assets/images/trophy_white.png')
}

export default Images
