import React from 'react';
import { Button, Avatar, Tooltip } from 'appkit-react';
import { ACTIONS } from 'app/common/constants'
import { formatCurrency, number2Percent } from 'app/common/utils';
import { Devider, Info } from 'app/common/components'
import { select } from 'redux-saga/effects';
import BarChart from './bar-chart'
import BarChartGroup from './bar-chart-group'
import { CalculateService } from 'app/common/services'
import _ from 'lodash'

const ViewImpactSelection = ({disabled=false, title, items, selectedItem, add, del}) => {
  return (
    <div className='view-impact-selection-zone'>
      <span>{title}</span>
      <div>
        <span>Current Selection: </span>
        {selectedItem && (
          <span>{items.find(i => i.id === selectedItem).name}</span>
        )}
      </div>
      <div className='view-impact-selections'>
        {items.map((i, index) => (
          <React.Fragment key={index}>
            {i.id === selectedItem ? (
              <Button className='view-impact-selection-selected-button' onClick={del}>
                <span className="appkiticon icon-check-mark-fill"></span>
                {i.name.toUpperCase()}
              </Button>
            ) : (
              <>
                {disabled ? (
                  <Tooltip
                      trigger='hover' 
                      content={'You must select a group to protect.'} 
                      placement="right"
                      tooltipTheme='light'
                      style={{width: 321}}>
                    <Button kind='secondary' className='view-impact-selection-unselected-button' disabled={disabled} onClick={() => add(i.id)}>
                      {i.name.toUpperCase()}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button kind='secondary' className='view-impact-selection-unselected-button' onClick={() => add(i.id)}>
                    {i.name.toUpperCase()}
                  </Button>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

const AuditReport = ({
  popup, game, allOptions, auditReport, updateAuditReport, hidePopup
}) => {
  const handleCancel = () => {
    hidePopup('auditReport')
  }

  const lenderActions = popup.lenderResults
  const realConsumers = popup.realConsumers

  const title = `${popup.title} for ${game[lenderActions.player]}`
  const model = lenderActions.model
  const pg = lenderActions.protected_groups[0]
  const bm = lenderActions.bias_metric
  const lt = lenderActions.legal_threshold
  const at = lenderActions.adjusted_threholds
  const oPG = allOptions.groupWithCategoriesList.find(i => i.id === pg)
  const oBM = allOptions.biasMetricsList.find(i => i.id === bm)

  const rPG = auditReport.protected_groups[0]
  const rBM = auditReport.bias_metric
  const rOPG = allOptions.groupWithCategoriesList.find(i => i.id === rPG)
  const rOBM = allOptions.biasMetricsList.find(i => i.id === rBM)

  const legalThresholdConf = {
    name: 'legal_threshold',
    title: 'Legal Disparity Threshold',
    min: 0,
    max: 100,
    step: 1,
    unit: '%',
    integerOnly: true,
    value: lt * 100,
  }

  const addPG = (id) => {
    const payload = {
      protected_groups: [id],
    }
    updateAuditReport(payload)
  }

  const delPG = () => {
    if (rBM) return
    const payload = {
      protected_groups: [],
    }
    updateAuditReport(payload)
  }

  const addBM = (id) => {
    if (!rPG) return
    const payload = {
      bias_metric: id,
    }
    updateAuditReport(payload)
  }

  const delBM = () => {
    const payload = {
      bias_metric: null,
    }
    updateAuditReport(payload)
  }

  const thresholds = pg && bm
                      ? { ...allOptions.thresholds[model][bm], ...at }
                      : game.default_baseline_modal_thresholds
  const consumerData = _.map(allOptions.consumerData, consumer => {
    const got_loan = realConsumers.includes(consumer.id) && CalculateService.gotLoan(consumer, model, oPG, thresholds)
    return {
      got_loan,
      paid_back: consumer.paid_back,
      credit_amount: consumer.credit_amount,
      sex: consumer.sex,
      age: consumer.age,
      employment: consumer.employment,
      housing: consumer.housing,
      citizenship_status: consumer.citizenship_status,
      marital_status: consumer.marital_status,
    }
  })

  const chartData = {
    loansApprovedAndDenied:  {
      loansApproved: {
        name: 'Loans Approved',
        data: CalculateService.getLoansApprovedByCategory(consumerData, rOPG)
      },
      loansDenied: {
        name: 'Loans Denied',
        data: CalculateService.getLoansDeniedByCategory(consumerData, rOPG)
      }
    },
    bm: {
      name: 'haha',
      data: CalculateService.getBM(consumerData, rOPG, rOBM)
    }
  }

  const categories = rOPG ? rOPG.categories: [{name: 'Overall', id: 'total'}]

  const contentDom = (
    <div className='view-impact-zone'>
      <div className='view-impact-bias-metrics-container'>
        <div className='view-impact-bias-metrics-col view-impact-for-group'>
          <ViewImpactSelection title='Protected Group' items={allOptions.groupWithCategoriesList} selectedItem={rPG} add={addPG} del={delPG} />
        </div>
        <div className='view-impact-bias-metrics-col view-impact-for-bias'>
          <ViewImpactSelection disabled={!rPG} title='Bias Metric' items={allOptions.biasMetricsList} selectedItem={rBM} add={addBM} del={delBM} />
        </div>
      </div>
      <div className='view-impact-thresholds'>
        <div className='view-impact-thresholds-title'>
          {rOPG && rOBM && (
            <>
              <span className='view-impact-thresholds-title-marked'>{rOPG.name}</span>
              <span> & </span>
              <span className='view-impact-thresholds-title-marked'>{rOBM.name}</span>
              <span> are Selected</span>
            </>
          )}
          {rOPG && !rOBM && (
            <>
              <span className='view-impact-thresholds-title-marked'>{rOPG.name}</span>
              <span> is Selected</span>
            </>
          )}
          {!rOPG && !rOBM && (
            <span>Nothing is Selected</span>
          )}
        </div>
      </div>

      <div className='view-impact-bar-chart'>
        <span className='view-impact-bar-chart-title'>Number of Loans Approved and Declined</span>
        <BarChart 
          categories={categories}
          chartData={chartData.loansApprovedAndDenied}
          hidePopup={hidePopup}
        />
      </div>

      <div className='view-impact-bar-chart-group'>
        <div className='view-impact-bar-chart-group-container'>
          <div>
            {rOPG && rOBM && (
              <>
                <span className='view-impact-bar-chart-group-title'>{rOBM.name} Percentages</span>
                <BarChartGroup
                  categories={categories}
                  chartData={chartData.bm}
                  hidePopup={ hidePopup }
                />
                <div className='view-impact-average'>
                  <div></div>
                  <span>Average Population {number2Percent(chartData.bm.data.bmr)}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Info 
      visible={popup.visible}
      title={title}
      content={contentDom}
      className='audit-report-modal'
      handleCancel={handleCancel} />
  )
}

export default AuditReport;