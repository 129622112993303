// actions
import types from './types'

export default {
	getGameAboutRequest: (value) => ({
		type: types.GET_GAME_ABOUT_REQUEST,
		value,
	}),

	getGameAboutSuccess: (value) => ({
		type: types.GET_GAME_ABOUT_SUCCESS,
		value,
	}),

	getGameAboutFailure: (value) => ({
		type: types.GET_GAME_ABOUT_FAILURE,
		value,
	}),

	updateGameSettingsRequest: (value) => ({
		type: types.UPDATE_GAME_SETTINGS_REQUEST,
		value,
	}),

	updateGameSettingsSuccess: (value) => ({
		type: types.UPDATE_GAME_SETTINGS_SUCCESS,
		value,
	}),

	updateGameSettingsFailure: (value) => ({
		type: types.UPDATE_GAME_SETTINGS_FAILURE,
		value,
	}),

	toggleEditMode: (value) => ({
		type: types.GAME_SETTINGS_TOGGLE_EDIT_MODE,
		value,
	}),

	launchGameRequest: (value) => ({
		type: types.LAUNCH_GAME_REQUEST,
		value,
	}),

	launchGameSuccess: (value) => ({
		type: types.LAUNCH_GAME_SUCCESS,
		value,
	}),

	launchGameFailure: (value) => ({
		type: types.LAUNCH_GAME_FAILURE,
		value,
	}),

	playerHasJoined: (value) => ({
		type: types.PLAYER_HAS_JOINED,
		value,
	}),
}