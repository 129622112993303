import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge, Button } from 'appkit-react'
import { Colors, Typography } from 'app/styles'

const Confirm = ({ visible, confirm}) => {
	return (
		<Modal
      className="confirm-modal"
      visible={visible}
      backdropClosable={false}
      onCancel={confirm.handleCancel}
    >
      <ModalHeader> 
      	<Badge type={"warning"}>CONFIRM</Badge> 
      	<div className="modal-title">{confirm.title}</div>
     	</ModalHeader>

      <ModalBody>
          <span>{confirm.content}</span>
      </ModalBody>

      <ModalFooter>
      	<div className="button-comp">
          <Button kind="transparent" onClick={confirm.handleCancel} size="lg" gray> NO </Button>
          <Button kind="transparent" onClick={confirm.handleConfirm} size="lg"> YES </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
};

export default Confirm
