// actions
import types from './types'

export default {
	getActiveGamesRequest: (value) => ({
		type: types.GET_ACTIVE_GAMES_REQUEST,
		value,
	}),

	getActiveGamesSuccess: (value) => ({
		type: types.GET_ACTIVE_GAMES_SUCCESS,
		value,
	}),

	getActiveGamesFailure: (value) => ({
		type: types.GET_ACTIVE_GAMES_FAILURE,
		value,
	}),

	getCompletedGamesRequest: (value) => ({
		type: types.GET_COMPLETED_GAMES_REQUEST,
		value,
	}),

	getCompletedGamesSuccess: (value) => ({
		type: types.GET_COMPLETED_GAMES_SUCCESS,
		value,
	}),

	getCompletedGamesFailure: (value) => ({
		type: types.GET_COMPLETED_GAMES_FAILURE,
		value,
	}),

	deleteGameRequest: (value) => ({
		type: types.DELETE_GAME_REQUEST,
		value,
	}),

	deleteGameSuccess: (value) => ({
		type: types.DELETE_GAME_SUCCESS,
		value,
	}),

	deleteGameFailure: (value) => ({
		type: types.DELETE_GAME_FAILURE,
		value,
	}),

	changeSettings: (value) => ({
		type: types.CHANGE_SETTINGS,
		value,
	}),

	addLenders: (value) => ({
		type: types.ADD_LENDERS,
		value,
	}),

	deleteLenders: (value) => ({
		type: types.DELETE_LENDERS,
		value,
	}),

	createGameRequest: (value) => ({
		type: types.CREATE_GAME_REQUEST,
		value,
	}),

	createGameSuccess: (value) => ({
		type: types.CREATE_GAME_SUCCESS,
		value,
	}),
	
	createGameFailure: (value) => ({
		type: types.CREATE_GAME_FAILURE,
		value,
	}),
	
	getGamePlayersRequest: (value) => ({
		type: types.GET_GAME_PLAYERS_REQUEST,
		value,
	}),

	getGamePlayersSuccess: (value) => ({
		type: types.GET_GAME_PLAYERS_SUCCESS,
		value,
	}),

	getGamePlayersFailure: (value) => ({
		type: types.GET_GAME_PLAYERS_FAILURE,
		value,
	}),

	choosePlayer: (value) => ({
		type: types.CHOOSE_PLAYER,
		value,
	}),

	toggleConfirm: (value) => ({
		type: types.HOME_TOGGLE_CONFIRM,
		value,
	}),
}