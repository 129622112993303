import { all } from 'redux-saga/effects'

import { homeSagas } from 'app/screens/home/duck'
import { aboutSagas } from 'app/screens/about/duck'
import { playSagas } from 'app/screens/play/duck'
import { finishSagas } from 'app/screens/finish/duck'
import { resultSagas } from 'app/screens/result/duck'

export default function* rootSaga() {
	yield all([
		homeSagas.getActiveGamesSaga(),
		homeSagas.getCompletedGamesSaga(),
		homeSagas.createGameSaga(),
		homeSagas.deleteGameSaga(),
		homeSagas.getGamePlayersSaga(),
		aboutSagas.getGameAboutSaga(),
		aboutSagas.updateGameSettingsSaga(),
		aboutSagas.launchGameSaga(),
		playSagas.getGamePlayInfoSaga(),
		playSagas.submitRoundSaga(),
		finishSagas.getGameSummarySaga(),
		resultSagas.getRoundResultsSaga(),
		resultSagas.startNextRoundSaga(),
	])
}
