import React from 'react';

import { APP_TITLE } from 'app/common/constants'
import { HomeHeaderContainer } from 'app/screens'

import * as RouteName from 'app/routes/route-name'

const Header = ({ location }) => {
	let header

	console.log(location)

	if (location.pathname === RouteName.home) {
		header = null
	} else {
		header = (
			<header className='main-header'>
				<div className="a-pwc-logo-grid a-md" />
        <div className="divider" />
        <span className="app-title">{APP_TITLE}</span>
			</header>
		)
	}
	
	return (
		<>
			{header}
		</>
	)
};

export default Header;

// import { APP_TITLE } from 'app/common/constants'

// const Header = ({isLanding, round, isPlaying=false, isCompleted=false, isActionDisabled, action=null}) => {
// 	return (
//     <div className={isLanding ? "a-bg-white": "a-bg-dark"} style={theStyle.container}>
//     	{isPlaying ? (<span className="header-round">Round {round}</span>) : null}
//     	<span className={isLanding? "a-text-dark" : "a-text-white"} style={isLanding ? theStyle.span.isLanding : theStyle.span.notLanding}>{APP_TITLE}</span>
//     	{isPlaying || isCompleted ? (<span style={theStyle.play} onClick={action} className={`appkiticon icon-play-outline a-font-40 a-text-white ${isActionDisabled ? 'rai-button-grey' : 'rai-button-orange'}`} aria-hidden="true"></span>) : null}
//     </div>
//   )
// };

// export default Header;

// const theStyle = {
// 	span: {
// 		isLanding: {
// 			fontSize: '6rem',
// 			fontWeight: 700,
// 		},
// 		notLanding: {
// 			fontSize: '3rem',
// 			fontWeight: 700,
// 		}
// 	},
// 	container: {
// 		textAlign: 'center',
// 		flex: 'none',
// 		position: 'relative',
// 	},
// 	play: {
// 		position: 'absolute',
// 		height: '100%',
// 		right: '1rem',
// 	}
// }
