import React from 'react';
import { Button, Table } from 'appkit-react';
import { Link } from "react-router-dom";
import * as RouteName from 'app/routes/route-name'
import { range } from 'app/common/utils'
import moment from 'moment'
import { MOMENT_FORMAT } from 'app/common/constants'
import { Images } from 'app/assets'

const ActiveGames = ({ activeGames, push }) => {
	const columns = [
    {
      Header: '',
      accessor: 'name',
      Cell: row => (
			  <div className='a-col-align-start'>
			  	<span className='game-name'>{row.original.name}</span>
			  	<span className='game-created_at'>
			  		{moment(row.original.created_at, MOMENT_FORMAT.DateTime.standard).format(MOMENT_FORMAT.DateTime.display)}
			  	</span>
			  </div>
	    )
    },
    {
      Header: '',
      accessor: 'players_count',
      Cell: row => (
      	<div className='a-col-align-start'>
      		<div>
      			{row.original.joined_count > 0 && range(1, row.original.joined_count).map(i => (
              <img key={i} className='player-icon' src={Images.player_active} />
      			))}
      			{row.original.players_count - row.original.joined_count > 0 
      					&& range(1, row.original.players_count - row.original.joined_count).map(i => (
      				<img key={i} className='player-icon' src={Images.player} />
      			))}
      		</div>
      		<span className='game-players'>Players {row.original.joined_count}/{row.original.players_count}</span>
      	</div>
      )
    },
    {
      Header: '',
      accessor: 'rounds',
      Cell: row => (
        <div className='a-col-align-start'>
          <span className='game-players'>Round: {row.original.current_round}/{row.original.rounds}</span>
        </div>
      )
    },
    {
      Header: '',
      accessor: 'id',
      Cell: row => (
      	<span className='join-game-icon appkiticon icon-right-chevron-outline a-text-orange'></span>
      )
    }
  ];

	return (
    <div className='active-games-zone'>
    	<span>Join a Game</span>
    	<Table
          data={activeGames}
          columns={columns}
          defaultPageSize={5}
          getTrProps={(state, row, column) => ({
            onClick: (e) => {
              push(row.original.id.toString().concat(RouteName.choose_player))
            }
          })}
        />
    </div>
  )
};

export default ActiveGames;
