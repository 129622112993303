import * as CONSTANTS from 'app/common/constants'
import { lasAxios } from './axios'

// lasAxios.interceptors.request.use(
// 	async (config) => {
// 		// Do something before request is sent
// 		return config
// 	}, (error) => {
// 	// Do something with request error
//     return Promise.reject(error);
// 	})

lasAxios.interceptors.response.use(
	(response) => {
		// Any status code that lie within the range of 2xx cause this function to trigger
	  // Do something with response data
	  return response.data
	}, (e) => {
	  // Any status codes that falls outside the range of 2xx cause this function to trigger
	  // Do something with response error
	  if (e.response) {
	  	return Promise.reject({ ...e.response.data.error, isLASError: true })
	  } else if (e.toJSON) {
	  	return Promise.reject({ ...e.toJSON() })
	  } else {
	  	return Promise.reject(e)
	  }
	})

function getCMSToken() {
	const url = CONSTANTS.LAS_API_CMS_AUTH
	const payload = {
		project: CONSTANTS.CMS_PROJECT
	}

	return lasAxios.post(url, payload)
}

function refreshCMSToken(token) {
	const url = CONSTANTS.LAS_API_CMS_REFRESH
	const payload = {
		token: token
	}
	return lasAxios.post(url, payload)
}

function sendMessage(message, data) {
	const url = CONSTANTS.LAS_API_WEBHOOK
	const payload = {
		message,
		data,
		namespace: CONSTANTS.SOCKET_NAMESPACE,
	}

	return lasAxios.post(url, payload)
}

export {
	getCMSToken,
	refreshCMSToken,
	sendMessage,
}