import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Tabs, Tab, Button } from 'appkit-react';
import { Loading, RoundWaiting } from 'app/common/components'
import * as RouteName from 'app/routes/route-name'
import Overview from './overview'
import Rules from './rules'
import Settings from './settings'
import _ from 'lodash'
import { SOCKET_MESSAGE, GAME_STATUS } from 'app/common/constants'
import { socket } from 'app/common/services/socket'

import '../styles/index.scss'

function AboutComponent({
  isLoading, isLoaded, isProcessing, isCurrentRoundFinished, error, game, editMode, isRegulator, isGameStarted, currentRoundPlayers, joinedPlayers, isAllJoined, allOptions,
  push, getGameAboutRequest, updateGameSettingsRequest, toggleEditMode, launchGameRequest, playerHasJoined
}) {
  const { gameId, player } = useParams()

  const launchGame = () => {
    launchGameRequest(game)
  }

  useEffect(() => {
    getGameAboutRequest()
  }, [])

  useEffect(() => {
    if (isLoaded) {
      socket.on(SOCKET_MESSAGE.PLAYER_HAS_JOINED, data => {
        if (data.game == gameId && data.round == game.current_round && data.player && !joinedPlayers.includes(data.player)) {
          playerHasJoined(data.player)
        }
      });
    }
  }, [isLoaded])

  useEffect(() => {
    if (isCurrentRoundFinished) {
      setTimeout(function() {
        push(`/${gameId}/${player}${RouteName.result}`)
      }, 0);
    }
  }, [isCurrentRoundFinished])

  useEffect(() => {
    if (isAllJoined) {
      setTimeout(function() {
        push(`/${gameId}/${player}${RouteName.play}`)
      }, 1500);
    }
  }, [isAllJoined])

  return (
    <>
      {isLoading ? <Loading /> : (
        <div className='about-page'>
          <Tabs defaultValue='1' className='a-tab-item-padding-10'>
            <Tab value='1' label="OVERVIEW">
              <Overview game={game} player={player} />
            </Tab>
            <Tab value='2' label="RULES">
              <Rules></Rules>
            </Tab>
            <Tab value='3' label="GAME SETTINGS">
              <Settings 
                game={game} player={player} editMode={editMode} allOptions={allOptions} isRegulator={isRegulator} isGameStarted={isGameStarted} 
                changeSettings={updateGameSettingsRequest} toggleEditMode={toggleEditMode} />
            </Tab>
          </Tabs>
          <Button className='launch-button' onClick={launchGame}>LAUNCH GAME</Button>
          {isProcessing ? <Loading /> : null}
          <RoundWaiting game={game} round={game.current_round} player={player} isAllJoined={isAllJoined} currentRoundPlayers={currentRoundPlayers} joinedPlayers={joinedPlayers} />
        </div>
      )}
    </>
  )
}

export default AboutComponent;
