import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { formatNumber, number2Percent } from 'app/common/utils'
import { Colors } from 'app/styles'
import _ from 'lodash'

const BarChartGroup = ({ categories, chartData }) => {
  const maxValue = _.chain(chartData.data).map(i => i.value).max().value()

  const chartOption = {
    chart: {
      type: 'bar',
      spacingLeft: 80,
      height: 220,
      width: 900,
      backgroundColor: Colors.GREY_LIGHTER,
      style: {
        fontFamily: 'Helvetica Neue',
      }
    },
    title: {
      text: '',
    },
    legend: {
      reversed: true,
      enabled: false
    },
    credits: {
      enabled: false
    },
    xAxis: {
      visible: true,
      categories,
      lineWidth: 0,
      labels: {
        style: {
          fontSize: '16px'
        }
      }
    },
    yAxis: {
      visible: true,
      reversedStacks: false,
      gridLineWidth: 0,
      tickPositions: [0, 100, 120],
      plotLines: [{ 
        color: '#FFA929',
        width: 2,
        value: chartData.average * 100,
        zIndex: 15,
      }],
      labels: {
        enabled: false
      },
      title: {
        enabled: false
      }
    },
    plotOptions: {
      series: {
        animation: false,
        stacking: 'percent'
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        name: 'percentage',
        pointWidth: 20,
        // colors: ['#F1BAC3', '#A43E50', '#A43E50', '#A43E50'],
        colors: chartData.data.map(i => i.value < maxValue ? '#F1BAC3' : '#A43E50'),
        colorByPoint: true,
        data: chartData.data.map(i => i.value)
      },
      { 
        name: 'percentage2',
        pointWidth: 20,
        dataLabels: {
          align: 'right',
          enabled: true,
          crop: false,
          overflow: 'none',
          x: 55,
          formatter: function() {
            return Math.round((1 - this.y) * 100) + '%' 
          },
          style: {
            fontSize: '20px',
            color: 'black',
            textOutline: 'none',
          }
        },
        colors: chartData.data.map(i => Colors.WHITE),
        colorByPoint: true,
        data: chartData.data.map(i => _.round(1 - i.value, 2))
      }
    ]
  }
  
  return (
    <div className="finish-chart">
      <HighchartsReact highcharts={Highcharts} options={chartOption}/>
      <div className='finish-chart-average'>
        <div></div>
        <span>Average Population {number2Percent(chartData.average)}</span>
      </div>
    </div>
  )
}

export default BarChartGroup