import { push } from 'connected-react-router'
import { connect } from 'react-redux';
import { CompletedGamesComponent } from '../components';
import { homeActions } from '../duck';

const mapStateToProps = state => {
  const { isLoading, error ,confirm, completedGames } = state.home

  return {
    isLoading,
    error,
    confirm,
    completedGames,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    push: (path) => {
      dispatch(push(path))
    },
  	getCompletedGamesRequest: () => {
  		dispatch(homeActions.getCompletedGamesRequest())
  	},
    deleteGameRequest: (gameId) => {
      dispatch(homeActions.deleteGameRequest(gameId))
    },
    toggleConfirm: (confirm) => {
      dispatch(homeActions.toggleConfirm(confirm))
    },
  }
};

const CompletedGamesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedGamesComponent);

export default CompletedGamesContainer;
