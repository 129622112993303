import React from 'react';
import { Button } from 'appkit-react';

const Notifications = ({ 
  game, 
  currentResults,
  showPopup,
}) => {
  const viewPDF = (notif) => {
    if (notif.isMarketShare) {
      showPopup('quarterMarketReport', notif)
    } else if (notif.isAudit) {
      showPopup('auditReport', notif)
    }
  }

  return (
    <div className='result-notifications'>
      {currentResults.notifications.map((i, index) => (
        <div key={index}>
          <div className='messages-zone'>
            {i.isAlert && (
              <span className="appkiticon icon-alert-fill notif-icon"></span>
            )}
            <div className='messages'>
              {i.messages.map((msg, ind) => (
                <span key={ind}>{msg}</span>
              ))}
            </div>
          </div>

          {i.isPDF && (
            <Button kind='secondary' className='notif-button' onClick={() => viewPDF(i)}>VIEW PDF</Button>
          )}
        </div>
      ))}
    </div>
  )
};

export default Notifications;
