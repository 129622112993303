import { connect } from 'react-redux';
import { HomeComponent } from '../components';
import { homeActions } from '../duck';
import { push } from 'connected-react-router'

const mapStateToProps = state => {
  const { isLoading, error, activeGames } = state.home

  return {
    isLoading,
    error,
    activeGames,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    push: (path) => {
      dispatch(push(path))
    },
  	getActiveGamesRequest: () => {
  		dispatch(homeActions.getActiveGamesRequest())
  	},
  }
};

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent);

export default HomeContainer;
