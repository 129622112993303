// types
export default {
	GET_ACTIVE_GAMES_REQUEST: 'GET_ACTIVE_GAMES_REQUEST',
	GET_ACTIVE_GAMES_SUCCESS: 'GET_ACTIVE_GAMES_SUCCESS',
	GET_ACTIVE_GAMES_FAILURE: 'GET_ACTIVE_GAMES_FAILURE',

	GET_COMPLETED_GAMES_REQUEST: 'GET_COMPLETED_GAMES_REQUEST',
	GET_COMPLETED_GAMES_SUCCESS: 'GET_COMPLETED_GAMES_SUCCESS',
	GET_COMPLETED_GAMES_FAILURE: 'GET_COMPLETED_GAMES_FAILURE',

	DELETE_GAME_REQUEST: 'DELETE_GAME_REQUEST',
	DELETE_GAME_SUCCESS: 'DELETE_GAME_SUCCESS',
	DELETE_GAME_FAILURE: 'DELETE_GAME_FAILURE',

	CHANGE_SETTINGS: 'CHANGE_SETTINGS',
	ADD_LENDERS: 'ADD_LENDERS',
	DELETE_LENDERS: 'DELETE_LENDERS',
	CHECK_PLAYER_NAME_UNIQUENESS: 'CHECK_PLAYER_NAME_UNIQUENESS',
	CREATE_GAME_REQUEST: 'CREATE_GAME_REQUEST',
	CREATE_GAME_SUCCESS: 'CREATE_GAME_SUCCESS',
	CREATE_GAME_FAILURE: 'CREATE_GAME_FAILURE',
	
	GET_GAME_PLAYERS_REQUEST: 'GET_GAME_PLAYERS_REQUEST',
	GET_GAME_PLAYERS_SUCCESS: 'GET_GAME_PLAYERS_SUCCESS',
	GET_GAME_PLAYERS_FAILURE: 'GET_GAME_PLAYERS_FAILURE',

	CHOOSE_PLAYER: 'CHOOSE_PLAYER',

	HOME_TOGGLE_CONFIRM: 'HOME_TOGGLE_CONFIRM',
}