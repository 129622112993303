import React from 'react';
import { Button } from 'appkit-react';
import { formatCurrency, numberToPercent } from 'app/common/utils'

const Summary = ({ 
	game, 
	currentResults, 
	topPerformer,
}) => {
	const compared = (a, b) => {
		if (a > b) {
			return 'higher than'
		} else if (a < b) {
			return 'lower than'
		} else {
			return 'same as'
		}
	}

  return (
    <div className='result-summary'>
    	<div>
    		<span>{formatCurrency(currentResults.profit)}</span>
    		<span> profit made</span>
    	</div>
    	<div>
    		<span>{numberToPercent(currentResults.consumer_welfare)} of loans approved is {compared(currentResults.consumer_welfare, topPerformer.consumer_welfare)}</span>
    		<span> the highest ranking player of all time</span>
    	</div>
    </div>
  )
};

export default Summary;
