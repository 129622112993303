// types
export default {
	GET_GAME_ABOUT_REQUEST: 'GET_GAME_ABOUT_REQUEST',
	GET_GAME_ABOUT_SUCCESS: 'GET_GAME_ABOUT_SUCCESS',
	GET_GAME_ABOUT_FAILURE: 'GET_GAME_ABOUT_FAILURE',

	UPDATE_GAME_SETTINGS_REQUEST: 'UPDATE_GAME_SETTINGS_REQUEST',
	UPDATE_GAME_SETTINGS_SUCCESS: 'UPDATE_GAME_SETTINGS_SUCCESS',
	UPDATE_GAME_SETTINGS_FAILURE: 'UPDATE_GAME_SETTINGS_FAILURE',

	GAME_SETTINGS_TOGGLE_EDIT_MODE: 'GAME_SETTINGS_TOGGLE_EDIT_MODE',

	LAUNCH_GAME_REQUEST: 'LAUNCH_GAME_REQUEST',
	LAUNCH_GAME_SUCCESS: 'LAUNCH_GAME_SUCCESS',
	LAUNCH_GAME_FAILURE: 'LAUNCH_GAME_FAILURE',

	PLAYER_HAS_JOINED: 'PLAYER_HAS_JOINED',
}