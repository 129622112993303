// Directus API
export const CMS_TOKEN_LIFE_SECONDS = 20 * 60
export const CMS_PROJECT = 'rai_game'
export const CMS_API_HOST = 'https://emtechcms.eastus.cloudapp.azure.com'
export const CMS_API_BASE = `${CMS_API_HOST}/${CMS_PROJECT}`

// Lab App Service API (including PwC Identity Auth and Directus Auth)
export const LAS_API_HOST = 'https://emtechauth.com'
export const LAS_API_BASE = LAS_API_HOST

// Socket
export const SOCKET_URL = `${LAS_API_BASE}/${CMS_PROJECT}`
export const LAS_API_WEBHOOK = 'web_hook'
export const SOCKET_NAMESPACE = '/rai_game'

// Directus Auth API
export const LAS_API_CMS_AUTH = 'cms/auth/authenticate'
export const LAS_API_CMS_REFRESH = 'cms/auth/refresh'