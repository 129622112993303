import React from 'react';
import { Button, Panel, RadioGroup, Radio, Checkbox } from 'appkit-react';
import { range, formatCurrency } from 'app/common/utils';
import _ from 'lodash';

const OptionalActionsSelection = ({ game, action, updateActionsStatus, currentRoundPlayers, addLenders, currentRoundActions }) => {
  const lenders = [];
  currentRoundPlayers.forEach((item, index) => {
    if(item != 'regulator') {
      lenders.push(item)
    }
  })

  const updateStatus = (id) => {
    const value = { id };
    var payload = { id };
    if(action.id != 4 && currentRoundActions.player === 'regulator') {
      payload.player = 'financial_lender_1';
    }
    updateActionsStatus(value);
    if(action.isSelected) {
      addLenders(payload);
    } 
    addLenders({ id: null });
  }

  const onChange = (event, value) => {
    addLenders({
      id: action.id,
      player: value
    })
    if(_.findIndex(currentRoundActions.actions, n => action.id === n.id) === -1) {
      updateActionsStatus({ id: action.id });
    }
  }

  if(action.isSelected) {
    return (
      <>
        <Button className='optional-actions-selection-selected-button' onClick={() => updateStatus(action.id)}>
          <span className="appkiticon icon-check-mark-fill"></span>
          SELECTED  
        </Button>
        { action.allow_multi_players != null && (
        // { currentRoundActions.player === 'regulator' && action.id != 4 && (
            <>
              <p className='optional-actions-selection-selected-radio-title'>Player Selection required</p>
              {/* { action.id === 5 && (
                  <RadioGroup onChange={onChange.bind(this)} defaultValue={ lenders[0] }>
                    { lenders.map((item, index) => {
                        return (
                          <Radio key={ index } value={ item }>{ game[item] }</Radio>
                        )
                      })
                    }
                  </RadioGroup>
                ) 
              } */}
              <div className="optional-actions-selection-selected-checkbox">
                { lenders.map((item, index) => {
                    return (
                      <Checkbox key={ index } value={ item } onChange={onChange.bind(this)} defaultChecked={index === 0}>{ game[item] }</Checkbox>
                    )
                  })
                }
              </div>
            </>
          )
        }
      </>
    )
  } else {
    return (
      <Button className='optional-actions-selection-unselected-button' onClick={() => updateStatus(action.id)}>
        <span className="appkiticon icon-plus-fill"></span>
        SELECT
      </Button>
    )
  }
}

const ActionPanel = ({ game, action, updateActionsStatus, currentRoundPlayers, addLenders, currentRoundActions }) => {
  return (
    <Panel>
      <div>
        <span className={ 'appkiticon ' + action.icon_class + ' a-font-18' }></span>
        <span>{ action.name }</span>
      </div>
      <span>{'Cost '+ formatCurrency(action.cost) + (action.allow_multi_players === null ? '' : ' per Company')}</span>
      <div><p>{ action.definition }</p></div>
      <OptionalActionsSelection 
        game={ game }
        action= { action } 
        updateActionsStatus={ updateActionsStatus } 
        currentRoundPlayers={ currentRoundPlayers }
        addLenders={ addLenders }
        currentRoundActions={ currentRoundActions } />
    </Panel>
  )
}

const OptionalActions = ({ game, role, allOptions, updateActionsStatus, addLenders, currentRoundActions, currentRoundPlayers}) => {
  const actionsOptions = allOptions.actions.filter(i => i.role.name === role.name)
  const lenders = range(1, 3)
										.map(i => `financial_lender_${i}`)
										.filter(i => game[i])
                    .map(i => { return { title: i, name: game[i] } })

  const desc = {
    title: 'Optional Strategic Moves',
    description: 'Regulators can take an unlimited number of actions but each of them come with a financial cost and some come with uncertain outcomes or responses from the lenders.',
  }

  return (
    <div className='optional-actions-zone'>
      <span>{ desc.title }</span>
      <span>{ desc.description }</span>
      <div className='optional-actions-panel-container'>
        <div className='optional-actions-panel-col'>
          <ActionPanel 
            game={ game }
            action={ actionsOptions[0] } 
            updateActionsStatus={updateActionsStatus} 
            currentRoundPlayers={ currentRoundPlayers }
            addLenders={ addLenders }
            currentRoundActions={ currentRoundActions } />
          <ActionPanel 
            game={ game }
            action={ actionsOptions[2] } 
            updateActionsStatus={ updateActionsStatus } 
            currentRoundPlayers={ currentRoundPlayers }
            addLenders={ addLenders }
            currentRoundActions={ currentRoundActions } />
        </div>
        <div className='optional-actions-panel-col'>
          <ActionPanel 
            game={ game }
            action={ actionsOptions[1] }
            updateActionsStatus={ updateActionsStatus } 
            currentRoundPlayers={ currentRoundPlayers }
            addLenders={ addLenders }
            currentRoundActions={ currentRoundActions } />
          <ActionPanel 
            game={ game }
            action={ actionsOptions[3] } 
            updateActionsStatus={ updateActionsStatus } 
            currentRoundPlayers={ currentRoundPlayers }
            addLenders={ addLenders } 
            currentRoundActions={ currentRoundActions } />
        </div>
      </div>
    </div>
  )
}

export default OptionalActions