// operations using redux-saga
import { all, call, put, take, takeLatest, takeLeading } from 'redux-saga/effects';
import { CMSService, CalculateService, SocketService } from 'app/common/services'
import { SOCKET_MESSAGE, GAME_STATUS, PLAYER_ROLE, PLAYERS, LENDERS, ACTIONS, TABLE_FIELDS, MOMENT_FORMAT, CONSUMER_SHRINK_RATIO, COMMA } from 'app/common/constants'
import { numberToThousand, formatCurrency, numberToPercent, joinByAnd } from 'app/common/utils'
import types from './types'
import actions from "./actions"
import _ from 'lodash'
import moment from 'moment'

function* getRoundResults(action) {
	try {
		const { gameId, player } = action.value
		const isRegulator = CalculateService.isRegulator(player)

		const res_game = yield call(CMSService.getItemById, 'games', gameId)
		const game = res_game.data
		let round = game.current_round

		let res_all_players_results = yield call(CMSService.getItemsByQuery, {
			collection: 'game_actions',
			filter: [
				{ field: 'game', operator: 'eq', value: gameId },
				{ field: 'round', operator: 'eq', value: round }
			],
			sort: '-consumer_welfare'
		})
		let allPlayersResults = res_all_players_results.data

		// Check if player has joined current round
		if (!allPlayersResults.find(i => i.player === player)) {
			// Player has not joined current round
			if (round > 1) {
				// Go to previous round result page if current round is not round 1
				const res_previous_round = yield call(CMSService.getItemByQuery, {
					collection: 'game_actions',
					fields: 'round',
					filter: [
						{ field: 'game', operator: 'eq', value: gameId },
						{ field: 'player', operator: 'eq', value: player }
					],
					sort: '-round'
				})
				round = res_previous_round.data.round
				game.current_round = round

				res_all_players_results = yield call(CMSService.getItemsByQuery, {
					collection: 'game_actions',
					filter: [
						{ field: 'game', operator: 'eq', value: gameId },
						{ field: 'round', operator: 'eq', value: round }
					],
					sort: '-consumer_welfare'
				})
				allPlayersResults = res_all_players_results.data
			} else {
				// Go to about page to launch game if current round is round 1
				yield put(actions.getRoundResultsFailure({
					hasNotLaunched: true,
				}))
				return
			}
		}

		// Check if current round is finished
		if (allPlayersResults.filter(i => !i.is_submitted).length > 0) {
			yield put(actions.getRoundResultsFailure({
				isCurrentRoundNotFinished: true,
			}))
			return
		}

		const currentResults = allPlayersResults.find(i => i.player === player)
		currentResults.starting_wealth = currentResults.wealth - currentResults.profit
		currentResults.leaderboard = allPlayersResults.map(i => ({ player: i.player, consumer_welfare: i.consumer_welfare }))
		currentResults.nonBankrupts = allPlayersResults.filter(i => i.wealth > 0).map(i => i.player)

		const [ res_thresholds, res_protected_groups, res_group_categories, res_bias_metrics, res_actions ] = yield all([
			call(CMSService.getItems, 'thresholds', '*'),
			call(CMSService.getItemsByQuery, {
				collection: 'protected_groups',
				filter: [
					{ field: 'id', operator: 'in', value: game.round_settings[game.current_round].protected_groups.join(COMMA) }
				]
			}),
			call(CMSService.getItemsByQuery, {
				collection: 'group_categories',
				fields: '*.*',
				filter: [
					{ field: 'group', operator: 'in', value: game.round_settings[game.current_round].protected_groups.join(COMMA) }
				]
			}),
			call(CMSService.getItemsByQuery, {
				collection: 'bias_metrics',
				filter: [
					{ field: 'id', operator: 'in', value: game.round_settings[game.current_round].bias_metrics.join(COMMA) }
				]
			}),
			call(CMSService.getItems, 'actions')
		])
		const allOptions = {
			thresholds: _.chain(res_thresholds.data)
										.groupBy('model')
										.mapValues(i => _.chain(i)
																			.groupBy('bias_metric')
																			.mapValues(j => _.chain(j).keyBy('group_category').mapValues('threshold').value())
																			.value())
										.value(),
			protectedGroups: _.keyBy(res_protected_groups.data, 'id'),
			groupCategories: _.keyBy(res_group_categories.data, 'id'),
			groupWithCategoriesList: _.chain(res_group_categories.data)
											  .groupBy('group.id')
											  .mapValues(i => ({
											    categories: i,
											    id: i[0].group.id,
											    name: i[0].group.name
											  }))
											  .sortBy('id')
											  .value(),
			biasMetrics: _.keyBy(res_bias_metrics.data, 'id'),
			biasMetricsList: res_bias_metrics.data,
			actions: _.keyBy(res_actions.data, 'id'),
		}

		// Calculate Top Performer
		const [ res_top_cw, res_top_wealth ] = yield all(
			['consumer_welfare', 'wealth'].map(key => (
				call(CMSService.getItemByQuery, {
					collection: 'game_actions',
					fields: key,
					filter: [
						{ field: 'round', operator: 'eq', value: round },
						{ field: 'player', operator: (isRegulator ? 'eq': 'neq'), value: PLAYER_ROLE.regulator },
						{ field: 'is_result_out', operator: 'eq', value: 1 }
					],
					sort: `-${key}`
				})
			)
		))
		const topPerformer = {
			consumer_welfare: res_top_cw.data.consumer_welfare,
			wealth: res_top_wealth.data.wealth,
		}

		// Calculate costs, revenues and notifications
		const costs = []
		const revenues = []
		const notifications = []

		if (!isRegulator && currentResults.use_data_scientist) {
			costs.push(ACTIONS.dataScientist)
		}

		const costsMap = {}
		for (const act of currentResults.actions) {
			const oAct = allOptions.actions[act.id]
			if (costsMap.hasOwnProperty(act.id)) {
				costsMap[act.id] += oAct.cost
			} else {
				costsMap[act.id] = oAct.cost
			}

			switch (oAct.name) {
				// Calculate Audit Actions
				case ACTIONS.audit.name:
					const res_lender = yield call(CMSService.getItemsByQuery, {
						collection: 'game_actions',
						filter: [
							{ field: 'game', operator: 'eq', value: gameId },
							{ field: 'player', operator: 'eq', value: act.player },
						],
						sort: '-round'
					})
					const lenderResults = res_lender.data[0]
					const lOPG = allOptions.protectedGroups[lenderResults.protected_groups[0]],
								lOBM = allOptions.biasMetrics[lenderResults.bias_metric],
								lModel = lenderResults.model

					notifications.push({
						messages: [`Audit for ${game[act.player]} is complete.`],
						isPDF: true,
						isAudit: true,
						title: 'Audit Report',
						lenderResults,
						nb_of_news_alerts: Math.min(_.filter(res_lender.data.slice(1), i => i.news_alert).length, game.max_nb_of_news_alerts)
					})
					break
				default:
					break
			}
		}

		for (const [actId, cost] of Object.entries(costsMap)) {
			costs.push({
				name: `${allOptions.actions[actId].name} Costs`,
				cost,
			})
		}

		if (isRegulator) {
			let lobbyRevenue = 0
			// Calculate Lobby Actions
			const lobbyCost = _.find(allOptions.actions, (val) => val.name === ACTIONS.lobby.name).cost
			const res_nb_of_lobby_actions = yield call(CMSService.getItemsByQuery, {
				collection: 'game_actions',
				fields: 'player,actions',
				filter: [
					{ field: 'game', operator: 'eq', value: gameId },
					{ field: 'player', operator: 'neq', value: PLAYER_ROLE.regulator },
					{ field: 'round', operator: 'eq', value: round }
				],
			})
			res_nb_of_lobby_actions.data
				.filter(i => i.actions.find(j => allOptions.actions[j.id].name === ACTIONS.lobby.name))
				.forEach(i => {
					lobbyRevenue += lobbyCost
					notifications.push({
						messages: [`${game[i.player]} lobbied Regulator and spent ${formatCurrency(lobbyCost)}.`],
					})
				})
			if (lobbyRevenue > 0) {
				revenues.push({
					name: 'Lobby Revenue',
					revenue: lobbyRevenue
				})
			}
	
			// Calculate Consumer Data for audited financial lenders
			if (notifications.find(i => i.isAudit)) {
				const res_consumer_data = yield call(CMSService.getItems, 'consumer_data', TABLE_FIELDS.consumer_data)
				allOptions.consumerData = res_consumer_data.data
				_.forEach(notifications, i => {
					if (i.isAudit) {
						i.realConsumers = allOptions.consumerData
															.slice(0, parseInt(allOptions.consumerData.length * Math.pow(CONSUMER_SHRINK_RATIO, i.nb_of_news_alerts)))
															.map(i => i.id)
					}
				})
			}

			// Regulator's revenue
			const penaltyRevenue = Math.round(currentResults.penalty_against.length * game.penalty)
			if (penaltyRevenue > 0) {
				revenues.push({
					name: 'Penalty Revenue',
					revenue: penaltyRevenue
				})
			}
			for (const against of currentResults.penalty_against) {
				notifications.push({
					messages: [`The Penalty Review Board that you requested has found violations at ${game[against.player]}.`, 
										`You collected ${formatCurrency(game.penalty)} in fines.`],
					isAlert: true,
				})
			}
		} else {
			// Financial Lender's revenue
			const loanRevenue = Math.round(currentResults.paid_back_loan * game.interest_rate_index)
			if (loanRevenue > 0) {
				revenues.push({
					name: 'Revenue',
					revenue: loanRevenue
				})
			}

			// Financial Lender's defaulted loan amount
			if (currentResults.defaulted_loan > 0) {
				costs.push({
					name: 'Defaulted Loan Amount',
					cost: currentResults.defaulted_loan,
				})
			}

			// Financial Lender's penalty
			if (currentResults.penalty_against.length) {
				costs.push({
					name: 'Penalty Fine',
					cost: game.penalty,
				})
				const penalty_against_cats = currentResults.penalty_against.map(i => allOptions.groupCategories[i].name)
				notifications.push({
					messages: [`${game.regulator} fined you ${formatCurrency(game.penalty)} for bias against ${joinByAnd(penalty_against_cats)}.`],
					isAlert: true,
				})
			}

			// Regulator's regulation
			const res_regulator_results = yield call(CMSService.getItemByQuery, {
				collection: 'game_actions',
				filter: [
					{ field: 'game', operator: 'eq', value: gameId },
					{ field: 'player', operator: 'eq', value: 'regulator' },
					{ field: 'round', operator: 'eq', value: round }
				]
			})

			const regulatorResults = res_regulator_results.data
			const rOPGs = regulatorResults.protected_groups.map(i => (allOptions.protectedGroups[i]))
			const rOBM = allOptions.biasMetrics[regulatorResults.bias_metric]
			const rLT = regulatorResults.legal_threshold || 0

			if (rOPGs.length && rOBM) {
				// const res_top_aggregate_bias = yield call(CMSService.getItemByQuery, {
				// 	collection: 'game_actions',
				// 	fields: 'aggregate_bias',
				// 	filter: [
				// 		{ field: 'round', operator: 'eq', value: currentResults.round }, 
				// 		{ field: 'player', operator: 'neq', value: PLAYER_ROLE.regulator },
				// 		{ field: 'is_submitted', operator: 'eq', value: 1 }, 
				// 		{ field: 'aggregate_bias', operator: 'gt', value: 0 }
				// 	],
				// 	sort: 'aggregate_bias'
				// })
				// topPerformer.aggregate_bias = res_top_aggregate_bias.data.aggregate_bias

				notifications.push({
					messages: [`${game.regulator} released regulations to enforce a +/-${numberToPercent(rLT)} 
					threshold for ${rOBM.name} for ${joinByAnd(rOPGs.map(i => i.name))}.`],
				})
			}

			// New York Times Alerts
			if (currentResults.news_alert) {
				const res_previous_new_alerts = yield call(CMSService.getItemsByQuery, {
					collection: 'game_actions',
					fields: 'id',
					filter: [
						{ field: 'game', operator: 'eq', value: gameId },
						{ field: 'player', operator: 'eq', value: player },
						{ field: 'round', operator: 'lt', value: round },
						{ field: 'news_alert', operator: 'eq', value: 1 }
					],
				})
				const previousNewAlerts = res_previous_new_alerts.data
				if (previousNewAlerts.length > 0 && previousNewAlerts.length < game.max_nb_of_news_alerts) {
					notifications.push({
						messages: [`News Alert: The New York Times did a follow up expose on bias at you. 
						Investigations into bias continue and may impact customer acquisition.`],
						isAlert: true,
						isNewsAlert: true,
					})
				} else if (previousNewAlerts.length === 0) {
					notifications.push({
						messages: [`News Alert: The New York Times did a story on bias found at your company. 
						This will impact customer acquisition.`],
						isAlert: true,
						isNewsAlert: true,
					})
				}
			}

			// Quarterly market report
			const lenders = _.filter(LENDERS, i => game[i])
			if (lenders.length > 1 && currentResults.round % 3 === 0) {
				const res_loan = yield call(CMSService.getItemsByQuery, {
					collection: 'game_actions',
					fields: 'player,nb_of_consumers_got_loan',
					filter: [
						{ field: 'game', operator: 'eq', value: gameId },
						{ field: 'player', operator: 'neq', value: PLAYER_ROLE.regulator },
						{ field: 'round', operator: 'in', value: [round - 2, round - 1, round].join(',') }
					]
				})
				currentResults.marketShare = _.chain(res_loan.data)
																			.groupBy('player')
																			.mapValues((val, key) => ({
																				name: game[key],
																				nbConsumersGotLoan: val.reduce((sum, o) => sum + o.nb_of_consumers_got_loan, 0)
																			}))
																			.sortBy('name')
																			.value()
				notifications.push({
					messages: [`Quarterly market report was published on the Financial Services Industry.`],
					isPDF: true,
					isMarketShare: true,
					title: 'Quarterly Marketshare Report'
				})
			}
		}

		currentResults.costs = costs
		currentResults.revenues = revenues
		currentResults.notifications = notifications

		const auditReport = isRegulator ? {
			protected_groups: currentResults.protected_groups.length ? [currentResults.protected_groups[0]] : [allOptions.groupWithCategoriesList[0].id],
			bias_metric: currentResults.bias_metric || allOptions.biasMetricsList[0].id,
		} : {}

		yield put(actions.getRoundResultsSuccess({ game, currentResults, topPerformer, allOptions, auditReport }))
	} catch (e) {
		yield put(actions.getRoundResultsFailure(e))
	}
}

function* getRoundResultsSaga() {
	yield takeLatest(types.GET_ROUND_RESULTS_REQUEST, getRoundResults)
}

function* startNextRound(action) {
	try {
		const { game, player, nonBankrupts } = action.value
		const round = game.current_round
		const nextRound = round + 1

		const res_joined = yield call(CMSService.getItemsByQuery, {
			collection: 'game_actions',
			fields: 'player,is_submitted',
			filter: [
				{ field: 'game', operator: 'eq', value: game.id },
				{ field: 'round', operator: 'eq', value: nextRound }
			],
		})
		const joinedPlayers = res_joined.data.map(i => i.player)

		if (!joinedPlayers.includes(player)) {
			const payload = {
				game: game.id,
				player,
				round: nextRound,
			}
			yield call(CMSService.createItem, 'game_actions', payload)
			joinedPlayers.push(player)

			// Set game's current_round to nextRound if all nonBankrupts players have joined next round
			if (joinedPlayers.length === nonBankrupts.length) {
				yield call(CMSService.updateItemById, 'games', game.id, { current_round: nextRound })
			}
		}

		yield call(SocketService.send, SOCKET_MESSAGE.PLAYER_HAS_JOINED, { game: game.id, player, round: nextRound })

		yield put(actions.startNextRoundSuccess({ joinedPlayers }))
	} catch (e) {
		yield put(actions.startNextRoundFailure(e))
	}
}

function* startNextRoundSaga() {
	yield takeLatest(types.START_NEXT_ROUND_REQUEST, startNextRound)
}

export default {
	getRoundResultsSaga,
	startNextRoundSaga,
}
