import { connect } from 'react-redux';
import { FinishComponent } from '../components';
import { finishActions, finishSelectors } from '../duck';
import { CalculateService } from 'app/common/services'

const mapStateToProps = (state, ownProps) => {
  const { gameId, player } = ownProps.match.params

  const { isLoading, isLoaded, currentTab, biasSummarySettings, winner, results, game, topPerformer, currentRoundResults, players, allOptions, biasSummary,
    consumerData, currentChartData } = state.finish

  const alertOptions = {
    biasWithoutGroup: { 
      title: 'Not Allowed', 
      content: 'You can only apply a bias metric if you have chosen a group to protect.' 
    },
  }

  return {
    role: CalculateService.getRole(player),
    isLoading,
    isLoaded,
    currentTab,
    biasSummarySettings,
    winner,
    results: results.map(i => ({...i, breakdowns: i.breakdowns[currentTab]})),
    game,
    topPerformer: topPerformer[currentTab],
    biasSummary: biasSummary[currentTab][biasSummarySettings.biasMetric],
    currentRoundResults,
    players,
    allOptions,
    consumerData,
    currentChartData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { gameId, player } = ownProps.match.params

  const getGameSummaryRequest = () => {
    dispatch(finishActions.getGameSummaryRequest({ gameId }))
  }

  const changeBiasSummaryState = (payload) => {
    dispatch(finishActions.summaryChangeBiasSummaryState(payload))
  }

  const updateCurrentRoundResults = (payload) => {
    dispatch(finishActions.updateCurrentRoundResults(payload))
  }

  const updateCurrentChartData = (payload) => {
    dispatch(finishActions.updateCurrentChartData(payload))
  }

  const switchTab = (tab) => {
    dispatch(finishActions.switchTab(tab))
  }

  const switchBiasSummary = (settings) => {
    dispatch(finishActions.switchBiasSummary(settings))
  }

  return {
    getGameSummaryRequest,
    changeBiasSummaryState,
    updateCurrentRoundResults,
    updateCurrentChartData,
    switchTab,
    switchBiasSummary,
  }
};

const FinishContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinishComponent);

export default FinishContainer;
