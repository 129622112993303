import React from 'react';
import { Button, Tooltip } from 'appkit-react';
import { Link } from "react-router-dom";
import * as RouteName from 'app/routes/route-name'
import { UnderLabelledNumberInput }  from 'app/common/components'
import { range, lodash2space, formatCurrency } from 'app/common/utils'
import moment from 'moment'
import { CURRENCY, UNITS } from 'app/common/constants'
import { CalculateService } from 'app/common/services'
import AdjustThresholds from './adjust-thresholds'

const SetMetrics = ({ game, player, role, currentRoundActions, allOptions, alertOptions, popup,
                      updateRoundActions, showAlert, showPopup, hidePopup }) => {
	const descOption = {
		regulator: {
			group: `You can select up to 2 protected groups to legally protect against bias in the financial loan process.
					Protected groups are listed below with the associated group categories that would be protected.`,
			bias: `You can select up to 1 metric that is used to regulate bias in the financial loan approval process.
					You can set a Legal Threshold of allowed variance in metric level for each protected group when compared to the whole group.`
		},
		lender: {
			group: `You can select up to 1 group to protect against bias in their loan approval algorithm. Selecting a particular
					group will allow you to modify loan approval thresholds for individuals in that group in order to increase approval
					rates. Protected groups are listed below with the associated group categories that would be considered.`,
			bias: `You can select one metric to evaluate bias in their loan approval algorithm. Explore below to understand
					the definitions of the metrics and use the info buttons to see examples. Click "ADJUST THRESHOLDS" to further adjust model
					decision making thresholds for selected protected groups. Before making final determinations, you could use the
					Data Scientist page to analyze the implications of different choices.`
		}
	}
	const desc = role.isRegulator ? descOption.regulator : descOption.lender

  const pgs = currentRoundActions.protected_groups
  const bm = currentRoundActions.bias_metric
  const lt = currentRoundActions.legal_threshold
  const att = currentRoundActions.adjusted_threholds_temp
  const at = currentRoundActions.adjusted_threholds

  const oPG = pgs[0] ? allOptions.groupCategories.find(group => pgs[0] === group.id) : null
  const oBM = bm ? allOptions.biasMetrics.find(bias => bm === bias.id) : null

  const legalThresholdConf = {
    name: 'legal_threshold',
    title: 'Legal Disparity Threshold',
    min: 0,
    max: 100,
    step: 1,
    unit: '%',
    integerOnly: true,
    value: parseInt(lt * 100),
  }

  const addPG = (groupId) => {
    const payload = {
      protected_groups: pgs.concat(groupId).sort(),
    }

    updateRoundActions(payload)
  }

  const delPG = (groupId) => {
    const payload = {
      protected_groups: pgs.filter(i => i != groupId),
    }
    if (bm && payload.protected_groups.length < 1) {
      // showAlert(alertOptions.biasWithoutGroup)
    } else {
      updateRoundActions(payload)
    }
  }

  const addBM = (biasId) => {
    if (pgs.length < 1) {
      // showAlert(alertOptions.biasWithoutGroup)
    } else {
      const payload = {
        bias_metric: biasId,
        legal_threshold: 0,
        adjusted_threholds_temp: {},
        adjusted_threholds: {},
      }
      updateRoundActions(payload)
    }
  }

  const delBM = () => {
    const payload = {
      bias_metric: null,
      legal_threshold: 0,
      adjusted_threholds_temp: {},
      adjusted_threholds: {},
    }

    updateRoundActions(payload)
  }

  const updateLT = ({key, val}) => {
    const payload = {
      legal_threshold: val / 100,
    }

    updateRoundActions(payload)
  }

  return (
    <div className='set-metrics-zone'>
    	<div className='group-zone'>
    		<div className='title-row'>
    			<span>Select A Group to Protect</span>
    		</div>
    		<div className='desc-row'>
    			<span>{desc.group}</span>
    		</div>
    		<div className='group-items'>
    			{allOptions.groupCategories.map((group, index) => (
    				<div key={index} className='group-item'>
    					{pgs.includes(group.id) ? (
                <Button 
                    className='group-selector'
                    onClick={() => delPG(group.id)}>
                  <span className='appkiticon icon-check-mark-outline a-text-white'></span>{group.name.toUpperCase()}
                </Button>
              ) : (
                <Button 
                    kind='secondary'
                    className='group-selector'
                    disabled={pgs.length === role.pgsMax}
                    onClick={() => addPG(group.id)}>
                  {group.name.toUpperCase()}
                </Button>
              )}
              <div className='category-items'>
	              {group.categories.map((cat, j) => (
	              	<span key={j} className='category-item'>{cat.name}</span>
	              ))}
              </div>
    				</div>
    			))}
    		</div>
    	</div>

    	<div className='bias-zone'>
    		<div className='title-row'>
    			<span>Select A Bias Metric</span>
    			{!role.isRegulator && (
    				<Button disabled={!(pgs[0] && bm)} kind='transparent' onClick={() => showPopup('adjustThresholds')}>ADJUST THRESHOLDS</Button>
    			)}
    		</div>
    		<div className='desc-row'>
    			<span>{desc.bias}</span>
    		</div>
    		<div className='bias-items'>
    			{allOptions.biasMetrics.map((bias, index) => (
    				<div key={index} className='bias-item'>
    					<div className='bias-info'>
	    					<div>
	    						<span className='bias-name'>{bias.name}</span>
	    						<Tooltip
	    								trigger='click' 
	    								content={bias.more_context} 
	    								placement="left"
	    								tooltipTheme='light'
	    								style={{width: 321}}>
	    							<span className='appkiticon icon-information-fill bias-tooltip-icon'></span>
	    						</Tooltip>
	    					</div>
	    					<div>
	    						<span className='bias-definition'>{bias.definition}</span>
	    					</div>
    					</div>
              <div className='bias-select'>
                {bm === bias.id ? (
                  <Button 
                      className='bias-selector bias-active'
                      onClick={() => delBM()}>
                    <span className='appkiticon icon-check-mark-outline a-text-white'></span>SELECTED
                  </Button>
                ) : (
                  <>
                    {pgs.length < 1 ? (
                      <Tooltip
                          trigger='hover' 
                          content={'You must select a group to protect.'} 
                          placement="right"
                          tooltipTheme='light'
                          style={{width: 321}}>
                      <Button 
                          kind='secondary'
                          className='bias-selector'
                          disabled={pgs.length < 1}
                          onClick={() => addBM(bias.id)}>
                        <span className='appkiticon icon-plus-outline a-text-gray'></span>SELECT
                      </Button>
                    </Tooltip>
                    ) : (
                      <Button 
                          kind='secondary'
                          className='bias-selector'
                          onClick={() => addBM(bias.id)}>
                        <span className='appkiticon icon-plus-outline a-text-orange'></span>SELECT
                      </Button>
                    )}
                  </>
                )}

                {role.isRegulator && bm === bias.id && (
                  <UnderLabelledNumberInput obj={legalThresholdConf} disabled={false} changeSettings={updateLT} />
                )}
              </div>
    				</div>
    			))}
    		</div>
    	</div>
      {!role.isRegulator && popup.adjustThresholds.showing && (
        <AdjustThresholds visible={popup.adjustThresholds.showing} oPG={oPG} oBM={oBM}
          legalThresholdConf={legalThresholdConf} defaultThresholds={allOptions.thresholds[bm]} 
          adjustedThresholdsTemp={att} adjustedThresholds={at}
          updateRoundActions={updateRoundActions} hidePopup={hidePopup} isModal={true}/>
      )}
    </div>
  )
};

export default SetMetrics;
