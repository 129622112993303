//New
const home = '/'
const completed_games = '/completed_games'
const new_game = '/new_game'
const choose_player = '/choose_player'
const gameId = '/:gameId'
const player = '/:player'
const about = '/about'
const play = '/play'
const result = '/result'
const finish = '/finish'

//Old
const game = '/game'
const regulation = '/regulation'
const submitted = '/submitted'
const results = '/results'
const summary = '/summary'
const create_game = '/create_game'
const lobby = '/lobby'
const gameKey = '/:gameKey'
const player_select = '/player_select'
// const player = '/:player'

export {
	home,
	completed_games,
	new_game,
	choose_player,
	gameId,
	player,
	about,
	play,
	result,
	finish,
	//Old
	create_game,
	game,
	regulation,
	submitted,
	results,
	summary,
	lobby,
	gameKey,
	player_select,
	// player,
}
