import React from 'react';
import { Button } from 'appkit-react';
import { range, formatCurrency } from 'app/common/utils'
import { PLAYER_ICON } from 'app/common/constants'
import { Images } from 'app/assets'

const UserCard = ({ 
	game, 
	player, 
	role, 
	currentResults,
	allOptions,
}) => {
	const oPGs = currentResults.protected_groups.map(i => (allOptions.protectedGroups[i]))
	const oBM = allOptions.biasMetrics[currentResults.bias_metric]
	const oActions = currentResults.actions.map(i => ({ ...allOptions.actions[i.id], ...i }))

  return (
  	<div className='user-card'>
  		<div className='user-info'>
	      <div className='title'>
		      <img src={PLAYER_ICON[role.name].grey} />
		      <span>{game[player]}</span>
	      </div>
	      <div className='prop-row'>
	      	<span>{role.title}</span>
	      </div>
	      <div className='prop-row'>
		      <span>Wealth: </span>
		      <span>{formatCurrency(currentResults.wealth)}</span>
	      </div>
	      <div className='prop-row'>
	      	<span>CW: </span>
	      	<span>{currentResults.consumer_welfare}</span>
	      </div>
	    </div>

	    {(oPGs[0] || oBM || oActions[0]) && (
	    	<div className='user-selection'>
		      <div className='title'>
			      <span>My Selections</span>
		      </div>

		      {oPGs.map((i, index) => (
		      	<span key={index} className='selection-item'>{i.name}</span>
		      ))}

		      {oBM && (
		      	<span className='selection-item'>{oBM.name}</span>
		      )}

					{oActions.map((i, index) => (
						<div key={index}>
							<span className='selection-item'>{i.name}</span>
							<span className='selection-item-next'>{'Cost ' + formatCurrency(i.cost)}</span>
						</div>
					))}
		    </div>
	    )}
  	</div>
  )
};

export default UserCard;
