import React, { useEffect } from 'react';

import { Loading } from 'app/common/components'
import NewGame from './new-game'
import CompletedGames from './completed-games'
import ActiveGames from './active-games'

import '../styles/index.scss'

function HomeComponent({
  isLoading, error, activeGames,
  getActiveGamesRequest,
  push,
}) {
  useEffect(() => {
    getActiveGamesRequest()
  }, [])

  return (
    <>
      {isLoading ? <Loading /> : (
        <div className='home-page'>
          <div>
            <NewGame />
            <CompletedGames />
          </div>
          <ActiveGames push={push} activeGames={activeGames} />
        </div>
      )}
    </>
  )
}

export default HomeComponent;
