import { connect } from 'react-redux';
import { ChoosePlayerComponent } from '../components';
import { homeActions } from '../duck';

const mapStateToProps = state => {
  const { isLoading, error, chosenGame, chosenPlayer } = state.home

  return {
    isLoading,
    error,
    chosenGame,
    chosenPlayer,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { gameId } = ownProps.match.params

  return {
    getGamePlayersRequest: () => {
      dispatch(homeActions.getGamePlayersRequest(gameId))
    },
    choosePlayer: (player) => {
      dispatch(homeActions.choosePlayer(player))
    },
  }
};

const ChoosePlayerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChoosePlayerComponent);

export default ChoosePlayerContainer;
