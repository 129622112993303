import React from 'react';
import { FOOTER_TEXT } from 'app/common/constants'

const Footer = () => {
	return (
		<footer className='footer'>
    	<span>{FOOTER_TEXT}</span>
    </footer>
	)
}

export default Footer;

// import { Link } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.css';
// import 'appkit-react/style/appkit-react.default.css';

// import GameTabbar from './game-tabbar';
// import * as RouteName from 'app/routes/route-name';
// import { CURRENCY } from 'app/common/constants'
// import * as Utils from 'app/common/utils'
// import { Colors, Typography } from 'app/styles';

// const Footer = ({
// 	isLanding, playerInfo, isCreateGame, isPlayerSelect, isLobby,
// 	tabs, currentTab, switchToTab, isRegulation, isSubmitted, gameRoundDetails={},
// 	createGameSettings, createGameRequest,
// 	checkPlayerNameUniqueness
// }) => {

// 	const centerDom = (isLanding || isCreateGame || isLobby || isPlayerSelect || isSubmitted || !tabs) ? (<span></span>) : (<GameTabbar tabs={tabs} currentTab={currentTab} switchToTab={switchToTab} />)
// 	let rightDom = (<span></span>)

// 	if (isLanding) {
// 		rightDom = (
// 			<Link to={RouteName.create_game}>
//       	<span className='appkiticon icon-play-fill a-font-45 a-text-dark rai-button-icon'></span>
//       </Link>
//     )
// 	} else if (isRegulation) {
// 		rightDom = (
// 			<div style={theStyle.regulation}>
// 				<b>{playerInfo.name}</b>
// 				<span><b>Wealth:</b> {Utils.currencyWithCommas(gameRoundDetails.wealth, CURRENCY.dollar)}</span>
// 				<span><b>CW:</b> {gameRoundDetails.consumer_welfare}</span>
// 			</div>
// 		)
// 	} else if (isCreateGame) {
// 		const lenderCount = createGameSettings.financialLenders.value.length
// 		if (lenderCount === 0) {
// 			rightDom = (<div></div>)
// 		} else {
// 			rightDom = (
// 				<div onClick = {() => {
// 					let playerNameValues = [...createGameSettings.financialLenders.value]
// 					playerNameValues.unshift(createGameSettings.regulator.value)

// 					if ((new Set(playerNameValues)).size !== playerNameValues.length) {
// 						checkPlayerNameUniqueness('NOT_UNIQUE')
// 					} else {
// 						checkPlayerNameUniqueness('UNIQUE')
// 						var payload
// 						switch (lenderCount) {
// 							case 1:
// 							payload = {
// 								name: createGameSettings.game_name.value,
// 								regulator: playerNameValues[0],
// 								financial_lender_1: playerNameValues[1],
// 							};
// 							case 2:
// 							payload = {
// 								name: createGameSettings.game_name.value,
// 								regulator: playerNameValues[0],
// 								financial_lender_1: playerNameValues[1],
// 								financial_lender_2: playerNameValues[2],
// 							};
// 							case 3:
// 							payload = {
// 								name: createGameSettings.game_name.value,
// 								regulator: playerNameValues[0],
// 								financial_lender_1: playerNameValues[1],
// 								financial_lender_2: playerNameValues[2],
// 								financial_lender_3: playerNameValues[3],
// 							};
// 						}
// 				  	createGameRequest(payload);
// 				 }
// 			 }
// 			 }>
// 			 <div>
// 			 	<span className='appkiticon icon-play-fill a-font-45 a-text-dark rai-button-icon'></span>
// 			 </div>
// 	     </div>
// 	    )
// 		}
//  }
// 	return (
// 		<div className="a-footer" style={theStyle.container}>
//       <div className="a-pwc-logo a-md"></div>
//       {centerDom}
//       {rightDom}
//     </div>
// 	)
// };

// export default Footer;

// const theStyle = {
// 	container: {
// 		flex: 'none',
// 		display: 'flex',
// 		justifyContent: 'space-between',
// 		alignItems: 'center',
// 		padding: '0 5px',
// 		color: Colors.BLACK,
// 		borderTop: `1px solid ${Colors.GREY_LIGHTER}`
// 	},
// 	regulation: {
// 		paddingRight: '4rem',
// 		display: 'flex',
// 		flexDirection: 'column',
// 		fontSize: Typography.FONT_SIZE_12,
// 	}
// }
