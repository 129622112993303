import React from 'react';
import { Button, Panel, RadioGroup, Radio } from 'appkit-react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { formatNumber } from 'app/common/utils'

const BarChart = ({ categories, chartData, hidePopup }) => {
  var chartOptions = [];

  
  categories.forEach((item, index) => {
    chartOptions.push({
      chart: {
        type: 'bar',
        height: '110px',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Helvetica Neue',
        }
      },
      colors: ['#4DACF1', '#003DAB'],
      title: {
        align: 'left',
        text: item.name,
        margin: 32,
        style: {
          fontSize: '16px',
        }
      },
      legend: {
        reversed: true,
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        visible: false
      },
      yAxis: {
        visible: false,
        reversedStacks: false
      },
      plotOptions: {
        series: {
          animation: false,
          stacking: 'percent'
        }
      },
      tooltip: {
        enabled: false
      },
      series: [
        {
          name: chartData.loansApproved.name,
          pointWidth: 20,
          dataLabels: {
            align: 'left',
            enabled: true,
            crop: false,
            overflow: 'none',
            formatter: function() {
              return formatNumber(this.y)
            },
            x: -5,
            y: -30,
            style: {
              fontSize: '20px',
              color: 'black',
              textOutline: 'none',
            }
          },
          data: [chartData.loansApproved.data[item.id]]
        },
        { 
          name: chartData.loansDenied.name,
          pointWidth: 20,
          dataLabels: {
            align: 'right',
            enabled: true,
            crop: false,
            overflow: 'none',
            formatter: function() {
              return formatNumber(this.y)
            },
            x: 5,
            y: -30,
            style: {
              fontSize: '20px',
              color: 'black',
              textOutline: 'none'
            }
          },
          data: [chartData.loansDenied.data[item.id]]
        }
      ]
    })
  })


  const chartOption = {
    chart: {
      type: 'bar',
      height: '110px',
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Helvetica Neue',
      }
    },
    colors: ['#4DACF1', '#003DAB'],
    title: {
      align: 'left',
      text: 'Female',
      margin: 32,
      style: {
        fontSize: '16px',
      }
    },
    legend: {
      reversed: true,
      enabled: false
    },
    credits: {
      enabled: false
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false,
      reversedStacks: false
    },
    plotOptions: {
      series: {
        stacking: 'percent'
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        name: 'Loans Approved',
        pointWidth: 20,
        dataLabels: {
          align: 'left',
          enabled: true,
          crop: false,
          overflow: 'none',
          formatter: function() {
            return formatNumber(this.y)
          },
          x: -5,
          y: -30,
          style: {
            fontSize: '20px',
            color: 'black',
            textOutline: 'none',
          }
        },
        data: [1100]
      },
      { 
        name: 'Loans Denied',
        pointWidth: 20,
        dataLabels: {
          align: 'right',
          enabled: true,
          crop: false,
          overflow: 'none',
          formatter: function() {
            return formatNumber(this.y)
          },
          x: 5,
          y: -30,
          style: {
            fontSize: '20px',
            color: 'black',
            textOutline: 'none'
          }
        },
        data: [326]
      }
    ]
  }

  return(
    <div className='bar-chart-container'>
      { categories.map((item, index) => {
          return (
            <div key={index} className='bar-chart'>
              <HighchartsReact highcharts={Highcharts} options={chartOptions[index]}/>
              <div className='bar-chart-legend'>
                <span>{chartOptions[index].series[0].name}</span>
                <span>{chartOptions[index].series[1].name}</span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default BarChart;