// types
const FINISH_GET_GAME_SUMMARY_REQUEST = 'FINISH_GET_GAME_SUMMARY_REQUEST'
const FINISH_GET_GAME_SUMMARY_SUCCESS = 'FINISH_GET_GAME_SUMMARY_SUCCESS'
const FINISH_GET_GAME_SUMMARY_FAILURE = 'FINISH_GET_GAME_SUMMARY_FAILURE'

const SUMMARY_CHANGE_BIAS_SUMMARY_STATE = 'SUMMARY_CHANGE_BIAS_SUMMARY_STATE'
const UPDATE_CURRENT_ROUND_RESULTS = 'UPDATE_CURRENT_ROUND_RESULTS'
const UPDATE_CURRENT_CHART_DATA = 'UPDATE_CURRENT_CHART_DATA'

const FINISH_SWITCH_TAB = 'FINISH_SWITCH_TAB'
const FINISH_SWITCH_BIAS_SUMMARY = 'FINISH_SWITCH_BIAS_SUMMARY'

export default {
	FINISH_GET_GAME_SUMMARY_REQUEST,
	FINISH_GET_GAME_SUMMARY_SUCCESS,
	FINISH_GET_GAME_SUMMARY_FAILURE,
	SUMMARY_CHANGE_BIAS_SUMMARY_STATE,
	UPDATE_CURRENT_ROUND_RESULTS,
	UPDATE_CURRENT_CHART_DATA,
	FINISH_SWITCH_TAB,
	FINISH_SWITCH_BIAS_SUMMARY,
}
