import React from 'react';
import { Button, Avatar, Tooltip } from 'appkit-react';
import { ACTIONS } from 'app/common/constants'
import { formatCurrency, number2Percent } from 'app/common/utils';
import { select } from 'redux-saga/effects';
import AdjustThresholds from './adjust-thresholds'
import BarChart from './bar-chart'
import BarChartGroup from './bar-chart-group'
import { CalculateService } from 'app/common/services'
import ViewImpactOverlay from './view-impact-overlay'
import _ from 'lodash'

const ViewImpactSelection = ({disabled=false, title, items, selectedItem, add, del}) => {
  return (
    <div className='view-impact-selection-zone'>
      <span>{title}</span>
      <div>
        <span>Current Selection: </span>
        {selectedItem && (
          <span>{items.find(i => i.id === selectedItem).name}</span>
        )}
      </div>
      <div className='view-impact-selections'>
        {items.map((i, index) => (
          <React.Fragment key={index}>
            {i.id === selectedItem ? (
              <Button className='view-impact-selection-selected-button' onClick={del}>
                <span className="appkiticon icon-check-mark-fill"></span>
                {i.name.toUpperCase()}
              </Button>
            ) : (
              <>
                {disabled ? (
                  <Tooltip
                      trigger='hover' 
                      content={'You must select a group to protect.'} 
                      placement="right"
                      tooltipTheme='light'
                      style={{width: 321}}>
                    <Button kind='secondary' className='view-impact-selection-unselected-button' disabled={disabled} onClick={() => add(i.id)}>
                      {i.name.toUpperCase()}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button kind='secondary' className='view-impact-selection-unselected-button' onClick={() => add(i.id)}>
                    {i.name.toUpperCase()}
                  </Button>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

const ViewImpact = ({
  game, allOptions, currentRoundActions, updateRoundActions, dataScientist, updateDataScientist, hidePopup
}) => {
  const purchaseAccess = () => {
    updateRoundActions({
      use_data_scientist: true,
      profit: currentRoundActions.profit - ACTIONS.dataScientist.cost,
      wealth: currentRoundActions.wealth - ACTIONS.dataScientist.cost,
    })
  }

  const desc = {
    title: 'Experiment with different bias metrics',
    thresholds: {
      description: `These selections and/or threshold differ from your current selections.`,
      question: 'Would you like to update?'
    }
  };

  const model = currentRoundActions.model
  const pg = dataScientist.protected_groups[0]
  const bm = dataScientist.bias_metric
  const lt = dataScientist.legal_threshold
  const at = dataScientist.adjusted_threholds
  const oPG = allOptions.groupCategories.find(i => i.id === pg)
  const oBM = allOptions.biasMetrics.find(i => i.id === bm)

  const isDifferent = pg !== currentRoundActions.protected_groups[0] 
                      || bm !== currentRoundActions.bias_metric
                      || !_.isEqual(at, currentRoundActions.adjusted_threholds)

  const legalThresholdConf = {
    name: 'legal_threshold',
    title: 'Legal Disparity Threshold',
    min: 0,
    max: 100,
    step: 1,
    unit: '%',
    integerOnly: true,
    value: lt * 100,
  }

  const addPG = (id) => {
    const payload = {
      protected_groups: [id],
      adjusted_threholds: {},
    }
    updateDataScientist(payload)
  }

  const delPG = () => {
    if (bm) return
    const payload = {
      protected_groups: [],
      adjusted_threholds: {},
    }
    updateDataScientist(payload)
  }

  const addBM = (id) => {
    if (!pg) return
    const payload = {
      bias_metric: id,
      adjusted_threholds: {},
    }
    updateDataScientist(payload)
  }

  const delBM = () => {
    const payload = {
      bias_metric: null,
      adjusted_threholds: {},
    }
    updateDataScientist(payload)
  }

  const applyDataScientist = () => {
    const payload = {
      ...dataScientist,
      adjusted_threholds_temp: {
        ...dataScientist.adjusted_threholds
      }
    }
    updateRoundActions(payload)
  }

  const thresholds = pg && bm
                      ? { ...allOptions.thresholds[bm], ...dataScientist.adjusted_threholds }
                      : game.default_baseline_modal_thresholds
  const consumerData = _.map(allOptions.consumerData, consumer => {
    const got_loan = allOptions.realConsumers.includes(consumer.id) && CalculateService.gotLoan(consumer, model, oPG, thresholds)
    return {
      got_loan,
      paid_back: consumer.paid_back,
      credit_amount: consumer.credit_amount,
      sex: consumer.sex,
      age: consumer.age,
      employment: consumer.employment,
      housing: consumer.housing,
      citizenship_status: consumer.citizenship_status,
      marital_status: consumer.marital_status,
    }
  })

  const chartData = {
    accuracy: CalculateService.getAccuracy(consumerData),
    profit: CalculateService.getLoanProfit(consumerData, game),
    loansApprovedAndDenied:  {
      loansApproved: {
        name: 'Loans Approved',
        data: CalculateService.getLoansApprovedByCategory(consumerData, oPG)
      },
      loansDenied: {
        name: 'Loans Denied',
        data: CalculateService.getLoansDeniedByCategory(consumerData, oPG)
      }
    },
    bm: {
      name: 'haha',
      data: CalculateService.getBM(consumerData, oPG, oBM)
    }
  }

  const categories = oPG ? oPG.categories: [{name: 'Overall', id: 'total'}]

  return (
    <div className='view-impact-zone'>
      <span>{desc.title}</span>
      <div className='view-impact-bias-metrics-container'>
        <div className='view-impact-bias-metrics-col view-impact-for-group'>
          <ViewImpactSelection title='Protected Group' items={allOptions.groupCategories} selectedItem={pg} add={addPG} del={delPG} />
        </div>
        <div className='view-impact-bias-metrics-col view-impact-for-bias'>
          <ViewImpactSelection disabled={!pg} title='Bias Metric' items={allOptions.biasMetrics} selectedItem={bm} add={addBM} del={delBM} />
        </div>
      </div>
      <div className='view-impact-thresholds'>
        <div className='view-impact-thresholds-title'>
          {oPG && oBM && (
            <>
              <span className='view-impact-thresholds-title-marked'>{oPG.name}</span>
              <span> & </span>
              <span className='view-impact-thresholds-title-marked'>{oBM.name}</span>
              <span> are Selected</span>
            </>
          )}
          {oPG && !oBM && (
            <>
              <span className='view-impact-thresholds-title-marked'>{oPG.name}</span>
              <span> is Selected</span>
            </>
          )}
          {!oPG && !oBM && (
            <span>Nothing is Selected</span>
          )}
        </div>
        {pg && bm && (
          <>
            <div className='view-impact-thresholds-slider'>
              <AdjustThresholds oPG={oPG} oBM={oBM}
                legalThresholdConf={legalThresholdConf} defaultThresholds={allOptions.thresholds[bm]} 
                adjustedThresholds={at}
                updateRoundActions={updateDataScientist} isModal={false}/>
            </div>
            {isDifferent && (
              <div className='view-impact-thresholds-action'>
                <div>
                  <p>{desc.thresholds.description}</p>
                  <p>{desc.thresholds.question}</p>
                </div>
                <Button className='view-impact-thresholds-action-update-button' onClick={applyDataScientist}>USE THESE SELECTIONS</Button>
              </div>
            )}
          </>
        )}
      </div>

      <div className='view-impact-bar-chart'>
        <span className='view-impact-bar-chart-title'>Number of Loans Approved and Declined</span>
        <BarChart 
          currentRoundActions={currentRoundActions}
          hidePopup={hidePopup}
          categories={categories}
          chartData={chartData.loansApprovedAndDenied}/>
      </div>

      <div className='view-impact-bar-chart-group'>
        <div className='view-impact-bar-chart-group-container'>
          <div>
            {oPG && oBM && (
              <>
                <span className='view-impact-bar-chart-group-title'>{oBM.name} Percentages</span>
                <BarChartGroup
                  currentRoundActions={currentRoundActions}
                  hidePopup={ hidePopup }
                  categories={categories}
                  chartData={chartData.bm}/>
                <div className='view-impact-average'>
                  <div></div>
                  <span>Average Population {number2Percent(chartData.bm.data.bmr)}</span>
                </div>
              </>
            )}
          </div>
          <div>
            <span className='view-impact-bar-chart-group-title'>Profit</span>
            <span className='view-impact-bar-chart-group-profit'>{formatCurrency(chartData.profit)}</span>
            <span className='view-impact-bar-chart-group-increase'>{number2Percent(chartData.accuracy)} increase</span>
          </div>
        </div>
      </div>

      {!currentRoundActions.use_data_scientist && (
        <ViewImpactOverlay currentRoundActions={currentRoundActions} purchaseAccess={purchaseAccess} />
      )}
    </div>
  )

}

export default ViewImpact;