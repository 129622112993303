import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge, Button } from 'appkit-react'
import { Colors, Typography } from 'app/styles'

const Info = ({ visible, title, content, handleCancel, className }) => {
	return (
		<Modal
      className={"info-modal " + className}
      visible={visible}
      backdropClosable={false}
      onCancel={handleCancel}
    >
      <ModalHeader> 
      	<Badge type={"info"}>INFO</Badge> 
      	<div className="modal-title">{title}</div>
     	</ModalHeader>

      <ModalBody>
          <span>{content}</span>
      </ModalBody>
    </Modal>
  )
};

export default Info
