import React, { useEffect } from 'react';
import { Button, Table } from 'appkit-react';
import { Link } from "react-router-dom";
import { Loading, Confirm } from 'app/common/components'
import * as RouteName from 'app/routes/route-name'
import { range } from 'app/common/utils'
import moment from 'moment'
import { MOMENT_FORMAT, PLAYER_ROLE_MAP, SLASH } from 'app/common/constants'
import { Images } from 'app/assets'

const CompletedGamesComponent = ({ 
  isLoading, error, confirm, completedGames,
  getCompletedGamesRequest, deleteGameRequest, push, toggleConfirm,
}) => {
  useEffect(() => {
    getCompletedGamesRequest()
  }, [])

	const columns = [
    {
      Header: '',
      accessor: 'name',
      Cell: row => (
			  <div className='a-col-align-start'>
			  	<span className='game-name'>{row.original.name}</span>
			  	<span className='game-completed_at'>
			  		{moment(row.original.completed_at, MOMENT_FORMAT.DateTime.standard).format(MOMENT_FORMAT.DateTime.display)}
			  	</span>
			  </div>
	    )
    },
    {
      Header: '',
      accessor: 'players_count',
      Cell: row => (
      	<div className='a-col-align-start'>
      		<div>
      			{row.original.joined_count > 0 && range(1, row.original.joined_count).map(i => (
      				<img key={i} className='player-icon' src={Images.player_active} />
      			))}
      			{row.original.players_count - row.original.joined_count > 0 
      					&& range(1, row.original.players_count - row.original.joined_count).map(i => (
      				<img key={i} className='player-icon' src={Images.player} />
      			))}
      		</div>
      		<span className='game-players'>Players {row.original.joined_count}/{row.original.players_count}</span>
      	</div>
      )
    },
    {
      Header: '',
      accessor: 'final_outcome',
      Cell: row => (
        <span className='game-final_outcome'>{row.original.final_outcome}</span>
      )
    },
    {
      Header: '',
      accessor: 'is_top_performer',
      Cell: row => (
        <>
          {row.original.is_top_performer && (
            <span className="appkiticon icon-rating-fill"></span>
          )}
        </>
      )
    },
    {
      Header: '',
      accessor: 'id',
      Cell: row => (
      	<span className='join-game-icon appkiticon icon-right-chevron-outline a-text-orange' 
              onClick={() => push(row.original.id.toString().concat(SLASH, PLAYER_ROLE_MAP.regulator.name, RouteName.finish))}></span>
      )
    },
    {
      Header: '',
      accessor: 'id',
      Cell: row => (
        <span className='join-game-icon appkiticon icon-delete-outline a-text-orange'
              onClick={() => toggleConfirm({
                title: `Are you sure`,
                content: `Are you sure you'd like to delete the Game ${row.original.name}?`,
                handleCancel: () => toggleConfirm(),
                handleConfirm: () => deleteGameRequest(row.original.id),
              })}></span>
      )
    }
  ];

	return (
    <>
      <div className='completed-games-page'>
        <Link to={RouteName.home}>
          <span className='back-icon appkiticon icon-left-chevron-outline a-text-orange'></span>
          <span>BACK TO HOME PAGE</span>
        </Link>
        <div>
          <span className='page-title'>Completed Games</span>
          <Link to={RouteName.new_game}>
            <Button>NEW GAME</Button>
          </Link>
        </div>
        <div>
          <span>View a summary of any completed games.</span>
          <div>
            <span className="appkiticon icon-rating-fill"></span>
            <span>All Time Best Performer</span>
          </div>
        </div>
        <Table
            data={completedGames}
            columns={columns}
            defaultPageSize={10}
          />
      </div>
      {isLoading && (
        <Loading />
      )}
      {confirm && (
        <Confirm visible={!!confirm} confirm={confirm} />

      )}
    </>
  )
};

export default CompletedGamesComponent;
