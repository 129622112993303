import { connect } from 'react-redux';
import { AboutComponent } from '../components';
import { aboutActions } from '../duck';
import { CalculateService } from 'app/common/services'
import { push } from 'connected-react-router'

const mapStateToProps = (state, ownProps) => {
  const { player } = ownProps.match.params

  const { isLoading, isLoaded, isProcessing, isCurrentRoundFinished, error, game, editMode, currentRoundPlayers, joinedPlayers, allOptions } = state.about

  return {
    isLoading,
    isLoaded,
    isProcessing,
    isCurrentRoundFinished,
    error,
    game,
    editMode,
    isRegulator: CalculateService.isRegulator(player),
    isGameStarted: CalculateService.isGameStarted(game),
    currentRoundPlayers,
    joinedPlayers,
    isAllJoined: joinedPlayers.length > 0 && joinedPlayers.length === currentRoundPlayers.length,
    allOptions,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { gameId, player } = ownProps.match.params

  return {
    push: (path) => {
      dispatch(push(path))
    },
  	getGameAboutRequest: () => {
  		dispatch(aboutActions.getGameAboutRequest({ gameId, player }))
  	},
    updateGameSettingsRequest: ({key, val}) => {
      dispatch(aboutActions.updateGameSettingsRequest({ gameId, payload: {[key]: val} }))
    },
    toggleEditMode: () => {
      dispatch(aboutActions.toggleEditMode())
    },
    launchGameRequest: (game) => {
      dispatch(aboutActions.launchGameRequest({ game, player }))
    },
    playerHasJoined: (value) => {
      dispatch(aboutActions.playerHasJoined(value))
    },
  }
};

const AboutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutComponent);

export default AboutContainer;
