import React from 'react';
import { Button } from 'appkit-react';
import { number2Percent } from 'app/common/utils'
import { Images } from 'app/assets'
import _ from 'lodash'

const CW = ({ winner, game, player, results, topPerformer }) => {
  const title = 'Loans Successfully Approved'
  const bestVal = _.chain(results).filter(i => i.breakdowns).map(i => i.breakdowns.consumer_welfare).max().value()
  const isBest = playerItem => playerItem.breakdowns && playerItem.breakdowns.consumer_welfare === bestVal
  const isBankrupt = playerItem => !playerItem.breakdowns
  const isWinner = playerItem => winner && winner.indexOf(playerItem.player) > -1

  return (
    <div className='finish-cw'>
      <div>
        <span className="title">{title}</span>
      </div>
    	<div className='performers'>
        {results.map((i, index) => (
          <div key={index}>
            <div className={'performer-card' + (isBankrupt(i) ? ' error-performer' : '')}>
              <div>
                {isWinner(i) && (<img className='trophy' src={Images.trophy_blue} />)}
                <span>{`${game[i.player]}${player === i.player ? ' (You)' : ''}`}</span>
              </div>
              <span>{i.breakdowns ? number2Percent(i.breakdowns.consumer_welfare) : 'Bankrupt'}</span>
            </div>
          </div>
        ))}

        <div className='performer-card'>
      	  <div>
            <span className="appkiticon icon-rating-fill"></span>
            <span>All Time Best Performer</span>
          </div>
          <span>{number2Percent(topPerformer.consumer_welfare)}</span>
        </div>
    	</div>
    </div>
  )
};

export default CW;
