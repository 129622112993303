import React from 'react'
import { Helmet } from 'react-helmet'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import configureStore, { history } from 'app/duck/store'
import AppRoutes from 'app/routes'

const { store, persistor } = configureStore()

export default class App extends React.Component {  
  render() {
    return (
      <Provider store={ store } >
        <ConnectedRouter history={history}>
        	<Helmet>
  	        <title>RAI Game</title>
  	      </Helmet>
  	      <PersistGate loading={ null } persistor={ persistor }>
  	        <AppRoutes />
  	      </PersistGate>
        </ConnectedRouter>
      </Provider>
    );
  }
}