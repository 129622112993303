// Brand Colors
// export const ORANGE = '#D04A02'
export const DIGITAL_ROSE = '#D93954'
// export const TANGERINE = '#EB8C00'

// Neutral Greys
// export const BLACK = '#000000'
export const DARK_GREY = '#2D2D2D'
export const MEDIUM_GREY = '#464646'
// export const GREY = '#7D7D7D'
export const LIGHT_GREY = '#DEDEDE'
export const LIGHTER_GREY = '#F2F2F2'
// export const WHITE = '#FFFFFF'

// Status Colors
export const ERROR = '#C52A1A'
export const WARNING = '#FFBF1F'
export const SUCCESS = '#22992E'

// Full Colors
export const BLACK = '#000000';
export const GREY_DARKER = '#141414';
export const GREY_DARK = '#2D2D2D';
export const GREY_MEDIUM = '#464646';
export const GREY = '#7D7D7D';
export const GREY_LIGHT = '#DEDEDE';
export const GREY_LIGHTER = '#F2F2F2';
export const WHITE = '#FFFFFF';

export const ORANGE_DARKER = '#571F01'
export const ORANGE_DARK = '#933401'
export const ORANGE = '#D04A02'
export const ORANGE_LIGHT = '#FD6412'
export const ORANGE_LIGHTER = '#FEB791'

export const TANGERINE_DARKER = '#714300'
export const TANGERINE_DARK = '#AE6800'
export const TANGERINE = '#EB8C00'
export const TANGERINE_LIGHT = '#FFA929'
export const TANGERINE_LIGHTER = '#FFDCA9'

export const YELLOW_DARKER = '#855F00'
export const YELLOW_DARK = '#C28A00'
export const YELLOW = '#FFB600'
export const YELLOW_LIGHT = '#FFC83D'
export const YELLOW_LIGHTER = '#FFECBD'

export const RED_DARKER = '#741910'
export const RED_DARK = '#AA2417'
export const RED = '#E0301E'
export const RED_LIGHT = '#E86153'
export const RED_LIGHTER = '#F7C8C4'

export const ROSE_DARKER = '#6E2A35'
export const ROSE_DARK = '#A43E50'
export const ROSE = '#D93954'
export const ROSE_LIGHT = '#E27588'
export const ROSE_LIGHTER = '#F1BAC3'

export const PURPLE_DARKER = '#4B20AB'
export const PURPLE_DARK = '#6B2CDA'
export const PURPLE = '#8E34F4'
export const PURPLE_LIGHT = '#B056F6'
export const PURPLE_LIGHTER = '#DCB4FC'

export const BLUE_DARKER = '#003DAB'
export const BLUE_DARK = '#0060D7'
export const BLUE = '#0089EB'
export const BLUE_LIGHT = '#4DACF1'
export const BLUE_LIGHTER = '#B3DCF9'

export const GREEN_DARKER = '#175C2C'
export const GREEN_DARK = '#2C8646'
export const GREEN = '#4EB523'
export const GREEN_LIGHT = '#86DB4F'
export const GREEN_LIGHTER = '#C4FC9F'

