import React from 'react';
import { Button } from 'appkit-react';
import { formatCurrency } from 'app/common/utils'
import { Devider } from 'app/common/components'

const Wealth = ({
  game,
  player,
  currentResults,
  topPerformer,
}) => {
  const title = 'Wealth'
  const isCurrentBetter = currentResults.wealth >= topPerformer.wealth

  const performers = [
    {
      name: 'You',
      value: currentResults.wealth,
      isBest: isCurrentBetter,
      isError: currentResults.wealth < 0,
    },
    {
      name: 'All Time Best Performer',
      value: topPerformer.wealth,
      isBest: !isCurrentBetter,
      isError: topPerformer.wealth < 0,
    }
  ]

  return (
    <div className='result-wealth'>
      <div>
        <span className="title">{title}</span>
      </div>

      <div className='performers'>
        {performers.map((i, index) => (
          <div key={index} className={i.isError ? 'performer-card error-performer' : 'performer-card'}>
            <div>
              {i.isBest && (
                <span className="appkiticon icon-rating-fill"></span>
              )}
              <span>{i.name}</span>
            </div>
            <span>{formatCurrency(i.value)}</span>
          </div>
        ))}
      </div>

      <div className='wealth-calculation'>
        <span className='title'>Wealth Calculation</span>
        <div className='item-row'>
          <span className='item-name'>Starting Wealth</span>
          <span className='item-value'>{formatCurrency(currentResults.starting_wealth)}</span>
        </div>

        {currentResults.costs.map((i, index) => (
          <div key={index} className='item-row cost-row'>
            <span className='item-name'>{i.name}</span>
            <span className='item-value'>- {formatCurrency(i.cost)}</span>
          </div>
        ))}

        {currentResults.revenues.map((i, index) => (
          <div key={index} className='item-row revenue-row'>
            <span className='item-name'>{i.name}</span>
            <span className='item-value'>+ {formatCurrency(i.revenue)}</span>
          </div>
        ))}

        <Devider />

        <div className='item-row result-row'>
          <span className='item-name'>Total Wealth</span>
          <span className='item-value'>{formatCurrency(currentResults.wealth)}</span>
        </div>
      </div>
    </div>
  )
};

export default Wealth;
