import React from 'react';

import { APP_TITLE } from 'app/common/constants'
import { Icons } from 'app/assets'

const HomeHeader = ({ location }) => {
	return (
    <header className='home-header'>
    	<span>{APP_TITLE}</span>
    	<img className='logo' src={Icons.pwc_logo_white} />
    </header>
  )
};

export default HomeHeader;
