// actions
import types from './types'

const getGameSummaryRequest = (value) => {
	return {
		type: types.FINISH_GET_GAME_SUMMARY_REQUEST,
		value: value,
	}
}

const getGameSummarySuccess = (value) => {
	return {
		type: types.FINISH_GET_GAME_SUMMARY_SUCCESS,
		value: value,
	}
}

const getGameSummaryFailure = (value) => {
	return {
		type: types.FINISH_GET_GAME_SUMMARY_FAILURE,
		value: value,
	}
}

const summaryChangeBiasSummaryState = (value) => {
	return {
		type: types.SUMMARY_CHANGE_BIAS_SUMMARY_STATE,
		value: value,
	}
}

const updateCurrentRoundResults = (value) => {
  return {
    type: types.UPDATE_CURRENT_ROUND_RESULTS,
    value: value,
  }
}

const updateCurrentChartData = (value) => {
  return {
    type: types.UPDATE_CURRENT_CHART_DATA,
    value: value,
  }
}

const switchTab = (value) => {
	return {
		type: types.FINISH_SWITCH_TAB,
		value: value,
	}
}

const switchBiasSummary = (value) => {
	return {
		type: types.FINISH_SWITCH_BIAS_SUMMARY,
		value: value,
	}
}

export default {
	getGameSummaryRequest,
	getGameSummarySuccess,
	getGameSummaryFailure,
  summaryChangeBiasSummaryState,
  updateCurrentRoundResults,
  updateCurrentChartData,
  switchTab,
  switchBiasSummary,
}
