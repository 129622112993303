// selectors
import { ACTIONS } from 'app/common/constants'
import { number2Percent, joinByAnd } from 'app/common/utils'
import { CalculateService } from 'app/common/services'
import _ from 'lodash'

const getActionLabels = (game, result, allOptions) => {
	const actionLabels = []

	const isRegulator = CalculateService.isRegulator(result.player)
	const oPGs = result.protected_groups.map(i => allOptions.protectedGroups[i].name)
	const oBM = allOptions.biasMetrics[result.bias_metric]
	const lt = number2Percent(result.legal_threshold || 0)

	if (isRegulator) {
		if (oPGs.length && oBM) {
			actionLabels.push(`Regulated ${joinByAnd(oPGs)} using ${oBM.name} with ${lt} threshold`)
		} else {
			actionLabels.push('Set no regulations')
		}

		for (const act of _.sortBy(result.actions, ['id'])) {
      const oAct = allOptions.actions[act.id]
      switch (oAct.name) {
        case ACTIONS.audit.name:
          actionLabels.push(`${oAct.name} ${game[act.player]}`)
          break
        case ACTIONS.reviewPenalty.name:
          actionLabels.push(`${oAct.name} for ${game[act.player]}`)
          if (result.penalty_against && result.penalty_against.find(i => i.player === act.player)) {
            actionLabels.push(`-Fined ${game[act.player]}`)
          }
          break
        case ACTIONS.provideWorkshop.name:
          actionLabels.push(oAct.name)
          break
        case ACTIONS.requestTransparency:
          actionLabels.push(`${oAct.name} ${game[act.player]}`)
          break
      }
    }
	} else {
		if (oPGs.length && oBM) {
			actionLabels.push(`Protected ${oPGs[0]} using ${oBM.name}`)
		}
		if (result.use_data_scientist) {
      actionLabels.push('Used data scientist')
    }

    for (const act of _.sortBy(result.actions, ['id'])) {
      const oAct = allOptions.actions[act.id]
      actionLabels.push(oAct.name)
    }
	}

	return actionLabels
}

export default {
	getActionLabels,
}